export default class SubmitOrderResult {
    OrderId:string;
    OrderDate:string;
    Message:string;
    amount:number;
    
	public constructor(orderDate:string, orderID:string, message:string, amount:number) {
        this.OrderDate = orderDate;
        this.OrderId = orderID;
        this.Message = message;
        this.amount = amount;
    }
}
