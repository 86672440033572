import { Button, ModalFooter, useDisclosure } from "@chakra-ui/react";
import { FaUpload } from "react-icons/fa";
import { useBasket } from "../../../../hooks/Basket/useBasket";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { Heading, VStack, Text } from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { Input } from "../../../Input";
import dayjs from "dayjs";
import { ExportRankingFileContent } from "../../../../models/Ranking/ExportRankingFileContent";
import { toast } from "react-toastify";

export function ImportRankingModal() {
    const { AddCodesToSelectedCodes, AddBaskets, AddStudies, UpdateSlots, UpdateSelectedPreset } = useBasket()
    const { isOpen, onClose, onToggle } = useDisclosure();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileContent, setFileContent] = useState<string | null>(null);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file: File | undefined = event.target.files?.[0];

        setSelectedFile(file || null);
        if (file && file.type === "application/json") {
            readFile(file);
        } else {
            toast.error("Formato inválido. Selecione um arquivo .json")
        }
    }

    const readFile = async (file: File) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const content = event.target?.result as string;
            setFileContent(content);
        };
        reader.readAsText(file);
    }

    const parseContent = (content: string | null) => {
        if (content) {
            const parsedContent: ExportRankingFileContent = JSON.parse(content)
            UpdateSlots(parsedContent.Slots)
            if (parsedContent.SelectedCodes !== null && parsedContent.SelectedCodes.length > 0) {
                AddCodesToSelectedCodes(parsedContent.SelectedCodes)
            }
            if (parsedContent.Studies !== null && parsedContent.Studies.length > 0) {
                AddStudies(parsedContent.Studies)
            }
            if (parsedContent.Baskets !== null && parsedContent.Baskets.length > 0) {
                AddBaskets(parsedContent.Baskets)
            }
        } else {
            toast.error("Não foi possível ler o conteúdo do arquivo.")
        }
    }

    const makeUpload = async () => {
        parseContent(fileContent);
        onClose()
    };

    return (
        <>
            <Button leftIcon={<FaUpload />} bg="green.800" size="sm" onClick={onToggle}
                title="Baixe um arquivo contendo toda a sua seleção do ranking atual.">Import</Button>
            <Modal size="xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        <Heading size="md">Importação do ranking</Heading>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <VStack align={"start"}>
                            <Text>Importe um arquivo .json e o ranking será montado automaticamente</Text>
                            <Input label="Selecione um arquivo .json" name={"fileName"} type="file" multiple={false} accept=".json" onChange={handleFileChange} />
                        </VStack>
                        <VStack align={"start"} pl="4">
                            <Text fontWeight="bold">Informações do arquivo</Text>
                            <Text>{selectedFile && `Última modificação: ${dayjs(selectedFile.lastModified).format("YYYY-MM-DD HH:MM:ss")}`}</Text>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button leftIcon={<FaUpload />} onClick={() => makeUpload()} title="Baixar arquivo">Importar</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}