import {
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalCloseButton, ModalBody,
    Switch, FormControl, SimpleGrid, FormLabel, Text, VStack, Button, ModalFooter, Box, HStack, Heading
} from "@chakra-ui/react";
import { useClipboard } from '@chakra-ui/react'
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { HiClipboardCopy } from "react-icons/hi";
import { toast } from "react-toastify";
import { useBasket } from "../../hooks/Basket/useBasket"
import { HistoricalSerie } from "../../models/HistoricalSerie";
import { Input } from "../Input";
import { Basket } from "../../models/Basket";

interface props {
    start: Date;
    end: Date;
    isOpen: boolean;
    onClose: () => void;
}

export const EquityCurveDownloader = ({ start, end, isOpen, onClose }: props) => {
    const { ReturnEquityCurve, Baskets, ChangeReturnEquityCurve } = useBasket();
    const { onCopy, value, setValue } = useClipboard("");
    const [internalStart, setInternalStart] = useState<Date>(start);
    const [internalEnd, setInternalEnd] = useState<Date>(start);

    const hasSeries = () => {
        return Baskets.some(x => x.Serie.length > 1)
    }
    useEffect(() => { hasSeries() }, [, Baskets])
    useEffect(() => { setInternalStart(start) }, [start])
    useEffect(() => { setInternalEnd(end) }, [end])

    const getSerieContent = (s: HistoricalSerie) => {
        return `${dayjs(s.Date).format("YYYY-MM-DD")}; ${s.Quote}; ${s.PercentualVariation}; ${s.Drawdown / 100}; ${s.MarketPosition}; ${s.NetWorthOnClose}; ${s.VarPts}; ${s.FinancialAdjustOnClose}; ${s.ContractsHeldOnClose}\n`;
    }

    const generateCsvContent = () => {
        setValue("No content")
        const header = "basket; date; quote; quoteVariation; quoteDrawdown; marketPositionOnClose; NetWorthOnClose; PositionVarPts; FinancialAdjustOnClose; ContractsHeldOnClose\n";
        let content = "";
        Baskets.forEach((bk) => {
            let basketCodes = bk.Codes.map(x => x.Name).join(", ");
            bk.Serie
                .filter(s => new Date(s.Date) >= internalStart && new Date(s.Date) <= internalEnd)
                .map(s => content += `${basketCodes}; ${getSerieContent(s)}`)

        })

        content += "\n ABAIXO, SISTEMAS INDIVIDUAIS \n\n";
        Baskets.forEach((bk: Basket) => {
            bk.Codes.forEach((code) => {
                content += `\n ${code.Name}\n\n`;
                code.Serie.forEach((s: HistoricalSerie) => content += `${code.Name}; ${getSerieContent(s)}`)
            })
        })
        const fContent = header + content;
        setValue(fContent);

        if (value !== "") {
            onCopy()
            toast.success("Dados copiados!")
        }
    }

    return <>
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Copiar equity curves</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={8}>
                        <FormControl as={SimpleGrid} columns={{ base: 3, lg: 2 }}
                            title="Ative esta opção para que sejam retornadas as curvas da basket e dos sistemas que a compõem">
                            <FormLabel htmlFor='returnEq'>Retornar equity curve</FormLabel>
                            <Switch id='returnEq' isChecked={ReturnEquityCurve} onChange={ChangeReturnEquityCurve} />
                        </FormControl>
                        {
                            hasSeries() ? "" : <Text fontWeight="semibold" color="red">Ainda não há equity curves disponíveis. Gere um ranking antes!</Text>
                        }
                        <Box>
                            <Heading size="md">Você pode cortar o range das curvas abaixo</Heading>
                            <HStack>
                                <Input name="Start" label="Data de início" type={"date"}
                                    value={dayjs(internalStart).format("YYYY-MM-DD")}
                                    onChange={(e) => setInternalStart(dayjs(e.target.value).toDate())} />
                                <Input name="End" label="Data de término" type={"date"}
                                    value={dayjs(internalEnd).format("YYYY-MM-DD")}
                                    onChange={(e) => setInternalEnd(dayjs(e.target.value).toDate())} />
                            </HStack>
                        </Box>
                        <Box>

                        </Box>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button isDisabled={!hasSeries()} onClick={generateCsvContent} leftIcon={<HiClipboardCopy />}>
                        {hasSeries() ? "Copiar tudo" : "Sem curvas disponíveis para cópia"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
}