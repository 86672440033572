import React, { useMemo, useState, useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';
import { useForm } from "react-hook-form";
import { VStack, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Select, HStack, Checkbox, Box, IconButton, Radio, RadioGroup, ModalFooter, FormControl, FormLabel } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { AccumulationRule, Study } from "../../../models/Study";
import { FaPencilAlt, FaPlus } from "react-icons/fa";
import { Input } from "../../Input";
import { useStudy } from "../../../hooks/useStudy";
import { useBasket } from "../../../hooks/Basket/useBasket";
import dayjs from "dayjs";

interface Props {
    study?: Study;
}

let schema = object().shape({
    Surname: string().required("Name is a required field"),
    Criteria: string().required(),
    Start: string().nullable(),
    End: string().nullable(),
    Weight: number().required(),
    RollingMonths: number().notRequired(),
    RangeType: string().required(),
    Parameter: string()
}).required();

export function CreateOrUpdateStudyModal({ study }: Props) {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const { register, handleSubmit, reset, formState: { errors, isSubmitting }, watch } = useForm<Study>({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => (study === undefined ? new Study(0, "", "CAGR") : study), [study])
    })
    const { studyTypes, rangeTypes } = useStudy();
    const { saveStudy, updateStudy } = useStudy();
    const { AddStudies, UpdateStudies } = useBasket();
    const [allowSaveStudy, setAllowSaveStudy] = useState<boolean>(false);
    const watchCriteriaSource = watch("ApplyOnlyToCodes");
    useEffect(() => {
        reset(study)
    }, [study])

    function parseDate(date: string | null, format: string, replaceNullWithToday: boolean = false): string | null {
        if (date !== null)
            if (isNaN(Date.parse(date)))
                return replaceNullWithToday ? dayjs(new Date()).format(format) : null;
            else
                return dayjs(date).format(format)
        return replaceNullWithToday ? dayjs(new Date()).format(format) : null;
    }


    const submit: SubmitHandler<Study> = async (data) => {
        data.Start = parseDate(data.Start, "YYYY-MM-DD");
        data.End = parseDate(data.End, "YYYY-MM-DD");

        if (allowSaveStudy) {
            if (study === undefined) {
                data.ID = 0;
                let savedStudy = await saveStudy(data);
                AddStudies([savedStudy]);
            } else {
                updateStudy(data);
                UpdateStudies([data]);
            }
        }
        else {
            if (study === undefined) {
                data.ID = Math.round(Math.random() * 10 * 10 * 10 * 10);
                AddStudies([data])
            } else {
                UpdateStudies([data])
            }
        }

    }

    return (
        <>
            {
                study === undefined ?
                    <IconButton size="sm" aria-label="Salvar"
                        icon={<FaPlus />} title="Criar novo critério"
                        onClick={onToggle} />
                    :
                    <IconButton size="xs" aria-label="Salvar"
                        icon={<FaPencilAlt />} title="Editar critério"
                        onClick={onToggle} />

            }
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <Box as='form' onSubmit={handleSubmit(submit)}>
                        <ModalHeader>{study === undefined ? "Criar" : "Editar"} critério</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody >

                            <VStack spacing="4">
                                <Input label="Nome" error={errors.Surname} {...register("Surname")} />
                                <Select {...register("Criteria")}>
                                    {studyTypes.map((type) => (<option value={type} key={type}>{type}</option>))}
                                </Select>
                                <Input label="Parâmetro" error={errors.Parameter} {...register("Parameter")} />
                                <HStack>
                                    <Input label="Data inicial" type="date" error={errors.Start} {...register("Start")} />
                                    <Input label="Data final" type="date" error={errors.End}  {...register("End")} />
                                    <Input label="Rolling" type="number" error={errors.RollingMonths} {...register("RollingMonths")} />
                                </HStack>
                                <Input label="Peso" type="number" error={errors.Weight} {...register("Weight")} />

                                <Select {...register("RangeType")}>
                                    {rangeTypes.map((type) => (<option value={type} key={type} defaultValue={rangeTypes[0]}>{type}</option>))}
                                </Select>
                                <FormControl>
                                    <FormLabel>Dados para a aplicação do critério</FormLabel>
                                    <Checkbox {...register("ApplyOnlyToCodes")}>Aplicar critério na curva dos codes</Checkbox>
                                </FormControl>
                                <FormControl isDisabled={!watchCriteriaSource}>
                                    <FormLabel>Regra de acumulação dos resultados</FormLabel>
                                    <Select {...register("AccumulationRule", { valueAsNumber: true })}>
                                        <option value={AccumulationRule.Average}>Média</option>
                                        <option value={AccumulationRule.Sum}>Soma</option>
                                    </Select>
                                </FormControl>
                            </VStack>

                        </ModalBody>
                        <ModalFooter>
                            <HStack spacing="2">
                                <Checkbox isChecked={allowSaveStudy}
                                    onChange={() => setAllowSaveStudy(!allowSaveStudy)}>
                                    Salvar alterações</Checkbox>
                                <Button type="submit" isLoading={isSubmitting}>
                                    {study === undefined ? "Adicionar ao ranking" : "Aplicar alterações"}
                                </Button>
                            </HStack>

                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
}
