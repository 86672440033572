import { RKGParameters } from "../../Ranking/Parameters";
import Combination from "./Combination";

export default class OptimizationParameters {
    Combination: Combination;
    Ranking: RKGParameters;
    constructor(combination: Combination, ranking: RKGParameters) {
        this.Combination = combination;
        this.Ranking = ranking;
    }
}