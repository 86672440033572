import React, { createContext, useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import API from '../api/ApiManager';
import RankingPreset from '../models/Ranking/RankingPreset';

interface RankingPresetProdiverProps {
    children: React.ReactNode;
}

interface RankingPresetContextData {
    RankingPresets: RankingPreset[];
    getRankingPresets: () => Promise<void>;
    SaveRankingPreset: (preset: RankingPreset) => Promise<void>;
    UpdateRankingPreset: (preset: RankingPreset) => Promise<void>;
    DeleteRankingPreset: (preset: RankingPreset) => Promise<void>;
}

const RankingPresetContext = createContext<RankingPresetContextData>({} as RankingPresetContextData);

export function RankingPresetProvider(props: RankingPresetProdiverProps) {
    const [RankingPresets, setRankingPresets] = useState<RankingPreset[]>([]);

    useEffect(() => {
        getRankingPresets()
    }, [])

    async function getRankingPresets(): Promise<void> {
        const api = new API('rankingpreset');
        toast.promise(api.getAsync<RankingPreset[]>().then(response => response !== null ? setRankingPresets(response) : []), { pending: "🔎 Buscando presets..." })
    }

    async function SaveRankingPreset(preset: RankingPreset): Promise<void> {
        const api = new API('rankingpreset');
        toast.promise(api.postAsync<RankingPreset>(preset).then(response => response !== null ?
            setRankingPresets([...RankingPresets, response]) : []), { pending: "Salvando...", success: "Preset salvo!" })
    }

    async function UpdateRankingPreset(preset: RankingPreset): Promise<void> {
        const api = new API('rankingpreset');
        const index = RankingPresets.findIndex(x => x.ID === preset.ID);
        if (index !== -1) {
            let updatedRankingPresets = RankingPresets;
            updatedRankingPresets[index] = preset;
            toast.promise(api.putAsync(preset), { pending: "Salvando...", success: "Preset atualizado!" })
            setRankingPresets(updatedRankingPresets)
        } else {
            toast.error("Não foi possível atualizar o preset")
        }
    }

    async function DeleteRankingPreset(preset: RankingPreset): Promise<void> {
        const api = new API(`rankingpreset/${preset.ID}`);
        toast.promise(api.deleteAsync(), { pending: "Excluindo...", success: "Preset excluído!" })
        setRankingPresets(RankingPresets.filter(x => x.ID !== preset.ID));
    }

    return (
        <RankingPresetContext.Provider value={{ RankingPresets, getRankingPresets, SaveRankingPreset, UpdateRankingPreset, DeleteRankingPreset }}>
            {props.children}
        </RankingPresetContext.Provider>
    )
}

export function useRankingPreset() {
    const context = useContext(RankingPresetContext);
    return context;
}