import React, { useEffect, useState } from "react";
import { Code } from "../../../models/Strategy/Code";
import { FaPlus, FaQuestionCircle, FaSearch } from "react-icons/fa";
import { Heading, Button, VStack, useDisclosure, Text, FormControl, FormLabel, Switch, Icon, HStack, RadioGroup, Radio } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { Input } from "../../Input";
import { useCode } from "../../../hooks/useCode";
import { uniqBy } from "lodash";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { PrefixIndexStrategy, Search } from 'js-search';
import { toast } from "react-toastify";
import { SyncButton } from "../../../views/v2/Ranking/SyncButton";
import { DisplayCodeTable } from "./DisplayCodeTable";

interface IProps {
	slotId: number;
}

export function AddCodeToSlot({ slotId }: IProps) {
	const { searchCodesByName } = useCode();
	const { AddCodesToSelectedCodes, Slots, AddSlots } = useBasket();
	const { isOpen, onClose, onToggle } = useDisclosure();
	const [selectedCodes, setSelectedCodes] = useState<Code[]>([]);

	const [searchValue, setSearchValue] = useState<string>("");
	const [segregateCodes, setSegregateCodes] = useState<boolean>(false);

	const [foundCodes, setFoundCodes] = useState<Code[]>([]);
	const [SearchMode, setSearchMode] = useState<'exact' | 'contains'>('contains');

	async function SearchCodes() {
		const values = searchValue.split(",").filter(x => x !== "")
		if (values.length > 0) {
			let result = await toast.promise(searchCodesByName(SearchMode, values), { pending: "Buscando..." })
			if (result.length == 0) toast.info("Nenhum code encontrado.")
			setFoundCodes(result)
		}
	}

	function handleAddCodes() {
		const updatedSelectedCodes: Code[] = [];
		selectedCodes.forEach(code => updatedSelectedCodes.push({ ...code }));
		if (!segregateCodes) {
			updatedSelectedCodes.forEach(x => {
				x.SlotID = slotId;
			});
		} else {
			let updatedSlotList = Slots;
			if (selectedCodes.length > Slots.length)
				updatedSlotList = Slots.concat(AddSlots(selectedCodes.length - Slots.length));

			for (let i = 0; i < updatedSlotList.length; i++) {
				updatedSelectedCodes[i].SlotID = updatedSlotList[i];
			}
		}

		AddCodesToSelectedCodes(updatedSelectedCodes);
		onToggle()
	}

	const selectedCodesHandler = (codes: Code[]) => {
		setSelectedCodes([...codes]);
	}

	return (
		<>
			<Button
				size='xs'
				title="Incluir no slot"
				onClick={onToggle}>
				<FaPlus /> Incluir code
			</Button>
			<Modal size="6xl" isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent >
					<ModalHeader>
						<Heading size="md">Adicionar codes no slot {slotId}</Heading>
						<ModalCloseButton />
					</ModalHeader>
					<ModalBody>
						<VStack w='100%'>
							<FormControl display='flex' alignItems='center' >
								<Switch pr={2} id='segregateCodes' checked={segregateCodes} onChange={() => setSegregateCodes(!segregateCodes)} />
								<FormLabel htmlFor='segregateCodes'>
									Gerar novo slot para cada code selecionado <Icon as={FaQuestionCircle} title="Será gerado um novo slot para cada code que for selecionado, não excluindo os slots existentes. Com esta opção desmarcada, todos os codes selecionados serão adicionados no slot que aparece no título desta janela." />
								</FormLabel>
							</FormControl>
							<HStack w='100%'>
								<RadioGroup onChange={(e) => setSearchMode(e === 'exact' ? 'exact' : 'contains')} value={SearchMode}>
									<Radio value='exact' title='Essa busca retorna apenas codes que concidem exatamente com o termo. Ignora apenas caracteres especiais.'>Busca exata</Radio>
									<Radio value='contains'>Busca aproximada</Radio>
								</RadioGroup>
								<VStack w='100%' align={"start"}>
									<HStack w='100%' align={"end"}>
										<Input name="searchVal" type="text" size="sm" label="Insira os termos da busca"
											placeholder="Separe múltiplos termos de busca com vírgula."
											value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
										<Button title="Search" size="sm" leftIcon={<FaSearch />} onClick={SearchCodes}>Buscar</Button>
									</HStack>
									<Text fontStyle={'italic'}>{foundCodes.length} {foundCodes.length > 1 ? 'resultados' : 'resultado'}</Text>
								</VStack>
							</HStack>

							<VStack w='100%' p='4'>
								<Text fontStyle={'italic'}>{selectedCodes.length} {selectedCodes.length > 1 ? 'selecionados' : 'selecionado'}</Text>
								<DisplayCodeTable codes={foundCodes} selectedCodesHandler={selectedCodesHandler} />
							</VStack>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<Button id="add-slot" onClick={handleAddCodes}>
							Adicionar {selectedCodes.length} codes
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>

	)
}
