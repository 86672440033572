import {
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalCloseButton, ModalBody,
    useDisclosure,
    MenuItem, Switch, FormControl, SimpleGrid, FormLabel
} from "@chakra-ui/react";
import { useBasket } from "../../../../hooks/Basket/useBasket";


export function RankingTableConfigsModal() {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const {
        AcumulateBaskets, ChangeAcumulateBaskets,
        ReturnEquityCurve, ChangeReturnEquityCurve
    } = useBasket();

    return (
        <>
            <MenuItem onClick={onToggle}>Mais configurações</MenuItem>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Mais configurações do ranking</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl as={SimpleGrid} columns={{ base: 4, lg: 2 }}
                            title="Ative esta opção para que as baskets sejam mantidas ao re-rodar o ranking">
                            <FormLabel htmlFor='acumulate'>Acumular baskets</FormLabel>
                            <Switch id='acumulate' isChecked={AcumulateBaskets} onChange={ChangeAcumulateBaskets} />
                        </FormControl>
                        <FormControl as={SimpleGrid} columns={{ base: 3, lg: 2 }}
                            title="Ative esta opção para que sejam retornadas as curvas da basket e dos sistemas que a compõem">
                            <FormLabel htmlFor='returnEq'>Retornar equity curve</FormLabel>
                            <Switch id='returnEq' isChecked={ReturnEquityCurve} onChange={ChangeReturnEquityCurve} />
                        </FormControl>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}