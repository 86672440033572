import React, { useMemo, useState } from "react";
import { FaDollarSign } from "react-icons/fa";
import { Heading, Button, VStack, useDisclosure, HStack, Text } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { Input } from "../../../Input";
import { yupResolver } from '@hookform/resolvers/yup';
import { number, object } from 'yup';
import { SubmitHandler, useForm } from "react-hook-form";
import { MmConfig } from "../../../../models/Ranking/Parameters";
import { MarketManagementTable } from "./MarketManagementTable/MarketManagementTable";
import { useBasket } from "../../../../hooks/Basket/useBasket";

interface IProps {
}



let schema = object().shape({
    Leverage: number().required("Alavanca é um campo obrigatório").min(0),
    InitialNetWorth: number().required("PL inicial é um campo obrigatório").min(1 * 1000 * 100)
}).required();

export function MoneyManagementConfigModal({ }: IProps) {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const { MoneyManagementConfig, UpdateMoneyManagementConfig } = useBasket();
    const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<MmConfig>({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => (MoneyManagementConfig), [])
    })

    const submit: SubmitHandler<MmConfig> = async (data) => {
        UpdateMoneyManagementConfig(data);
    }

    return (
        <>
            <Button size='sm' leftIcon={<FaDollarSign />} title="Incluir no slot" onClick={onToggle}>
                MM
            </Button>
            <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        <Heading size="md">Configurações de money management e custos</Heading>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <VStack as='form' onSubmit={handleSubmit(submit)} w='100%'>
                            <HStack>
                                <Input label="PL inicial bk" type="number" error={errors.InitialNetWorth} {...register("InitialNetWorth", { required: true })} />
                                <Input label="Alavanca bk" type="number" min={0} step={0.1} error={errors.Leverage} {...register("Leverage", { required: true })} />
                            </HStack>
                            <MarketManagementTable />
                            <HStack>
                                <Button id="apply" type="submit" isLoading={isSubmitting}>
                                    Aplicar
                                </Button>
                            </HStack>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
