import { Td, Tr, IconButton, ButtonGroup, Badge } from "@chakra-ui/react";
import { Study } from "../../../models/Study";
import React, { useEffect, useState } from "react";
import { Input } from "../../Input";
import { FaTrash } from "react-icons/fa";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { useStudy } from "../../../hooks/useStudy";
import dayjs from "dayjs";
import { CreateOrUpdateStudyModal } from "./CreateOrUpdateStudyModal";
import InputMask from "react-input-mask";

interface Props {
    study: Study;
}


export function DisplayStudyRow({ study }: Props) {
    const { UpdateStudies, RemoveStudy } = useBasket();
    const [weight, setWeight] = useState<number>(study.Weight);
    const [rolling, setRolling] = useState<number>(study.RollingMonths);
    const [start, setStart] = useState<string | null>(study.Start);
    const [end, setEnd] = useState<string | null>(study.End);
    const { getRangeDescription } = useStudy();

    useEffect(() => {
        let updated = { ...study };
        updated.Weight = weight;
        updated.RollingMonths = rolling; updateRolling();
        updated.Start = (start === '____-__-__' || start === null) ? null : start;
        updated.End = (end === '____-__-__' || end === null) ? null : end;
        updated.RangeDescription = getRangeDescription(updated.RangeType, updated.RollingMonths, updated.Start, updated.End);
        UpdateStudies([updated])
    }, [weight, rolling, start, end])

    var updateRolling = () => {
        if (rolling !== 0) {
            let rEnd: string = end == null ? dayjs(new Date()).format("YYYY-MM-DD") : end;
            const rolledStartDate = dayjs(rEnd).add(-rolling, 'M');
            setStart(dayjs(rolledStartDate).format("YYYY-MM-DD"))
            setEnd(rEnd)
        }
    }

    var rangeTypeBadge = (range: string) => {
        let text: string = "All"
        let scheme: string = "blue"
        if (range === "Backtest") {
            text = "BT"
            scheme = "orange"
        }

        else if (range === "Realtime") {
            text = "RT"
            scheme = "green"
        }

        return <Badge colorScheme={scheme} variant='subtle' size='sm'>{text}</Badge>
    }

    return (
        <Tr key={study.ID}>
            <Td>{rangeTypeBadge(study.RangeType)} {study.Criteria} {study.Parameter !== "" && study.Parameter}</Td>
            <Td>{study.Surname}</Td>
            <Td><Input name="weight" size="xs" type="number" value={study.Weight} onChange={(e) => setWeight(parseFloat(e.target.value))} /></Td>
            <Td><Input name="rolling" size="xs" type="number" value={study.RollingMonths} min={0} onChange={(e) => setRolling(parseInt(e.target.value))} /></Td>
            <Td>
                <Input name="start" size="xs" mask="****-**-**" alwaysShowMask={true} value={start !== null ? start : ""} onChange={(e) => setStart(e.target.value)}
                    isDisabled={study.RollingMonths >= 1} />
            </Td>
            <Td>
                <Input name="end" size="xs" mask="****-**-**" alwaysShowMask={true} value={end !== null ? end : ""} onChange={(e) => setEnd(e.target.value)} />
            </Td>
            <Td p={0} textAlign="center">
                <ButtonGroup spacing='0.5'>
                    <CreateOrUpdateStudyModal study={study} />
                    <IconButton size="xs" aria-label="Salvar" icon={<FaTrash />} title="Remover critério"
                        onClick={() => RemoveStudy(study)} />
                </ButtonGroup>
            </Td>
        </Tr>
    )
}