import dayjs from "dayjs";
import React from "react";
import { Row, Col, Button, Form, Table } from "react-bootstrap";
import Select from "react-select";
import FundController from "../../../controllers/v1/FundController";
import PositionController from "../../../controllers/v1/PositionController";
import { Fund } from "../../../models/Strategy/Fund";
import Position from "../../../models/Position/Position";
import { toast } from "react-toastify";

interface IProps { }

interface IState {
	maskedFunds: {label: string, value: number }[];
	funds: Array<Fund>;
	selectedFund: Fund;
	positions: Array<Position>;
}

export default class PositionView extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			maskedFunds: [],
			funds: [],
			selectedFund: new Fund(),
			positions: [],
		};
		this.getFunds();
	}

	getFunds = async () => {
		const response = await toast.promise(FundController.Get(), { pending: "Buscando fundos" });
		if (response) {
			this.setState({ funds: response });
			this.applyMaskToFunds();
		}
	};

	applyMaskToFunds() {
		let masked = this.state.maskedFunds;
		this.state.funds.map((fund: Fund) => masked.push({ label: fund.Name, value: fund.ID }));
		this.setState({ maskedFunds: masked });
	}

	handleSelectFund = (value: any, action: any) => {
		if (this.state.funds) {
			if (action != null) {
				if (action.action === "select-option") {
					let fund = this.state.funds.find((x) => x.ID === value.value);
					if (fund) this.setState({ selectedFund: fund });
				}
			}
		}
	};

	getPositions = async () => {
		if (this.state.selectedFund.ID !== -1) {
			const response = await toast.promise(PositionController.Get(this.state.selectedFund.ID), {
				pending: "Buscando posições 🔎",
				success: "Posições encontradas ✅",
				error: "Ocorreu um erro 😡"
			});
			if (response) {
				this.setState({ positions: response });
			}
		}
	};

	render() {
		return (
			<>
				<Row className="justify-content-center">
					<Col xs={6}>
						<Form>
							<Form.Group>
								<Select placeholder="Selecione o fundo" options={this.state.maskedFunds} onChange={this.handleSelectFund}></Select>
							</Form.Group>
						</Form>
					</Col>
					<Col xs="auto">
						<Button disabled={this.state.selectedFund.ID === -1} onClick={this.getPositions}>
							Consultar
						</Button>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs="auto">
						{this.state.selectedFund.ID !== -1 ? (
							<h4>
								Posições de {this.state.selectedFund.Name} na exchange {this.state.selectedFund.Exchange}
							</h4>
						) : (
							<h4>Selecione o fundo para consultar as posições</h4>
						)}
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs="auto">
						{this.state.positions.length > 0 ? (
							<p>
								<b>{this.state.positions.length}</b> posições em aberto
							</p>
						) : null}
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs={6}>
						<Table id="ranking" className="table-condensed table-bordered" responsive hover striped size="sm">
							<thead>
								<tr>
									<th style={{ width: "50px" }}>Symbol</th>
									<th style={{ width: "50px" }} className="text-right">
										NET
									</th>
									<th style={{ width: "50px" }} className="text-right">
										AVG entry price ($)
									</th>
									<th style={{ width: "50px" }} className="text-right">
										Unrealized Profit ($)
									</th>
									<th style={{ width: "50px" }} className="text-right">
										Margin ($)
									</th>
									<th style={{ width: "50px" }} className="text-right">
										Update time
									</th>
								</tr>
							</thead>
							<tbody style={{ overflowY: "scroll" }}>
								{this.state.positions.length === 0 ? (
									<tr className="text-center">
										<td colSpan={6}>As posições aparecerão aqui</td>
									</tr>
								) : (
									<>
										{this.state.positions.map((position: Position) => (
											<tr key={`${position.ID}`}>
												<td>{position.Market ? position.Market.Ticker : "Não identificado"}</td>
												<td className="text-right">{position.PositionSize.toFixed(position.Fund.Precision)}</td>
												<td className="text-right">{position.EntryPrice.toFixed(position.Fund.Precision)}</td>
												<td className="text-right">{position.UnrealizedProfit.toFixed(position.Fund.Precision)}</td>
												<td className="text-right">{position.Margin.toFixed(position.Fund.Precision)}</td>
												<td className="text-right">{dayjs(position.UpdateTime).format("YYYY-MM-DD HH:mm:ss")}</td>
											</tr>
										))}
									</>
								)}
							</tbody>
						</Table>
					</Col>
				</Row>
			</>
		);
	}
}
