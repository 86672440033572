import { Backtest } from "./Backtest/Backtest";
import { HistoricalSerie } from "../HistoricalSerie";

abstract class Strategy {
	ID: number;
	Name: string;
	SlotID: number;
	Backtest: Backtest;
	Serie: HistoricalSerie[];

	constructor(name: string, slotId: number, backtest: Backtest, serie: HistoricalSerie[]) {
		this.ID = 0;
		this.Name = name;
		this.SlotID = slotId;
		this.Backtest = backtest;
		this.Serie = serie;
	}
}

export { Strategy }
