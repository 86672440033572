class TransactionCost {
	public ID: number;
	public Commission: number;
	public Slippage: number;

	constructor();
	constructor(commission: number, slippage: number);
	constructor(commission = 0, slippage = 0) {
		this.ID = 0;
		this.Commission = commission;
		this.Slippage = slippage;
	}
}

export { TransactionCost }