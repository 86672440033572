import React from "react";
import User from "../../../models/User";
import { Center, Heading } from "@chakra-ui/react";

interface IProps { }

interface IState {
	user: User;
}

export default class AuthHome extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			user: new User("", ""),
		};
	}

	getUserName = () => {
		let usr = new User("", "");
		usr.GetFromLocalstorage();
		return usr.Name;
	};

	render() {
		return (
			<Center h='80vh'>
				<Heading size='md'>
					Bem-vindo ao sistema interno do FFLAB
				</Heading>
			</Center>
		);
	}
}
