import React, { useEffect, useMemo } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';
import { useForm } from "react-hook-form";
import { VStack, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Center, HStack, Text } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { Market } from "../../../models/Market";
import { useMarket } from "../../../hooks/useMarket";
import { Input } from "../../Input";

interface IProps {
	market: Market;
	show: boolean;
	onHide: () => void;
}


let schema = object().shape({
	Prefix: string().notRequired(),
	Ticker: string().required(),
	Exchange: string().required(),
	ContractType: string().required("Contract type is a required field"),
	BigPointValue: number().required("Big point value is a required field").positive(),
	MinContractsHeld: number().required("Min contracts is a required field").positive(),
	Exposition: number().required("Exposition is a required field").positive(),
	TransactionCost: object().shape({
		Commission: number().required("Commission is a required field").typeError("Must be a number"),
		Slippage: number().required("Slippage is a required field").typeError("Must be a number")
	})
}).required();


export function UpdateMarketModal({ market, show, onHide }: IProps) {
	const { register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<Market>({
		resolver: yupResolver(schema),
		defaultValues: useMemo(() => (market), [market])
	})

	useEffect(() => {
		let uTc = { ...market.TransactionCost }
		uTc.Commission = market.TransactionCost.Commission * 100;
		uTc.Slippage = market.TransactionCost.Slippage * 100;

		let uMkt = { ...market, uTc };
		uMkt.Exposition = market.Exposition * 100;
		reset(uMkt);
	}, [market])

	const { updateMarket } = useMarket();
	const submit: SubmitHandler<Market> = async (data) => {
		const updated = data;
		updated.Exposition = data.Exposition / 100;
		updated.TransactionCost.Slippage = data.TransactionCost.Slippage / 100;
		updated.TransactionCost.Commission = data.TransactionCost.Commission / 100;
		updateMarket(updated);
		onHide();
	}

	return (
		<>
			<Modal size="xl" isOpen={show} onClose={onHide}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Alterar mercado</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Center as='form' onSubmit={handleSubmit(submit)}>
							<VStack>
								<HStack>
									<Input label="Prefixo" error={errors.Prefix} {...register("Prefix")} />
									<Input label="Ticker" error={errors.Ticker} {...register("Ticker")} />
								</HStack>
								<HStack>
									<Input label="Exchange" error={errors.Exchange} {...register("Exchange")} />
									<Input label="Tipo do contrato" error={errors.ContractType}
										{...register("ContractType")} />
								</HStack>
								<HStack>
									<Input label="Valor do ponto" error={errors.BigPointValue}
										{...register("BigPointValue")} />
									<Input label="Lote mínimo" error={errors.MinContractsHeld}
										{...register("MinContractsHeld")} />
								</HStack>
								<Input label="Exposição do pl desalavancado" sufix="%" error={errors.Exposition}
									{...register("Exposition", { max: 100, min: 0 })} />

								<Text fontWeight="bold" textAlign="left">Custos por ordem executada</Text>
								<HStack>
									<Input label="Slippage" sufix="%" error={errors.TransactionCost?.Slippage}
										{...register("TransactionCost.Slippage")} />

									<Input label="Comissão" sufix="%" error={errors.TransactionCost?.Commission}
										{...register("TransactionCost.Commission")} />
								</HStack>

								<Button type="submit" isLoading={isSubmitting}>Salvar</Button>
							</VStack>
						</Center>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
