import API from "../../api/ApiManager";
import { Fund } from "../../models/Strategy/Fund";

export default class FundController {
	static async GenerateNewFund(): Promise<Fund> {
		return new Fund();
	}
	static async Get(): Promise<Array<Fund>> {
		let response: Array<Fund> = [];
		const api = new API(`fund`);
		const res = await api.getAsync<Array<Fund>>();
		if (res) {
			response = res;
		}
		return response;
	}

	static async Create(fund: Fund): Promise<Fund> {
		let response: Fund = await this.GenerateNewFund();
		const api = new API(`fund`);
		const res = await api.postAsync<Fund>(fund);
		if (res) {
			response = res;
		}
		return response;
	}

	static async Update(fund: Fund): Promise<string> {
		let response: string = "";
		const api = new API(`fund/update`);
		const res = await api.postAsync<string>(fund);
		if (res) {
			response = res;
		}
		return response;
	}

	static async Delete(fund: Fund): Promise<string> {
		let response: string = "";

		const api = new API(`fund/delete`);
		const res = await api.postAsync<string>(fund);
		if (res) {
			response = res;
		}
		return response;
	}
}
