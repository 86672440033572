class Backtest {
	ID: number;
	FileName: string;
	UploadDate: Date;
	FirstDate: Date;
	LastDate: Date;
	StrategyName: string;
	MarketTicker: string;

	constructor();
	constructor(fileName: string, uploadDate: Date, firstDate: Date, lastDate: Date, strategyName: string, marketTicker: string);
	constructor(fileName: string);
	constructor(fileName = "", uploadDate = new Date("0001-01-01"), firstDate = new Date("0001-01-01"), lastDate = new Date("0001-01-01"), strategyName = "", marketTicker = "") {
		this.ID = 0;
		this.FileName = fileName;
		this.UploadDate = uploadDate;
		this.FirstDate = firstDate;
		this.LastDate = lastDate;
		this.StrategyName = strategyName;
		this.MarketTicker = marketTicker;
	}
}


export { Backtest }