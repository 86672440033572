import React, { useState } from "react";
import { Heading, useDisclosure, MenuItem, Text, VStack, Checkbox, Button } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { useBasket } from "../../../hooks/Basket/useBasket";

export function ElcGeneration() {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const { GenerateElc, UpdateGenerateElc } = useBasket();

    return (
        <>
            <MenuItem onClick={onToggle}>Configuração cota ELC</MenuItem>
            <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Heading size="md">Cota ELC</Heading>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <VStack spacing="8">
                            <Text><Text fontWeight='semibold'>Descrição</Text>O algoritmo gerará uma cota paralela ELC para cada critério 'DL' que você selecionar.</Text>
                            <Checkbox isChecked={GenerateElc} onChange={() => UpdateGenerateElc(!GenerateElc)}>Gerar ELC</Checkbox>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}
