import React from "react";
import { Study } from "../../../models/Study";
import { Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { DisplayStudyRow } from "./DisplayStudyRow";

interface Props {
	studies: Array<Study>;
}

export function DisplayStudiesTable({ studies }: Props) {

	return (
		<Box overflowY="auto" w='100%' maxHeight="400px">
			<Table size="sm">
				<Thead position="sticky" top={0}>
					<Tr key="-2">
						<Th colSpan={3} textAlign="center">Informações do critério - {studies.length} critério(s)</Th>
						<Th colSpan={4} textAlign="center">Range</Th>
					</Tr>
					<Tr key="-1">
						<Th>Critério</Th>
						<Th>Nome</Th>
						<Th>Peso</Th>
						<Th>Rolling</Th>
						<Th>Início</Th>
						<Th>Término</Th>
						<Th className="text-right"></Th>
					</Tr>
				</Thead>
				<Tbody>
					{studies.map((study: Study) => (
						<DisplayStudyRow study={study} />
					))}
				</Tbody>
			</Table>
		</Box>

	);
}
