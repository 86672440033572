import API from "../../api/ApiManager";
import history from "../../history";
import IUser from "../../models/Interfaces/IUser";

export default class UserController {
	static async Login(userName: string, password: string): Promise<IUser | null> {
		const api = new API("user/login");
		const res = await api.postAsync<IUser | string>({ Name: userName, Password: password });
		if (res) {
			const user = res as IUser;
			if ('Token' in user) {
				localStorage.setItem("token", user.Token);
				localStorage.setItem("user", JSON.stringify(user));
				return user;
			}
		}
		return null;
	}

	static async TestAuth(): Promise<string> {
		const api = new API("user/authenticated");
		const res = await api.getAsync<string>();
		if (res) {
			return res;
		} else {
			return "Não foi possível testar";
		}
	}

	static async Logout() {
		try {
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			history.push("/");
		} catch (ex) {
			return ex;
		}
	}
}
