import React, { useState } from "react";
import { Button, Center, HStack } from "@chakra-ui/react";
import { FaPencilAlt, FaPlus } from "react-icons/fa";
import { CreateMarketModal } from "./CreateMarket";
import { UpdateTransactionCostModal } from "./UpdateTransactionCost";
import { DeleteMarketsButton } from "./DeleteMarketsButton";
import { Market } from "../../../models/Market";

interface IProps {
	selectedMarkets: Market[];
}

export function MarketManagementButtons({ selectedMarkets }: IProps) {
	const [displayingTransactionCostModal, setDisplayingTransactionCostModal] = useState<boolean>(false);
	const [displayingCreateMarketModal, setDisplayingCreateMarketModal] = useState<boolean>(false);
	const [displayingDeleteMarket, setDisplayingDeleteMarket] = useState<boolean>(false);

	const setDisplayingTransactionCostModalVisibility = () => {
		setDisplayingTransactionCostModal(!displayingTransactionCostModal);
	}

	const setDisplayingCreateMarketModalVisibility = () => {
		setDisplayingCreateMarketModal(!displayingCreateMarketModal);
	}
	const setDisplayingDeleteMarketVisibility = () => {
		setDisplayingDeleteMarket(!displayingDeleteMarket);
	}

	return (
		<>
			<UpdateTransactionCostModal markets={selectedMarkets}
				show={displayingTransactionCostModal} onHide={setDisplayingTransactionCostModalVisibility} />
			<CreateMarketModal show={displayingCreateMarketModal} onHide={setDisplayingCreateMarketModalVisibility} />
			<Center >
				<HStack>
					<Button title="Adicionar mercado" size="sm" onClick={setDisplayingCreateMarketModalVisibility}>
						<FaPlus></FaPlus>
					</Button>
					<Button size="sm" title="Atualizar custos de transação" disabled={selectedMarkets.length === 0}
						onClick={setDisplayingTransactionCostModalVisibility}>
						<FaPencilAlt />
					</Button>
					<DeleteMarketsButton show={displayingDeleteMarket}
						onHide={setDisplayingDeleteMarketVisibility}
						markets={selectedMarkets} />
				</HStack>
			</Center>
		</>
	);
}
