import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import React from 'react';
import { Basket } from "../../../../models/Basket";
import "react-contexify/ReactContexify.css"
import fileDownload from "js-file-download";
import API from '../../../../api/ApiManager';
import { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

interface IProps {
    basket: Basket;
}

const MENU_ID = 'ranking-table-row-context-menu';

const RankingTableRowContextMenu = ({ basket }: IProps) => {
    const baixarRel = async () => {
        const config: AxiosRequestConfig = {
            responseType: "arraybuffer",
            headers: {
                "Content-Type": "application/json",
            },
        };
        const api = new API(`basket/report?file=${basket.ReportOutputDir}`);
        const res = await api.getAsync<any>(config);

        if (res) {
            const fileName = basket.ReportOutputDir.split("\\").reverse()[0];
            fileDownload(res, fileName, "application/xlsx");
        } else {
            toast.warning("Não foi possível baixar o relatório.")
        }
    }

    return (
        <Menu id={MENU_ID}>
            <Item id="copy" onClick={baixarRel}>Baixar o relatório da basket (.xlsx)</Item>
        </Menu>
    );
}

export { RankingTableRowContextMenu, MENU_ID }