import React from "react";
import { Button, Popover, PopoverTrigger, Portal, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton, PopoverBody, PopoverFooter, Center } from "@chakra-ui/react";
import { FaTrashAlt } from "react-icons/fa";
import { Market } from "../../../models/Market";
import { useMarket } from "../../../hooks/useMarket";

interface IProps {
	markets: Market[];
	show: boolean;
	onHide: () => void;
}


export function DeleteMarketsButton({ markets, show, onHide }: IProps) {
	const { deleteMarket } = useMarket();

	const del = () => {
		markets.forEach(market => deleteMarket(market));
		onHide();
	}

	return (
		<Popover
			isOpen={show} onClose={onHide}>
			<PopoverTrigger>
				<Button onClick={onHide} size="sm" title="Excluir mercados" bg="red.600" disabled={markets.length === 0}>
					<FaTrashAlt />
				</Button>
			</PopoverTrigger>
			<Portal>
				<PopoverContent>
					<PopoverArrow />
					<PopoverHeader><strong>Por favor, confira a exclusão</strong></PopoverHeader>
					<PopoverCloseButton />
					<PopoverBody>
						<p> {markets.map(x => `${x.Prefix}${x.Ticker}`).join(', ')}</p>
					</PopoverBody>
					<PopoverFooter>
						<Center>
							<Button bg="red.600" onClick={del}>Excluir</Button>
						</Center>

					</PopoverFooter>
				</PopoverContent>
			</Portal>
		</Popover>
	);
}
