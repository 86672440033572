import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { FaPlus } from "react-icons/fa";
import { Fund } from "../../../models/Strategy/Fund";
import { OptionTypeBase } from "react-select/src/types";
import CandleControler from "../../../controllers/v1/CandleController";
import FundController from "../../../controllers/v1/FundController";
import MarketController from "../../../controllers/v1/MarketController";
import AppContext from "../../../context";
import { Market } from "../../../models/Market";
import { Col } from "react-bootstrap";
import { APIKey } from "../../../models/APIKey";
import { toast } from "react-toastify";

interface IProps {
	show: boolean;
	onHide: () => void;
	onAddFund: (Fund: Fund) => void;
}

interface IState {
	Fund: Fund;
	exchanges: Array<string>;
	markets: Array<Market>;
	maskedExchanges: Array<OptionTypeBase>;
	maskedMarkets: Array<OptionTypeBase>;
	maskedContractTypes: Array<OptionTypeBase>;
	isSyncDateChecked: boolean;
	isUpdating: boolean;
}

export default class AddFund extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			Fund: new Fund(),
			exchanges: [],
			markets: [],
			maskedExchanges: [],
			maskedMarkets: [],
			maskedContractTypes: [
				{ id: 0, label: "Spot", value: "Spot" },
				{ id: 0, label: "Futures", value: "Futures" },
			],
			isSyncDateChecked: false,
			isUpdating: false,
		};
	}

	static contextType = AppContext;

	generateNewFund() {
		this.setState({
			Fund: new Fund(),
			isUpdating: false,
		});
	}

	componentDidMount() {
		this.loadExchanges();
		this.loadMarkets();
	}

	applyMaskToExchanges() {
		let masked = this.state.maskedExchanges;
		this.state.exchanges.map((exchange: String) => masked.push({ label: exchange, value: exchange }));
		this.setState({ maskedExchanges: masked });
	}

	loadExchanges = async () => {
		let exchanges = await CandleControler.GetAvailableExchanges();
		this.setState({ exchanges: exchanges });
		this.applyMaskToExchanges();
	};

	loadMarkets = async () => {
		let markets = await MarketController.List();
		this.setState({ markets: markets });
		this.applyMaskToMarkets();
	};

	applyMaskToMarkets() {
		let masked = this.state.maskedMarkets;
		this.state.markets.map((market: Market) => {
			masked.push({ label: `${market.Prefix}${market.Ticker}`, value: market.ID });
		});
		this.setState({ maskedMarkets: masked });
	}

	addFund = () => {
		if (this.state.Fund) {
			if (this.state.Fund.Exchange != null) {
				this.setState({ isUpdating: !this.state.isUpdating });
				toast.promise(FundController.Create(this.state.Fund).then((returnedFund: Fund) => {
					this.props.onAddFund(returnedFund);
					this.generateNewFund();
					this.props.onHide();
				}),
					{
						pending: "Criando fundo",
						error: "Ocorreum um erro 😡"
					});
			}
		}
	};

	handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (this.state.Fund) {
			if (e.target.dataset.prop == "name") {
				let Fund = this.state.Fund;
				Fund.Name = e.target.value.replace("/", "").toUpperCase();
				this.setState({ Fund: Fund });
			}

			if (e.target.dataset.prop == "precision") {
				let Fund = this.state.Fund;
				Fund.Precision = e.target.valueAsNumber;
				this.setState({ Fund: Fund });
			}

			if (e.target.dataset.prop == "keyPrivate") {
				let Fund = this.state.Fund;
				Fund.Key.Private = e.target.value;

				this.setState({ Fund: Fund });
			}
			if (e.target.dataset.prop == "keyPublic") {
				let Fund = this.state.Fund;
				Fund.Key.Public = e.target.value;
				this.setState({ Fund: Fund });
			}
		}
	};

	handleSelectExchange = (value: any, action: any) => {
		if (this.state.Fund) {
			if (action != null) {
				let Fund = this.state.Fund;
				if (action.action == "select-option") {
					Fund.Exchange = value.value;
					this.setState({ Fund: Fund });
				}
			}
		}
	};

	handleSelectMarket = (value: any, action: any) => {
		if (this.state.Fund) {
			if (action != null) {
				let fund = this.state.Fund;
				fund.Markets = [];
				if (action.action == "select-option") {
					for (const i in value) {
						if (Object.prototype.hasOwnProperty.call(value, i)) {
							const market = value[i];
							const selected = this.state.markets.filter((x: Market) => x.ID == market.value)[0];
							fund.Markets.push(selected);
						}
					}
					this.setState({ Fund: fund });
				}
			}
		}
	};

	render() {
		return (
			<>
				<Modal show={this.props.show} onHide={this.props.onHide} centered>
					<Modal.Header closeButton>
						<h5>Adicionar fundo</h5>
					</Modal.Header>
					<Modal.Body>
						<Form>
							<Form.Group>
								<Form.Control name="name" type="text" placeholder="Nome" onChange={this.handleChange} data-prop="name"></Form.Control>
							</Form.Group>
							<Form.Group>
								<Select placeholder="Exchange" options={this.state.maskedExchanges} onChange={this.handleSelectExchange}></Select>
							</Form.Group>
							<Form.Group>
								<Form.Control
									name="precision"
									type="number"
									placeholder="Precisão das quantidades e preços"
									min={0}
									onChange={this.handleChange}
									data-prop="precision"></Form.Control>
							</Form.Group>
							{/* <Form.Group>
								<Select placeholder="Mercados" isMulti={true} options={this.state.maskedMarkets} onChange={this.handleSelectMarket}></Select>
							</Form.Group> */}
							<Col>
								<h5>Chaves para gerenciamento de API</h5>
								<Form.Group>
									<Form.Control
										name="keyPrivate"
										type="text"
										placeholder="API KEY (Private)"
										onChange={this.handleChange}
										data-prop="keyPrivate"></Form.Control>
								</Form.Group>
								<Form.Group>
									<Form.Control name="keyPublic" type="text" placeholder="API KEY (Public)" onChange={this.handleChange} data-prop="keyPublic"></Form.Control>
								</Form.Group>
							</Col>
						</Form>
					</Modal.Body>
					<Modal.Footer>
						<Button id="add-Fund" onClick={this.addFund}>
							<FaPlus />
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}
