import React, { Component } from "react";
import UserController from "../../../controllers/v1/UserController";
import { Form } from "react-bootstrap";
import "../../../sass/pages/login.scss";
import history from "../../../history";
import { Center, Button, VStack } from "@chakra-ui/react";


interface IProps {
}

interface IState {
	UserName: string;
	Password: string;
	ErrorMessage: string;
	redirect: boolean;
}

class Login extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			UserName: "",
			Password: "",
			ErrorMessage: "",
			redirect: false
		};
	}

	Login = async () => {
		this.setState({ ErrorMessage: "" });
		let response = await UserController.Login(this.state.UserName, this.state.Password);
		if (response != null) {
			if (typeof (response) === "string") {
				this.setState({ ErrorMessage: response });
				localStorage.removeItem("token");
			} else {
				history.push("/authHome");
			}
		}
	};

	render() {
		return (
			<>
				<Center h='100vh'>
					<VStack spacing={1}>
						<Form.Group controlId="username">
							<Form.Label>
								<strong>Username</strong>
							</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter username"
								value={this.state.UserName}
								onChange={(e: any) => this.setState({ UserName: e.target.value })}
							/>
						</Form.Group>
						<Form.Group controlId="pwd">
							<Form.Label>
								<strong>Password</strong>
							</Form.Label>
							<Form.Control
								type="password"
								placeholder="Password"
								value={this.state.Password}
								onChange={(e: any) => this.setState({ Password: e.target.value })}
							/>
						</Form.Group>

						<p className="text-danger">{this.state.ErrorMessage}</p>
						<Button onClick={() => this.Login()}>
							Logar
						</Button>
					</VStack>

				</Center>
			</>
		);
	}
}

export default Login;
