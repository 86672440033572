import { Button } from "@chakra-ui/react";
import { FaSync } from "react-icons/fa";
import { toast } from "react-toastify";
import { useRankingPreset } from "../../../hooks/useRankingPreset";
import { useStudy } from "../../../hooks/useStudy";

export function SyncButton() {
    const { getStudies } = useStudy();
    const { getRankingPresets } = useRankingPreset();


    async function sync(): Promise<void> {
        getStudies();
        getRankingPresets();
        toast.success("Sincronização finalizada!")
    }

    return (
        <Button leftIcon={<FaSync />} bg="purple.500" size="sm"
            title="Sincronize os presets, critérios e codes recém enviados sem precisar atualizar a página"
            onClick={sync}>Sync</Button>
    )
}