import React from "react";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { useRankingPreset } from "../../../hooks/useRankingPreset";
import { Select } from "@chakra-ui/react";

export function SelectRankingPreset() {
    const { UpdateSelectedPreset } = useBasket();
    const { RankingPresets } = useRankingPreset();

    function handleChange(event: any) {
        const selected = RankingPresets.find(x => x.ID === parseInt(event.target.value));
        if (selected)
            UpdateSelectedPreset(selected);
    }

    return (
        <Select onChange={handleChange} defaultValue="">
            <option hidden disabled value="">Selecionar preset</option>
            {!!RankingPresets && RankingPresets.map((x) => <option value={x.ID} key={x.ID}>{x.Name}</option>)}
        </Select>
    )
}