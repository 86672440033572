enum AccumulationRule {
	Sum,
	Average
}

class Study {
	ID: number;
	Surname: string;
	Criteria: string;
	Start: string | null;
	End: string | null;
	Weight: number;
	RollingMonths: number;
	RangeType: string;
	RangeDescription: string;
	ApplyOnlyToCodes: boolean;
	Message: string;
	AccumulationRule: AccumulationRule;
	Parameter: string;

	constructor(id: number, surname: string, criteria: string);
	constructor(id = 0, surname = "", criteria = "CAGR", start = null, end = null, weight = 1, rollingMonths = 0, rangeType = "Backtest", rangeDescription = '', applyOnltToCodes = false, message = '',
		accumulationRule = AccumulationRule.Sum, parameter = "") {
		this.ID = id;
		this.Surname = surname;
		this.Criteria = criteria;
		this.Start = start;
		this.End = end;
		this.Weight = weight;
		this.RollingMonths = rollingMonths;
		this.RangeType = rangeType;
		this.RangeDescription = rangeDescription;
		this.ApplyOnlyToCodes = applyOnltToCodes;
		this.Message = message;
		this.AccumulationRule = accumulationRule;
		this.Parameter = parameter;
	}
}

export { Study, AccumulationRule }