export class APIKey {
	ID: number;
	Public: string;
	Private: string;

	constructor(id: number=0, privateKey: string = "", publicKey: string="") {
		this.ID = id;
		this.Private = privateKey;
		this.Public = publicKey;
	}
}