import { Box, Button, HStack, SimpleGrid } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaChevronDown, FaPlus, FaTrash } from "react-icons/fa";
import { DisplaySlot } from "./DisplaySlot";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { TransactionCostModal } from "../Code/TransactionCostModal";
import { ErrorBoundary } from "../../ErrorBoundary";
import { CombinationTypeSelectorModal } from "../Code/CombinationTypeSelectModal";
import { CountPossibleCombinationsTag } from "../Code/CombinationsCalc";
import { MoneyManagementConfigModal } from "../Ranking/MoneyManagement/MoneyManagementConfigModal";
import { ElcGeneration } from "../Code/ElcGeneration";

export function SlotManagement() {
    const { AddSlots, Slots, ResetSlots, RemoveSlot } = useBasket();
    const [isTransactionCostModalOpen, setIsTransactionCostModalOpen] = useState<boolean>(false);

    const toggleTransactionCostModal = () => {
        setIsTransactionCostModalOpen(!isTransactionCostModalOpen);
    }

    const deleteSlot = (id: number) => {
        RemoveSlot(id);
    }

    return (
        <>
            <ErrorBoundary MessageOnError="Ocorreu um erro ao gerenciar os custos.">
                <TransactionCostModal show={isTransactionCostModalOpen} onHide={toggleTransactionCostModal} />
            </ErrorBoundary>
            <Box h="50%" overflowY={"auto"} mt="2" mb="2">
                <Box mb="2">
                    <HStack justify="center">
                        <Button size="sm" leftIcon={<FaPlus />}
                            onClick={() => AddSlots(1)}>Incluir 1 slot</Button>
                        <Menu>
                            <MenuButton as={Button} size="sm" rightIcon={<FaChevronDown />}>
                                Gerenciar slots e codes
                            </MenuButton>
                            <MoneyManagementConfigModal />
                            <MenuList>
                                <MenuItem onClick={ResetSlots} icon={<FaTrash />}>Apagar todos os slots</MenuItem>
                            </MenuList>
                        </Menu>
                        <Menu>
                            <MenuButton as={Button} size="sm" rightIcon={<FaChevronDown />}>
                                Demais configs.
                            </MenuButton>
                            <MenuList>
                                <CombinationTypeSelectorModal />
                                <MenuItem onClick={toggleTransactionCostModal}>Custos</MenuItem>
                                <ElcGeneration />
                                <MenuItem isDisabled={true}>Alavancagem</MenuItem>
                            </MenuList>
                        </Menu>
                        <CountPossibleCombinationsTag />
                    </HStack>
                </Box>
                <SimpleGrid columns={6} spacing={2}>
                    {Slots !== undefined && Slots.length > 0 &&
                        Slots.map((slot) => (
                            <ErrorBoundary MessageOnError="Ocorreu um erro">
                                <DisplaySlot id={slot} key={slot} deleteSlot={deleteSlot} />
                            </ErrorBoundary>
                        ))}
                </SimpleGrid>

            </Box >
        </>

    );
}