import React from "react";
import { MarketCandlesAbstractProvider } from "../../../../hooks/useMarketDataAbstract";
import { MarketDataAbstractTable } from "./Table";
import { MarketProvider } from "../../../../hooks/useMarket";
import { GetMarketDataConfig } from "./GetMarketDataConfig";
import { Row, Col } from "react-bootstrap";

interface MarketDataProps {

}

export function MarketData(props: MarketDataProps) {
    return (
        <MarketCandlesAbstractProvider>
            <MarketProvider>
                <Row className="justify-content-center">
                    <Col xs="4">
                        <GetMarketDataConfig />
                    </Col>
                </Row>

            </MarketProvider>
            <Row className="justify-content-center">
                <Col xs="10">
                    <MarketDataAbstractTable />
                </Col>
            </Row>
        </MarketCandlesAbstractProvider>
    );
}