import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import dayjs from "dayjs";
import { Heading, Button, VStack, useDisclosure, Table, Thead, Tr, Th, Text, Tbody, Td, Box, Checkbox, IconButton } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react'
import { Input } from "../../Input";
import Fuse from 'fuse.js'
import { uniqBy } from "lodash";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { useStudy } from "../../../hooks/useStudy";
import { Study } from "../../../models/Study";

export function SelectStudy() {

    const { isOpen, onClose, onToggle } = useDisclosure();
    const [searchValue, setSearchValue] = useState<string>("");
    const [foundStudies, setFoundStudies] = useState<Study[]>([]);
    const [selectedStudies, setSelectedStudies] = useState<Study[]>([]);

    const { AddStudies } = useBasket();
    const { studies, getRangeDescription } = useStudy();
    const operators = [
        { operator: '&', meaning: 'and' },
        { operator: '!', meaning: 'not' },
        { operator: '|', meaning: 'or' },
        { operator: "'xyz", meaning: 'include xyz' },
        { operator: "!abc", meaning: "don't include abc" },
        { operator: "^abc", meaning: "start with abc" },
        { operator: "!^abc", meaning: "don't start with abc" },
        { operator: ".abc", meaning: "end with abc" },
        { operator: "!.abc", meaning: "don't end with abc" },
    ]

    useEffect(() => {
        const values = searchValue.split(",").filter(x => x !== "").map(x => x.replace(/\s/g, ""))
        let searchResults: Study[] = [];

        values.forEach(value => {
            const found: Study[] = new Fuse(studies, { keys: ['Surname', 'Criteria', 'RangeType'], threshold: .2, useExtendedSearch: true })
                .search(value).map(x => x.item);
            if (found.length > 0)
                searchResults = [...searchResults, ...found];
        })
        const finalResult = uniqBy(searchResults, 'Surname')
        setFoundStudies(finalResult);
    }, [searchValue])

    function HandleSelectItem(item: Study) {
        if (!selectedStudies.includes(item)) 
            setSelectedStudies([...selectedStudies, item]);
        else {
            const updated = selectedStudies.filter(x => x.ID !== item.ID);
            setSelectedStudies(updated);
        }
    }

    function isSelected(item: Study) {
        return selectedStudies.includes(item);
    }

    function selectAll() {
        if (selectedStudies.length !== foundStudies.length) setSelectedStudies(foundStudies);
        else setSelectedStudies([]);
    }

    function handleAddItems() {
        const updated = selectedStudies;
        AddStudies(updated)
        onToggle()
        setSelectedStudies([])
    }

    return (
        <>
            <IconButton icon={<FaSearch />} size='sm' onClick={onToggle}
                title="Pesquise critérios existentes" aria-label="Buscar critérios" />
            <Modal size="full" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        <Heading size="md">Pesquisar critérios e adicionar ao ranking</Heading>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <VStack w='100%'>
                            <Input name="searchVal" label="Insira os termos para pesquisa" type="text"
                                placeholder="Para uma busca mais refinada consulte os operadores lógicos de busca"
                                value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />

                            <Box overflowY="auto" w='70vw' maxHeight="60vh">
                                <Text align={"center"}>{foundStudies.length} encontrado(s) - {selectedStudies.length} selecionado(s).</Text>
                                <Table size="sm" variant="base">
                                    <Thead>
                                        <Tr>
                                            <Th colSpan={5} textAlign="center">Informações do critério</Th>
                                            <Th colSpan={5} textAlign="center">Range</Th>
                                        </Tr>
                                        <Tr>
                                            <Th textAlign={"center"}>
                                                <Checkbox
                                                    onChange={selectAll}
                                                    isChecked={selectedStudies.length > 0 && selectedStudies.length === foundStudies.length}
                                                    isIndeterminate={selectedStudies.length > 0 && selectedStudies.length !== foundStudies.length}
                                                />
                                            </Th>
                                            <Th>Critério</Th>
                                            <Th>Nome</Th>
                                            <Th>Tipo do range</Th>
                                            <Th textAlign="right">Peso</Th>
                                            <Th textAlign="right">Meses rolling</Th>
                                            <Th>Data de início</Th>
                                            <Th>Data de término</Th>
                                            <Th>Descrição</Th>

                                        </Tr>
                                    </Thead>
                                    <Tbody >
                                        {foundStudies.map((item, i) =>
                                            <Tr key={i}>
                                                <Td textAlign={"center"}>
                                                    <Checkbox isChecked={isSelected(item)}
                                                        onChange={() => HandleSelectItem(item)} />
                                                </Td>
                                                <Td>{item.Criteria}</Td>
                                                <Td>{item.Surname}</Td>
                                                <Td>{item.RangeType}</Td>
                                                <Td textAlign="right">{item.Weight}</Td>
                                                <Td textAlign="right">{item.RollingMonths}</Td>
                                                <Td>{item.Start !== null && dayjs(item.Start).format("YYYY-MM-DD")}</Td>
                                                <Td>{item.End !== null && dayjs(item.End).format("YYYY-MM-DD")}</Td>
                                                <Td>{getRangeDescription(item.RangeType, item.RollingMonths, item.Start, item.End)}</Td>
                                            </Tr>
                                        )}
                                    </Tbody>
                                </Table>
                            </Box>
                            <Box>
                                <Accordion allowToggle>
                                    <AccordionItem>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                Operadores lógicos de busca
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                        <AccordionPanel pb={4}>
                                            <Table size="sm">
                                                <Thead>
                                                    <Tr>
                                                        <Th>Operador</Th>
                                                        <Th>Significado</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {operators.map((x, i) => (
                                                        <Tr key={i}>
                                                            <Td>{x.operator}</Td>
                                                            <Td>{x.meaning}</Td>
                                                        </Tr>))}
                                                </Tbody>
                                            </Table>
                                        </AccordionPanel>
                                    </AccordionItem>
                                </Accordion>
                            </Box>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="add-slot" onClick={handleAddItems}>
                            Adicionar ao ranking
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}