import React, { useState } from "react";
import { UpdateMarketModal } from "./UpdateMarket";
import { FaPencilAlt } from "react-icons/fa";
import { Button, Checkbox, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Market } from "../../../models/Market";
import { useMarket } from "../../../hooks/useMarket";

interface MarketTableProps {
    selectedMarkets: Market[];
    setSelectedMarkets: (selectedMarkets: Market[]) => void;
}

export function MarketTable({ selectedMarkets, setSelectedMarkets }: MarketTableProps) {
    const { markets } = useMarket();
    const [marketOnEdition, setMarketOnEdition] = useState<Market>(new Market(""));
    const [displayingUpdateModal, setDisplayingUpdateModal] = useState<boolean>(false);

    function selectAll() {
        if (markets.length === 0)
            return;
        selectedMarkets.length === 0 ? setSelectedMarkets(markets) : setSelectedMarkets([]);
    }

    function handleCheckMarket(market: Market) {
        selectedMarkets.some(m => m.ID === market.ID) === false ?
            setSelectedMarkets([...selectedMarkets, market]) :
            setSelectedMarkets(selectedMarkets.filter(m => m.ID !== market.ID));
    }

    const editMarket = (market: Market) => {
        setMarketOnEdition(market);
        setDisplayingUpdateModal(true);
    }

    const setUpdateModalVisibility = () => {
        setDisplayingUpdateModal(!displayingUpdateModal);
    }

    return (
        <>
            <UpdateMarketModal market={marketOnEdition} show={displayingUpdateModal} onHide={setUpdateModalVisibility} />
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th colSpan={4} className="text-center">Informações do mercado</Th>
                        <Th colSpan={3} className="text-center">Money management</Th>
                        <Th colSpan={3} className="text-center">Custos por trade</Th>
                        <Th></Th>
                    </Tr>
                    <Tr>
                        <Th>Prefix</Th>
                        <Th>Ticker</Th>
                        <Th>Exchange</Th>
                        <Th>Contrato</Th>
                        <Th className="text-right">Valor do ponto</Th>
                        <Th className="text-right">Lote mínimo</Th>
                        <Th className="text-right">Expo pl qdo. op.</Th>
                        <Th className="text-center">Slippage</Th>
                        <Th className="text-center">Comissão</Th>
                        <Th className="text-center">
                            <Checkbox
                                isChecked={selectedMarkets.length > 0 && selectedMarkets.length === markets.length}
                                isIndeterminate={markets !== undefined && selectedMarkets.length > 0 && selectedMarkets.length !== markets.length}
                                onChange={selectAll} />
                        </Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {!!markets && markets.map(market => (
                        <Tr key={market.ID}>
                            <Td>{market.Prefix}</Td>
                            <Td><strong>{market.Ticker}</strong></Td>
                            <Td>{market.Exchange}</Td>
                            <Td>{market.ContractType}</Td>
                            <Td className="text-right">{market.BigPointValue.toLocaleString("en-US", { style: "currency", currency: "USD" })}</Td>
                            <Td className="text-right">{market.MinContractsHeld}</Td>
                            <Td className="text-right">{market.Exposition.toLocaleString("en-US", { style: "percent", maximumSignificantDigits: 2 })}</Td>
                            <Td className="text-right">{market.TransactionCost == null ? "Nao cfg." : `${(market.TransactionCost.Slippage).toLocaleString("en-US", { style: "percent", maximumSignificantDigits: 2 })}`}</Td>
                            <Td className="text-right">{market.TransactionCost == null ? "Nao cfg." : `${(market.TransactionCost.Commission).toLocaleString("en-US", { style: "percent", maximumSignificantDigits: 2 })}`}</Td>
                            <Td className="text-right">
                                <Checkbox
                                    onChange={() => handleCheckMarket(market)}
                                    isChecked={selectedMarkets.includes(market)}
                                />
                            </Td>
                            <Td className="text-center">
                                <Button size="xs" title="Editar mercado" onClick={() => editMarket(market)}>
                                    <FaPencilAlt />
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>

            </Table>
        </>
    )
}