import StudyResult from "./StudyResult";
import { HistoricalSerie } from "./HistoricalSerie";
import { Code } from "./Strategy/Code";

class Basket {
	ID: number;
	Name: string;
	AVGResult: number;
	AVGPosition: number;
	Serie: Array<HistoricalSerie>;
	Results: Array<StudyResult>;
	Elements: string;
	Codes: Array<Code>;
	ReportOutputDir: string;

	constructor();
	constructor(name = "", avgResult = 0, avgPosition = 0, serie = [], results = [], elements = "", codes = []) {
		this.ID = 0;
		this.Name = name;
		this.AVGResult = avgResult;
		this.AVGPosition = avgPosition;
		this.Serie = serie;
		this.Results = results;
		this.Elements = elements;
		this.Codes = codes;
		this.ReportOutputDir = "";

	}
}

export { Basket }