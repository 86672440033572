import { Button, HStack, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { EquityCurveDownloader } from "../../EquityCurve/EquityCurveDownloader";
import { RankingTableConfigsModal } from "./Table/RankingTableConfigsModal";

export function BasketManagement() {
    const { RateBasket, ResetBaskets } = useBasket();
    const [isRatingBaskets, setIsRatingBaskets] = useState<boolean>(false);
    const eqDownloader = useDisclosure();

    async function Rate() {
        setIsRatingBaskets(true);
        await RateBasket();
        setIsRatingBaskets(false);
    }

    return (
        <>
            <EquityCurveDownloader start={new Date(1500, 4, 22)} end={new Date()} isOpen={eqDownloader.isOpen} onClose={eqDownloader.onClose} />
            <HStack>
                <Button size="sm" bg="green.500" onClick={Rate} isLoading={isRatingBaskets}>Gerar ranking</Button>
                <Menu>
                    <MenuButton as={Button}
                        size="sm"
                        rightIcon={<FaChevronDown />}>
                        Config. ranking
                    </MenuButton>
                    <MenuList>
                        <MenuItem onClick={ResetBaskets}>Limpar resultados</MenuItem>
                        <RankingTableConfigsModal />
                        <MenuItem onClick={eqDownloader.onToggle}>Copiar equity curves</MenuItem>
                    </MenuList>
                </Menu>
            </HStack>

        </>

    )
}