import React, { Component } from "react"
import Dropzone from "react-dropzone"
import isEqual from "lodash.isequal"
import Button from "react-bootstrap/Button"
import CodeController from "../controllers/v1/CodeController"

export default class Upload extends Component {
	state = {
		dragEnter: false,
		onDragEnter: () => this.setState({ dragEnter: true }),
		onDragLeave: () => this.setState({ dragEnter: false }),
		files: [],
		filesName: ["Deslize os arquivos para cá"],
		messageClass: "",
		canSend: false,
		dots: "",
	}

	loading = () => {
		this.loadingInterval = setInterval(() => {
			let dots = this.state.dots
			dots = dots === "..." ? "" : `${dots}.`
			this.setState({
				dots,
				filesName: [["Carregando", dots].join("")],
			})
		}, 500)
	}

	sendUpload = () => {
		// Integração com API
		this.loading()
		let formData = new FormData()
		this.state.files.map((file) => formData.append("Files", file))
		CodeController.Upload(formData)
			.then((response) => {
				clearInterval(this.loadingInterval)
				this.setState({
					filesName: ["Arquivo(s) enviado(s)"],
				})
			})
			.catch((err) => {
				clearInterval(this.loadingInterval)
				this.setState({
					filesName: ["Erro ao enviar o arquivo"],
					messageClass: "warning",
				})
			})
	}

	onDrop = (files) => {
		if (files.length) {
			let fileName = []
			let filesShouldUpload = []
			files.map((file) => {
				if (file.type === "text/csv" || file.type === "text/plain") {
					filesShouldUpload.push(file);
					fileName.push(file.name);
				}
			})

			if (filesShouldUpload.length) {
				if (!isEqual(filesShouldUpload, files)) fileName.push("Algum(ns) arquivo(s) não foram adicionados por não serem .txt ou .csv");
				this.setState({
					files: filesShouldUpload,
					filesName: fileName,
					canSend: true,
					messageClass: "",
				})
			} else {
				this.setState({
					filesName: [" Os arquivos adicionados devem corresponder à extensão .txt ou .csv"],
					messageClass: "warning",
				})
			}
		}
		this.setState({ dragEnter: false })
	}

	render() {
		return (
			<>
				<Dropzone onDrop={this.onDrop} onDragEnter={this.state.onDragEnter} onDragLeave={this.state.onDragLeave}>
					{({ getRootProps, getInputProps }) => (
						<section className={`dropzone ${this.state.dragEnter ? "hover" : ""}`}>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<p>
									{this.state.filesName.map((e) => (
										<span className={this.state.messageClass} key={Math.random()}>
											{e}
											<br />
										</span>
									))}
								</p>
							</div>
							<Button onClick={this.sendUpload} disabled={!this.state.canSend}>
								Enviar
							</Button>
						</section>
					)}
				</Dropzone>
				<p className="text-center"><h4>Se precisar, copie a lista de sistemas abaixo</h4></p>
				<p className="text-center">
					{this.state.filesName[0] !== "Deslize os arquivos para cá" ?
						this.state.filesName.map((name) => name.replace('.txt', '')).join(', ')
						: "Adicione algum sistema"}
				</p>
			</>

		)
	}
}
