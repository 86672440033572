import { VStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, IconButton, Button } from "@chakra-ui/react";
import { useState } from "react";
import { FaSave } from "react-icons/fa";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { useRankingPreset } from "../../../hooks/useRankingPreset";
import RankingPreset from "../../../models/Ranking/RankingPreset";
import { Input } from "../../Input";

export function SaveRankingPresetModal() {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const { Slots, Studies } = useBasket();
    const { SaveRankingPreset } = useRankingPreset();
    const [name, setName] = useState<string>("");

    const savePreset = () => {
        let preset = new RankingPreset(0, name, Slots.length, Studies);
        SaveRankingPreset(preset);
    }

    return (
        <>
            <IconButton size="sm" icon={<FaSave />} aria-label="Salvar preset" title="Salvar ranking atual como preset"
                onClick={onToggle} />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Salvar preset</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack align="left">
                            <Input label="Nome" name="Nome" value={name} onChange={(e) => setName(e.target.value)} />
                            <Button onClick={savePreset}>Salvar</Button>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}