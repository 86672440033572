import React from 'react';
import { Code } from '../../../models/Strategy/Code';
import { Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton, useDisclosure, VStack }
    from '@chakra-ui/react'
import { Text, IconButton } from "@chakra-ui/react";
import { FaInfo } from 'react-icons/fa';
import dayjs from 'dayjs';

interface Props {
    code: Code;
}
export function CodeResumePopover({ code }: Props) {
    const { isOpen, onToggle, onClose } = useDisclosure()

    return (
        <Popover
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={onClose}
            placement='right'
            size="xl"
            closeOnBlur={true}>
            <PopoverTrigger>
                <IconButton size="xs" title="Ver resumo sobre este code" aria-label="resume"
                    icon={<FaInfo />} onClick={onToggle} />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverHeader textAlign={"left"} fontWeight='bold'>{code.Name}</PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody textAlign={"left"}>
                    <VStack align="left" spacing="5">
                        <VStack align="left">
                            <Text fontWeight='semibold'>Info geral</Text>
                            <Text>Data da criação: {dayjs(code.CreationDate).format("YYYY-MM-DD")}</Text>
                            <Text>Data da otimização: {dayjs(code.BacktestedDate).format("YYYY-MM-DD")}</Text>
                        </VStack>
                        <VStack align="left">
                            <Text fontWeight='semibold'>Backtest</Text>
                            <Text>Upload do arquivo: {dayjs(code.Backtest.UploadDate).format("YYYY-MM-DD HH:mm:ss")}</Text>
                            <Text>1ª barra: {dayjs(code.Backtest.FirstDate).format("YYYY-MM-DD")}</Text>
                            <Text>Última barra: {dayjs(code.Backtest.LastDate).format("YYYY-MM-DD")}</Text>
                            <Text>Mercado: {code.Backtest.MarketTicker}</Text>
                        </VStack>
                        <VStack align="left">
                            <Text fontWeight='semibold'>Custos para esta rodada</Text>
                            <Text>Comissão: {code.Market.TransactionCost.Commission * 100}%</Text>
                            <Text>Slippage: {code.Market.TransactionCost.Slippage * 100}%</Text>
                        </VStack>
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}