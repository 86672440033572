import { Strategy } from "./Strategy/Strategy";

export class HistoricalSerie {
	constructor(
		id: number,
		strategy: Strategy,
		type: string,
		date: Date,
		quote: number,
		percentualVariation: number,
		drawdown: number,
		marketPosition: number = 0,
		NetWorthOnClose: number = 0,
		VarPts: number = 0,
		FinancialAdjustOnClose: number = 0,
		ContractsHeldOnClose: number = 0
	) {
		this.ID = id;
		this.Strategy = strategy;
		this.Type = type;
		this.Date = date;
		this.Quote = quote;
		this.PercentualVariation = percentualVariation;
		this.Drawdown = drawdown;
		this.MarketPosition = marketPosition;
		this.NetWorthOnClose = NetWorthOnClose;
		this.VarPts = VarPts;
		this.FinancialAdjustOnClose = FinancialAdjustOnClose;
		this.ContractsHeldOnClose = ContractsHeldOnClose;
	}
	ID: number;
	Strategy: Strategy;
	Type: string;
	Date: Date;
	Quote: number;
	PercentualVariation: number;
	Drawdown: number;
	MarketPosition: number;
	NetWorthOnClose: number;
	VarPts: number;
	FinancialAdjustOnClose: number;
	ContractsHeldOnClose: number;

}
