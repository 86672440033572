import React, { Component } from "react";
import "./sass/app.scss";
import Routes from "./routes";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";

export default class App extends Component {
	render() {
		return (

			<div className="App">
				<ToastContainer
					position="top-right"
					autoClose={6000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				/>
				<Routes></Routes>
			</div>
		);
	}
}
