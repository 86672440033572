import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import AddFund from "../../../components/v1/Fund/AddFund";
import UpdateFund from "../../../components/v1/Fund/UpdateFund";
import { Fund } from "../../../models/Strategy/Fund";
import FundController from "../../../controllers/v1/FundController";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

interface IProps {

}

interface IState {
	isAddSymbolModalOpened: boolean;
	isEditSymbolModalOpened: boolean;
	Funds: Array<Fund>;
	SelectedFunds: Array<Fund>;
}

export default class FundHome extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isAddSymbolModalOpened: false,
			isEditSymbolModalOpened: false,
			Funds: [],
			SelectedFunds: [],
		};
		this.getFunds();
	}

	getFunds = () => {
		toast.promise(FundController.Get().then((x: Array<Fund>) => this.setState({ Funds: x })), { pending: "Buscando fundos" });
	};

	toggleAddSymbolModal = () => {
		this.setState({ isAddSymbolModalOpened: !this.state.isAddSymbolModalOpened });
	};

	toggleEditSymbolModal = () => {
		this.setState({ isEditSymbolModalOpened: !this.state.isEditSymbolModalOpened });
	};

	addFund = (Fund: Fund) => {
		let Funds = this.state.Funds;
		Funds.push(Fund);
		this.setState({ Funds: Funds });
	};

	deleteFund = () => {
		if (this.state.SelectedFunds) {
			for (const i in this.state.SelectedFunds) {
				if (Object.prototype.hasOwnProperty.call(this.state.SelectedFunds, i)) {
					const fund = this.state.SelectedFunds[i];
					toast.promise(FundController.Delete(fund), { pending: "Deletando fundo" });
					this.setState({ Funds: this.state.Funds.filter((x: Fund) => x.ID !== fund.ID) });
				}
			}
		}
	}

	handleCheckFund = (Fund: Fund | number) => (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		let selecionados = this.state.SelectedFunds;
		if (typeof Fund == "number") {
			//* Se o tipo de Fund for numero, ele entende que deve selecionar todos os mercados da lista
			if (e.currentTarget.checked === true) {
				this.state.Funds.map((Fund: Fund) =>
					!selecionados.includes(Fund) ?
						selecionados.push(Fund) : null
				);
			} else {
				selecionados = [];
			}
		} else {
			//* Se o tipo for mercado, ele entende que deve selecionar o mercado especificado.
			if (e.currentTarget.checked === true) {
				let mktCandle = this.state.Funds.filter(
					(thing, i, arr) => arr.findIndex((t) => t.ID === Fund.ID) === i)[0];
				if (mktCandle != null && !selecionados.includes(mktCandle)) {
					selecionados.push(mktCandle);
				}
			} else {
				selecionados = selecionados.filter((selected: Fund) => selected.ID !== Fund.ID);
			}
		}
		this.setState({ SelectedFunds: selecionados });
	};

	FundIsSelected(Fund: Fund) {
		return this.state.SelectedFunds.includes(Fund);
	}

	render() {
		return (
			<>
				<AddFund show={this.state.isAddSymbolModalOpened} onHide={this.toggleAddSymbolModal} onAddFund={this.addFund}></AddFund>
				<UpdateFund show={this.state.isEditSymbolModalOpened} onHide={this.toggleEditSymbolModal} Funds={this.state.SelectedFunds}></UpdateFund>
				<Row className="justify-content-center" style={{ paddingTop: 5, paddingBottom: 20 }}>
					<Col sm="auto">
						<h4>Controle de fundos</h4>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col sm="auto">
						<Button title="Adicionar" onClick={this.toggleAddSymbolModal}>
							<FaPlus></FaPlus>
						</Button>
						{this.state.SelectedFunds.length >= 1 ? (
							<>
								<Button title="Editar" onClick={this.toggleEditSymbolModal}>
									<FaPencilAlt></FaPencilAlt>
								</Button>
								<Button title="Excluir" onClick={this.deleteFund} variant="danger" style={{ marginLeft: "5rem" }}>
									<FaTrashAlt></FaTrashAlt>
								</Button>{" "}
							</>
						) : null}
					</Col>
				</Row>

				<Row className="justify-content-center" style={{ paddingTop: 3 }}>
					<Col sm="10">
						<Table className="table-condensed table-bordered" responsive hover striped size="sm">
							<thead>
								<tr>
									<th>Nome</th>
									<th>Exchange</th>
									<th className="text-center">Precisão dos contratos</th>
									<th className="text-center">API Key</th>
									<th>Mercados</th>
									<th className="text-center">
										<Form.Check checked={this.state.SelectedFunds.length > 0} onClick={this.handleCheckFund(-1)} />
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.Funds.map((Fund: Fund) => (
									<tr key={Fund.ID}>
										<td>{Fund.Name}</td>
										<td>{Fund.Exchange}</td>
										<td className="text-center">{Fund.Precision}</td>
										<td className="text-center">{Fund.Key.Public === "***" ? "A chave foi ocultada" : "Não existe chave cadastrada"}</td>
										<td>{Fund.Markets.length > 1 ? Fund.Markets.map((x) => `${x.Prefix + x.Ticker}, `) : Fund.Markets.map((x) => x.Prefix + x.Ticker)}</td>
										<td className="text-center">
											<Form.Check id={`${Math.random()}`} checked={this.FundIsSelected(Fund)} onClick={this.handleCheckFund(Fund)} />
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Col>
				</Row>
			</>
		);
	}
}
