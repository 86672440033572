import React, { useState } from "react";
import { useMarketDataAbstract } from "../../../../hooks/useMarketDataAbstract";
import Table from "react-bootstrap/Table";
import { FormatDate } from "../../../../utils/Date";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import { ConfigureMarketDataExportModal } from "./ConfigureMarketDataExportModal";
import { FaDownload, FaSyncAlt } from "react-icons/fa";

export function MarketDataAbstractTable() {
    const { extendedAbstracts, editAbstract, checkAllAbstracts, updateSelected } = useMarketDataAbstract();
    const [isConfigureMarketDataExportModalOpened, setIsConfigureMarketDataExportModalOpened] = useState(false);
    const [allChecked, setAllChecked] = useState(false);
    const [updateMode, setUpdateMode] = useState('keep');

    function toggleMarketDataExportModal() {
        setIsConfigureMarketDataExportModalOpened(!isConfigureMarketDataExportModalOpened);
    }

    function CheckAbstracts() {
        checkAllAbstracts(!allChecked);
        setAllChecked(!allChecked);
    }

    return (
        <>
            <ConfigureMarketDataExportModal
                isConfigureMarketDataExportModalOpened={isConfigureMarketDataExportModalOpened}
                hideModal={toggleMarketDataExportModal}
            />
            <Row className="mt-2">
                <Col className="text-center">
                    <strong>{extendedAbstracts.length} datas - {extendedAbstracts.filter(x => x.isUpdated).length} atualizada(s)</strong>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col xs="3">
                    <Form.Control as="select" onChange={event => setUpdateMode(event.target.value)}>
                        <option value="keep">Complementar barras existentes</option>
                        <option value="overwrite">Sobrescrever barras existentes (+ demorado)</option>
                    </Form.Control>
                </Col>
                <Button onClick={() => updateSelected(updateMode)} className="mr-2" title="Atualizar datas"> <FaSyncAlt /> </Button>
            </Row>
            <Row className="mt-2">
                <Col className="text-center">
                    <Button onClick={toggleMarketDataExportModal} title="Baixar datas zipadas"><FaDownload /></Button>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col className="text-center">
                    <Table responsive striped hover size="sm" style={{ overflow: "hidden" }}>
                        <thead>
                            <tr>
                                <th>Prefix</th>
                                <th>Ticker</th>
                                <th>Contrato</th>
                                <th>Exchange</th>
                                <th>Status</th>
                                <th>Mais antiga</th>
                                <th>Mais recente</th>
                                <th>Barras</th>
                                <th><Form.Check id={`${Math.random()}`} checked={allChecked} onClick={CheckAbstracts} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {extendedAbstracts.map(abstract => (
                                <tr key={abstract.ID}>
                                    <td>{abstract.Market.Prefix}</td>
                                    <td>{abstract.Market.Ticker}</td>
                                    <td>{abstract.Market.ContractType}</td>
                                    <td>{abstract.Market.Exchange}</td>
                                    <td>
                                        <Badge
                                            variant={abstract.isUpdated ? "success" : "danger"}
                                            title={abstract.isUpdated ? "Data atualizada" : "Data desatualizada"}>
                                            {abstract.isUpdated ? (
                                                "OK"
                                            ) : (
                                                "DESAT."
                                            )}
                                        </Badge>
                                    </td>
                                    <td>{FormatDate(abstract.Oldest)}</td>
                                    <td>{FormatDate(abstract.Newest)}</td>
                                    <td>{abstract.CountBars}</td>
                                    <td><Form.Check id={`${Math.random()}`} checked={abstract.isSelected} onClick={() => editAbstract(abstract.ID, "isSelected")} /></td>
                                </tr>
                            ))}
                        </tbody>

                    </Table>
                </Col>
            </Row>
        </>

    )
}