import dayjs from "dayjs";

// retorna o dia de hoje no fuso-horário UTC
  export const todayUTC = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate());
  export const yesterdayUTC = dayjs(new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())).subtract(1, 'day').toDate();

  // Formata a data
  export function FormatDate(date: string) {
    const typedDate = new Date(date);
    return new Intl.DateTimeFormat('si-LK').format(typedDate.getTime());
  }
  