import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import SubmitOrder from "../../components/v1/Order/SubmitOrder";

interface IProps {
}

interface IState {
	displaySubmitOrder: boolean;
}

export default class OrderView extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			displaySubmitOrder: true
		};
	}

	handleDisplaySubmitOrder = () => {
		this.setState({ displaySubmitOrder: !this.state.displaySubmitOrder });
	};

		render() {
		return (
			<>
				<SubmitOrder show={this.state.displaySubmitOrder} onHide={this.handleDisplaySubmitOrder}></SubmitOrder>
				<Row>
					<Col xs="auto" className="centerScreen text-center">
						<Col xs="12">
							<Button style={{ marginRight: "1rem" }} onClick={this.handleDisplaySubmitOrder} title="Enviar ordens">
								Enviar ordens
							</Button>
						</Col>
					</Col>
				</Row>
			</>
		);
	}
}
