import { Button, Checkbox, ModalFooter, useDisclosure } from "@chakra-ui/react";
import { FaDownload } from "react-icons/fa";
import { useBasket } from "../../../../hooks/Basket/useBasket";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { Heading, VStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import { Input } from "../../../Input";
import dayjs from "dayjs";
import User from "../../../../models/User";
import { ExportRankingFileContent } from "../../../../models/Ranking/ExportRankingFileContent";

export function ExportRankingModal() {
    const { isOpen, onClose, onToggle } = useDisclosure();
    const { SelectedCodes, Studies, Baskets, Slots } = useBasket();
    const [includeSlots, setIncludeSlots] = useState<boolean>(true);
    const [includeStudies, setIncludeStudies] = useState<boolean>(true);
    const [includeResults, setIncludeResults] = useState<boolean>(true);
    const usr = new User("", "");
    usr.GetFromLocalstorage();
    const [fileName, setFileName] = useState<string>(`rkg  ${usr.Name} ${dayjs(new Date()).format("YYYY-MM-DD HH_MM_ss")}`)

    const makeDownload = () => {
        if (fileName !== "") {
            let content: ExportRankingFileContent = {
                SelectedCodes: null,
                Studies: null,
                Baskets: null,
                Slots: Slots
            }

            if (includeSlots && SelectedCodes.length > 0) content.SelectedCodes = SelectedCodes;
            if (includeStudies && Studies.length > 0) content.Studies = Studies;
            if (includeResults && Baskets.length > 0) content.Baskets = Baskets;

            const jsonData = JSON.stringify(content, null, 2)
            const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(jsonData)}`;


            const element = document.createElement('a');
            element.href = dataUri;
            element.download = `${fileName}.json`;
            element.setAttribute('readonly', 'true')
            element.click();
            onClose()
        }

    }

    return (
        <>
            <Button leftIcon={<FaDownload />} bg="blue.800" size="sm" onClick={onToggle}
                title="Baixe um arquivo contendo toda a sua seleção do ranking atual.">Export</Button>
            <Modal size="lg" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>
                        <Heading size="md">Download do ranking</Heading>
                        <ModalCloseButton />
                    </ModalHeader>
                    <ModalBody>
                        <VStack align={"start"}>
                            <Text fontWeight="thin">Extraia o seu ranking para salvá-lo ou compartilhá-lo.</Text>
                        </VStack>
                        <VStack align={"start"}>
                            <Text fontWeight="bold">Selecione abaixo quais itens você deseja incluir no arquivo</Text>
                            <Checkbox isChecked={includeSlots} onChange={() => setIncludeSlots(!includeSlots)}>Slots</Checkbox>
                            <Checkbox isChecked={includeStudies} onChange={() => setIncludeStudies(!includeStudies)}>Critérios</Checkbox>
                            <Checkbox isChecked={includeResults} onChange={() => setIncludeResults(!includeResults)}>Resultados</Checkbox>
                            <Input name={"fileName"} value={fileName} onChange={(e) => setFileName(e.target.value)} placeholder="Nome do arquivo" sufix=".json" />
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button leftIcon={<FaDownload />} onClick={makeDownload} title="Baixar arquivo">Download</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}