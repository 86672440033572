import IUser from "./Interfaces/IUser";

class User implements IUser {
	ID: number;
	Name: string;
	Password: string;
	LastLogin: string;
	Token: string;

	public constructor(name: string, pwd: string) {
		this.ID = 0;
		this.Name = name;
		this.Password = "";
		this.LastLogin = "";
		this.Token = "";
	}

	/**
	 * GetFromLocalstorage
	 */
	public GetFromLocalstorage() {
		const jsonUser = localStorage.getItem("user");
		if (jsonUser) {
			const user = JSON.parse(jsonUser);
			this.ID = user.ID;
			this.Name = user.Name;
		}
	}
}

export default User;