import React, { useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Heading, VStack, Button, Text, RadioGroup, Radio, HStack } from '@chakra-ui/react'
import { Input } from "../../Input";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { useMarket } from "../../../hooks/useMarket";

interface Props {
    show: boolean;
    onHide: () => void;
}

export function TransactionCostModal({ show, onHide }: Props) {
    const [commission, setCommission] = useState<number>(0);
    const [slippage, setSlippage] = useState<number>(0);
    const [costSource, setCostSource] = React.useState('database')
    const { SelectedCodes, UpdateSelectedCodes, UpdateCosts } = useBasket();
    const { markets } = useMarket();

    const setCosts = () => {
        if (costSource === 'database') {
            const updatedSelectedCodes = SelectedCodes;
            updatedSelectedCodes.forEach(code => {
                let market = markets.find(x => x.ID === code.Market.ID);
                if (market)
                    code.Market.TransactionCost = { ...market.TransactionCost }
            })
            UpdateSelectedCodes(updatedSelectedCodes);
        } else
            UpdateCosts(commission, slippage);
        onHide();
    }

    return (
        <Modal size="lg" isOpen={show} onClose={onHide}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading size="sm">Configurar custos de transação</Heading>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    <VStack spacing="8">
                        <VStack align="left">
                            <Text>Quais custos você deseja utilizar?</Text>
                            <RadioGroup onChange={setCostSource} value={costSource}>
                                <VStack align="left">
                                    <Radio value="database">Custos salvos na base de dados</Radio>
                                    <Radio value="custom">Custos abaixo (iguais para todos os mercados)</Radio>
                                </VStack>
                            </RadioGroup>
                        </VStack>
                        <HStack>
                            <Input name="commission" label="Comissão" type="number"
                                value={commission} onChange={(e: any) => setCommission((parseFloat(e.target.value)))}
                                isDisabled={costSource === 'database'} sufix="%" />
                            <Input name="slippage" label="Slippage" type="number"
                                value={slippage} onChange={(e: any) => setSlippage(parseFloat(e.target.value))}
                                isDisabled={costSource === 'database'} sufix="%" />
                        </HStack>
                        <Text><strong>Aviso:</strong> os custos são aplicados por trade executado.</Text>
                        <VStack>
                            <Button onClick={setCosts}>Utilizar custos para esta rodada</Button>
                        </VStack>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>

    )
}