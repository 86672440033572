import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react'
import { useBasket } from '../../../../hooks/Basket/useBasket';
import { Study } from "../../../../models/Study";

interface Props {
    study:Study;
}

export function StudyWeightInput ({study}:Props) {
    const {UpdateStudyWeight} = useBasket();
    
    return(
        <>
        <NumberInput    size='xs' bgColor='gray.50' color='gray.900' 
                        value={study.Weight} onChange={(value) => UpdateStudyWeight(study, parseFloat(value))}
                        allowMouseWheel>
            <NumberInputField  />
            <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
        </NumberInputStepper>
        </NumberInput>
        </>
    )
}