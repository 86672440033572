import { Study } from "../Study";
import { Code } from "../Strategy/Code";

class MoneyManagementConfig {
	Leverage: number;
	InitialNetWorth: number;

	constructor(leverage: number, initialNetWorth: number) {
		this.Leverage = leverage;
		this.InitialNetWorth = initialNetWorth;
	}
}

class RKGParameters {
	Codes: Code[];
	Criteria: Array<Study>;
	ReturnEquityCurve: boolean;
	InitialNetWorth: number;
	Leverage: number;
	GenerateElc: boolean;

	constructor(codes: Code[], criteria: Study[], initialNetWorth: number, leverage: number, DL = null, returnEquityCurve: boolean = false, generateElc: boolean = false) {
		this.Codes = codes;
		this.Criteria = criteria;
		this.ReturnEquityCurve = returnEquityCurve;
		this.InitialNetWorth = initialNetWorth;
		this.Leverage = leverage;
		this.GenerateElc = generateElc;
	}
}

export { RKGParameters, MoneyManagementConfig as MmConfig }