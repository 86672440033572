import API from "../../api/ApiManager";
import SubmitOrderResult from "../../models/Order/SubmitOrderResult";
import Order from "../../models/Order/Order";
import dayjs from "dayjs";
import { Market } from "../../models/Market";
import { from } from "linq-to-typescript";
import { Fund } from "../../models/Strategy/Fund";

export default class OrderController {
	static async Submit(order: Order): Promise<SubmitOrderResult> {
		if (!order.HasError) {
			const api = new API(`order/submit`);
			const res = await api.postAsync<SubmitOrderResult>(order, undefined);
			if (res) {
				if (res.OrderId !== "-1") {
					res.Message = `Enviada com sucesso em ${dayjs(res.OrderDate).format("YYYY-MM-DD HH:mm:ss")}`;
				}
			}
			return res;
		} else {
			return new SubmitOrderResult("0001-01-01", "0", "Não enviada", 0);
		}
	}

	static CheckErrors(order: Order, exchange: string) {
		let errors: Array<string> = [];
		if (!order.Market) {
			errors.push("Ativo não identificado.");
		}
		if (!order.Fund) {
			errors.push("Fundo não identificado.");
		}
		if (order.Market && order.Fund && order.Fund.Exchange.toLowerCase().trim() !== exchange.toLowerCase().trim()) {
			errors.push(`Exchange não confere com ${exchange}`);
		}
		if (order.Amount <= 0) {
			errors.push("Quantidade deve ser maior que zero.");
		}
		if (order.Price <= 0) {
			errors.push("Preço deve ser maior que zero.");
		}
		if (errors.length > 0) {
			order.HasError = true;
			let errorsStr = "";
			for (let i = 0; i < errors.length; i++) {
				const error = errors[i];
				errorsStr = errorsStr.concat(` ${i + 1}) ${error}`);
			}
			order.ErrorMessage = errorsStr;
		}
	}

	static validateOrders(
		availableFunds: Array<Fund>,
		ordersString: string,
		rawOrdersSep: string,
		rawOrdersFieldsSep: string,
		markets: Array<Market>,
		precision: number = 4
	): Array<Order> {
		/*
		orderFields[0] = Symbol
		orderFields[1] = Amount
		orderFields[2] = Price
		orderFields[3] = Fund name
		orderFields[4] = Market Type (SPOT, FUTURE)
		orderFields[5] = Side (Long, Short)
		orderFields[6] = Type (stop, market)
		*/

		const rawOrders = ordersString.split(rawOrdersSep).filter((val: string) => val !== "");

		let detectedOrders: Array<Order> = [];
		for (let i = 0; i < rawOrders.length; i++) {
			const rawOrder = rawOrders[i];
			const orderFields = rawOrder.split(rawOrdersFieldsSep);
			const fund = availableFunds.filter((x: Fund) => x.Name.trim().toLowerCase() === orderFields[3].trim().toLowerCase())[0];

			const order = new Order(
				i,
				fund ? fund.Exchange : "Não encontrada",
				markets.filter((mkt: Market) => mkt.Ticker === orderFields[0])[0],
				orderFields[5],
				orderFields[6],
				parseFloat(parseFloat(orderFields[1]).toFixed(fund ? fund.Precision : 0)),
				parseFloat(parseFloat(orderFields[2]).toFixed(fund ? fund.Precision : 0)),
				fund,
				null,
				orderFields[4]
			);
			OrderController.CheckErrors(order, fund ? fund.Exchange : "Nao encontrado");
			detectedOrders.push(order);
		}
		detectedOrders = from(detectedOrders)
			.orderByDescending((x) => x.Price)
			.toArray();

		return detectedOrders;
	}
}
