import API from "../../api/ApiManager";
import Position from "../../models/Position/Position";

export default class PositionController {
	static async Get(fund_id: number): Promise<Array<Position>> {
		let response: Array<Position> = [];

		const api = new API(`position/open`);
		const res = await api.postAsync<Array<Position>>(fund_id);
		if (res) {
			response = res;
		}
		return response;
	}
}
