import { Tbody, Td, Tr, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { useContextMenu } from "react-contexify";
import { useBasket } from "../../../../hooks/Basket/useBasket";
import { useStudy } from "../../../../hooks/useStudy";
import { Basket } from "../../../../models/Basket";
import { Study } from "../../../../models/Study";
import { RankingTableRowContextMenu, MENU_ID } from "./RankingTableRowContextMenu";

interface Props {
    showPositions: boolean;
}


export function RankingTableRows({ showPositions }: Props) {
    const { Baskets, Studies, DecimalPrecision } = useBasket();
    const [selectedBasket, setSelectedBasket] = useState<Basket>(new Basket());
    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const { studiesThatUsePercentual } = useStudy();

    function FindResultValueInBasket(study: Study, basket: Basket): number {
        let result = basket.Results.find(r => r.Study.Surname === study.Surname);
        return result !== undefined ? result.Result : 0;
    }

    function FindPositionValueInBasket(study: Study, basket: Basket): number {
        let result = basket.Results.find(r => r.Study.Surname === study.Surname);
        return result !== undefined ? result.Position : 0;
    }

    const formatExibitionResult = (result: number, criteria: string): string => {
        const fixedResult: string = new Intl.NumberFormat('en-US', { maximumFractionDigits: DecimalPrecision }).format(result)
        if (studiesThatUsePercentual.includes(criteria)) return `${fixedResult}%`;
        return `${fixedResult}`
    }
    const cellTable = (study: Study, basket: Basket) => {
        if (!showPositions)
            return (
                <Td textAlign="right" p={'.3rem'} isNumeric title={study.Message}>
                    {formatExibitionResult(FindResultValueInBasket(study, basket), study.Criteria)}
                </Td>
            )
        else
            return (
                <Td textAlign="right" p={'.3rem'} isNumeric title={study.Message}>
                    {FindPositionValueInBasket(study, basket)}
                </Td>)
    }

    const getStudy = (studyBase: Study, basket: Basket): Study => {
        const foundStudy: Study | undefined = basket.Results.find(x => x.Study.ID === studyBase.ID)?.Study;
        return foundStudy === undefined ? studyBase : foundStudy;
    }

    function displayMenu(e: any, basket: Basket) {
        e.preventDefault();
        setSelectedBasket(basket)
        show({ id: MENU_ID, event: e });
    }

    return (
        <>
            <RankingTableRowContextMenu basket={selectedBasket} />
            <Tbody>
                {!!Baskets !== undefined && Baskets.length > 0 && Baskets.map((basket, i) => (
                    <Tr key={i} onContextMenu={(e) => displayMenu(e, basket)}>
                        <Td p='.3rem'>{basket.Codes.sort((a, b) => (a.SlotID - b.SlotID)).map(x => x.Name).join(", ")}</Td>
                        <Td textAlign="right" p='.3rem'>{i + 1}</Td>
                        {!!Studies && Studies !== undefined && Studies.length > 0 &&
                            Studies.map((study, j) => (
                                cellTable(getStudy(study, basket), basket)
                            ))}
                    </Tr>
                ))}
            </Tbody>
        </>

    )
}