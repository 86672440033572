import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown } from "react-bootstrap";
import UserController from "../../controllers/v1/UserController";
import "../../sass/components/_header.scss"
import { Link } from "react-router-dom";

function Logout() {
	UserController.Logout();
}

export default function MainHeader() {
	return (
		<>
			<Navbar bg="dark" variant="dark" expand="sm" className="header">
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
					<Nav className="mr-auto">
						<NavDropdown title="Mercados" id="basic-nav-dropdown">
							<NavDropdown.Item as={Link} to="/markets">
								Listar mercados
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/marketdata">
								Baixar datas
							</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="Codes" id="basic-nav-dropdown">
							<NavDropdown.Item as={Link} to="/upload">
								Upload de backtests
							</NavDropdown.Item>
							<NavDropdown.Divider />
							<NavDropdown.Item as={Link} to="/ranking/baskets">
								Ranking
							</NavDropdown.Item>
						</NavDropdown>
						<NavDropdown title="Fundos" id="basic-nav-dropdown">
							<NavDropdown.Item as={Link} to="/funds">
								Listar fundos
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/funds/history">
								Histórico de cotas
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/submitOrder">
								Enviar ordens
							</NavDropdown.Item>
							<NavDropdown.Item as={Link} to="/positions">
								Consultar posições
							</NavDropdown.Item>
						</NavDropdown>
					</Nav>
					<Nav>
						<Nav.Link className="float-right" onClick={Logout}>
							Logout
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</>

	);
}
