import React from "react";
import { Market } from "../../../models/Market";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, number } from 'yup';
import { useForm } from "react-hook-form";
import { VStack, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Center } from "@chakra-ui/react";
import { Input } from "../../Input";
import { SubmitHandler } from "react-hook-form";
import { useMarket } from "../../../hooks/useMarket";

interface IProps {
	show: boolean;
	onHide: () => void;
}


let schema = object().shape({
	Prefix: string().notRequired(),
	Ticker: string().required(),
	Exchange: string().required(),
	ContractType: string().required("Contract type is a required field"),
	TransactionCost: object().shape({
		Commission: number().required("Commission is a required field").typeError("Must be a number"),
		Slippage: number().required("Slippage is a required field").typeError("Must be a number")
	})
}).required();

export function CreateMarketModal({ show, onHide }: IProps) {
	const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<Market>({
		resolver: yupResolver(schema)
	})

	const { saveMarket } = useMarket();
	const submit: SubmitHandler<Market> = async (data: Market) => {
		const updated = data;
		updated.TransactionCost.Slippage = data.TransactionCost.Slippage / 100;
		updated.TransactionCost.Commission = data.TransactionCost.Commission / 100;
		saveMarket(updated);
		onHide();
	}

	return (
		<>
			<Modal isOpen={show} onClose={onHide}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Criar mercado</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Center as='form' onSubmit={handleSubmit(submit)}>
							<VStack>
								<Input label="Prefixo" error={errors.Prefix} {...register("Prefix")} />
								<Input label="Ticker" error={errors.Ticker} {...register("Ticker")} />
								<Input label="Exchange" error={errors.Exchange} {...register("Exchange")} />
								<Input label="Tipo do contrato" error={errors.ContractType}
									{...register("ContractType")} />
								<Input label="Slip por ordem executada" sufix="%" error={errors.TransactionCost?.Slippage}
									{...register("TransactionCost.Slippage")} />
								<Input label="Comissão por ordem executada" sufix="%" error={errors.TransactionCost?.Commission}
									{...register("TransactionCost.Commission")} />
								<Button type="submit" isLoading={isSubmitting}>Salvar</Button>
							</VStack>
						</Center>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
