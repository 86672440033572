import { Box, Code, VStack, Text } from '@chakra-ui/react';
import React, { ComponentType } from 'react'
import { FaBug } from 'react-icons/fa';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, } from '@chakra-ui/react'

interface IProps {
    FallbackComponent?: ComponentType<{ error: Error }>;
    MessageOnError?: string;
}

interface IState {
    error: Error | null;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
    state: IState = { error: null }

    static getDerivedStateFromError(error: Error | null) {
        return { error }
    }

    render() {
        const { error } = this.state
        if (error) {
            return <Box textAlign={"center"} p="0" m="0" maxH="50%" maxW="50%">
                <VStack>
                    <Text color='red.500'><FaBug size={"2rem"} /></Text>
                    {
                        this.props.FallbackComponent === undefined ?
                            <Text color='red.500' fontWeight="semibold">{this.props.MessageOnError}</Text>
                            : <this.props.FallbackComponent error={error} />
                    }
                    <Accordion allowToggle>
                        <AccordionItem>
                            <AccordionButton w="100%">
                                <Box textAlign='center'>
                                    Visualizar erro
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel>
                                <Code>
                                    {error.stack}
                                </Code>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </VStack>
            </Box>
        }

        return this.props.children
    }
}