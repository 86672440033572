import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import { toast } from 'react-toastify';
import { ApiResponse, LogEventLevel } from "../models/base/ApiResponse";
import { isLocalhost } from "../serviceWorker";
interface API {
	baseURL: string;
	fullURL: string;
	version: string;
	endpoint: string;
	Instance: AxiosInstance;
}

class API implements API {
	version: string;
	Instance: AxiosInstance;

	/**
	 * @param endpoint endpoint ao qual será feita a requisição
	 */
	constructor(endpoint: string) {
		this.endpoint = endpoint.startsWith("/") ? endpoint.substr(1, endpoint.length) : endpoint;
		this.version = "v1";
		this.GenBaseURL();
		this.GenFullURL();
		this.Instance = this.GenAuthApi;
	}

	/** 
	Gera a base da URL, com URL base + versão.
	*/
	GenBaseURL(): void {
		const base =
			isLocalhost ? "http://localhost:8080" :
				"http://177.153.50.17:5000";
		this.baseURL = `${base}/${this.version}`;
	}

	/** 
	Gera a URL completa do destino
	*/
	GenFullURL(): void {
		const fullURL = `${this.baseURL}/${this.endpoint}`;
		this.fullURL = fullURL;
	}

	private get GetToken(): string {
		const bear = `Bearer ${localStorage.getItem("token")}`;
		return bear;
	}

	/**
	Gera a API com todas as predefinições
	*/
	private get GenAuthApi(): AxiosInstance {
		const instance = axios.create({ headers: { Authorization: this.GetToken } });
		return instance;
	}

	private appendConfig(config: AxiosRequestConfig | undefined): AxiosRequestConfig {
		if (config !== undefined && config.headers !== undefined) {
			config.headers.Authorization = this.GetToken;
			return config;
		} else {
			const config: AxiosRequestConfig = {
				responseType: "text",
				headers: {
					"Content-Type": "application/json",
					"Authorization": this.GetToken
				},
			};
			return config;
		}
	}

	private HandleAxiosError(err: AxiosError) {
		if (err.response) {
			let toastMessage = '';
			switch (err.response.status) {
				case 500:
					if (err.response.data) {
						let data = err.response.data as { message: string };
						toastMessage = data.message;
					} else {
						toastMessage = "Ocorreu um erro no servidor. Um diagnóstico enviado ao MK!";
					}

					break;
				case 401 || 403:
					toastMessage = "Acesso não autorizado! Tente fazer login novamente";
					break;
				case 404:
					toastMessage = `Recurso não encontrado - ${err.config.url}`;
					break;
				case 400:
					toastMessage = `${err.response.statusText}`;
					break;
				default:
					//if (err.response.data !== '') toastMessage = err.response.data;
					//else toastMessage = err.response.statusText;
					toastMessage = err.message;
					break;
			}
			toast.error(toastMessage, { toastId: `${err.response.status}` })
		} else {
			toast.error("Ocorreu um erro inesperado. Abra o console para detalhes (F12 -> Console).", {
				toastId: "Erro inesp"
			});
		}
		return null;
	}

	private HandleFormattedApiResponse(res: AxiosResponse): any {
		const apiResponse = res.data as ApiResponse;
		apiResponse.Logs.forEach(log => {
			switch (log.Level) {
				case (LogEventLevel.Error):
					toast.error(log.MessageTemplate.Text);
					break;
				case (LogEventLevel.Warning):
					toast.warning(log.MessageTemplate.Text);
					break;
				default:
					toast.info(log.MessageTemplate.Text);
			}
		});
		return apiResponse.Data;
	}

	private HandleSuccessfullResponse(res: AxiosResponse): any {
		if (res.status === 200) {
			if (res.data === '') return res.data;
			else {
				if ('Logs' in res.data && 'Data' in res.data) {
					return this.HandleFormattedApiResponse(res);
				} else {
					return res.data;
				}
			}

		}
		return null;
	}

	private HandleError(err: any): void {
		Promise.reject(err);
		if (err.isAxiosError) {
			this.HandleAxiosError(err);
		}
	}

	public async postAsync<T>(body: any, config?: AxiosRequestConfig): Promise<T> {
		const headers = this.appendConfig(config);
		return this.Instance
			.post(this.fullURL, body, headers)
			.then((response: any) => this.HandleSuccessfullResponse(response))
			.catch((err: any) => this.HandleError(err))
	}

	public async getAsync<T>(config?: AxiosRequestConfig): Promise<T> {
		const headers = this.appendConfig(config);
		return await this.Instance.get(this.fullURL, headers)
			.then((res: any) => this.HandleSuccessfullResponse(res))
			.catch((err: any) => this.HandleError(err))
	}

	public async putAsync(data: any, config?: AxiosRequestConfig): Promise<void> {
		const headers = this.appendConfig(config);
		return await this.Instance.put(this.fullURL, data, headers)
			.then((response: any) => this.HandleSuccessfullResponse(response))
			.catch((err: any) => this.HandleError(err))
	}

	public async deleteAsync(config?: AxiosRequestConfig): Promise<void> {
		const headers = this.appendConfig(config);
		return await this.Instance.delete(this.fullURL, headers)
			.then((response: any) => this.HandleSuccessfullResponse(response))
			.catch((err: any) => this.HandleError(err))
	}
}

export default API;
