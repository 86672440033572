const haveToken = () => {
	const token = localStorage.getItem("token");
	if (typeof (token) === "string") {
		return true;
	}
	else {
		return false;
	}
}

export default haveToken();