import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { Fund } from "../../../models/Strategy/Fund";
import { OptionTypeBase } from "react-select/src/types";
import CandleControler from "../../../controllers/v1/CandleController";
import FundController from "../../../controllers/v1/FundController";
import AppContext from "../../../context";
import { Table } from "react-bootstrap";
import { Market } from "../../../models/Market";
import MarketController from "../../../controllers/v1/MarketController";
import { toast } from "react-toastify";

interface IProps {
	Funds: Array<Fund>;
	show: boolean;
	onHide: () => void;
}

interface IState {
	exchanges: Array<string>;
	markets: Array<Market>;
	maskedExchanges: Array<OptionTypeBase>;
	maskedMarkets: Array<OptionTypeBase>;
	isUpdating: boolean;
}

export default class UpdateFund extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			exchanges: [],
			markets: [],
			maskedExchanges: [],
			maskedMarkets: [],
			isUpdating: false,
		};
	}

	static contextType = AppContext;

	componentDidMount() {
		this.loadExchanges();
		this.loadMarkets();
	}

	applyMaskToExchanges() {
		let masked = this.state.maskedExchanges;
		this.state.exchanges.map((exchange: String) => masked.push({ label: exchange, value: exchange }));
		this.setState({ maskedExchanges: masked });
	}

	loadExchanges = async () => {
		let exchanges = await CandleControler.GetAvailableExchanges();
		this.setState({ exchanges: exchanges });
		this.applyMaskToExchanges();
	};

	loadMarkets = async () => {
		let markets = await MarketController.List();
		this.setState({ markets: markets });
		this.applyMaskToMarkets();
	};

	applyMaskToMarkets() {
		let masked = this.state.maskedMarkets;
		this.state.markets.map((market: Market) => masked.push({ label: `${market.Prefix}${market.Ticker}`, value: market.ID }));
		this.setState({ maskedMarkets: masked });
	}

	updateFunds = () => {
		for (const i in this.props.Funds) {
			if (Object.prototype.hasOwnProperty.call(this.props.Funds, i)) {
				const Fund = this.props.Funds[i];
				toast.promise(FundController.Update(Fund), {
					pending: "Atualizando fundo",
					error: "Ocorreu um erro 😡"
				});
			}
		}
		this.props.onHide();
	};

	handleChange = (property: string, value: string | number, Fund: Fund) => {
		for (const i in this.props.Funds) {
			if (Object.prototype.hasOwnProperty.call(this.props.Funds, i)) {
				const mkt = this.props.Funds[i];
				if (mkt.ID === Fund.ID) {
					switch (property.toLowerCase()) {
						case "name":
							mkt.Name = value.toString();
							break;
						case "precision":
							mkt.Precision = parseFloat(value.toString());
							break;
						default:
							break;
					}
				}
			}
		}
	};

	handleSelect = (property: string, action: string, value: any, Fund: Fund) => {

		if (action === "select-option" || action === "remove-value") {
			for (const i in this.props.Funds) {
				if (Object.prototype.hasOwnProperty.call(this.props.Funds, i)) {
					const fund = this.props.Funds[i];
					if (fund.ID === Fund.ID) {
						switch (property.toLowerCase()) {
							case "exchange":
								fund.Exchange = value.toString();
								break;
							case "markets":
								fund.Markets = [];
								for (const z in value) {
									if (Object.prototype.hasOwnProperty.call(value, z)) {
										const market = value[z];
										const selected = this.state.markets.filter((x: Market) => x.ID == market.value)[0];
										fund.Markets.push(selected);
									}
								}
								break;
							default:
								break;
						}
					}
				}
			}
		}
	};

	render() {
		return (
			<>
				<Modal size="xl" show={this.props.show} onHide={this.props.onHide} centered>
					<Modal.Header closeButton>
						<h5>Editar fundos</h5>
					</Modal.Header>
					<Modal.Body style={{ paddingBottom: "2rem" }}>
						<Table className="table-condensed table-bordered" responsive hover striped size="sm">
							<thead>
								<tr>
									<th>Nome</th>
									<th>Exchange</th>
									<th className="text-center">Precisão</th>
									<th>Mercados</th>
								</tr>
							</thead>
							<tbody>
								{this.props.Funds.map((Fund: Fund) => (
									<tr key={Fund.ID}>
										<td>
											<Form.Control
												name="name"
												placeholder="Nome"
												onChange={(x) => this.handleChange("name", x.target.value, Fund)}
												defaultValue={Fund.Name}></Form.Control>
										</td>
										<td>
											<Select
												placeholder="Exchange"
												options={this.state.maskedExchanges}
												defaultValue={this.state.maskedExchanges.filter((x) => x.value == Fund.Exchange)[0]}
												onChange={(x: any, y: any) => this.handleSelect("exchange", y.action, x.value, Fund)}></Select>
										</td>
										<td className="text-center">
											<Form.Control
												name="precision"
												placeholder="Precisão"
												type="number"
												step={0.0001}
												onChange={(x) => this.handleChange("precision", x.target.value, Fund)}
												defaultValue={Fund.Precision}></Form.Control>
										</td>
										<td>
											<Select
												placeholder="Mercados"
												isMulti={true}
												options={this.state.maskedMarkets}
												defaultValue={this.state.maskedMarkets.filter((y) => (Fund.Markets.filter((x) => x.ID == y.value).length > 0))}
												onChange={(x: any, y: any) => this.handleSelect("markets", y.action, x, Fund)}></Select>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Modal.Body>
					<Modal.Footer>
						<Button id="add-Fund" onClick={this.updateFunds}>
							Salvar edições
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}
