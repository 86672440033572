export default class Combination {
    Min: number;
    Max: number;
    Step: number;

    constructor(min: number, max: number, step: number) {
        this.Min = min;
        this.Max = max;
        this.Step = step;
    }
}