import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./views/v1/Home/Home";
import Upload from "./views/upload";
import FundHome from "./views/v1/Fund/FundHome";
import haveToken from "./Auth";
import Login from "./views/v1/Auth/Login";
import AuthHome from "./views/v1/Home/AuthHome";
import { Container } from "react-bootstrap";
import MainHeader from "./components/header/header";
import OrderView from "./views/v1/OrderView";
import PositionView from "./views/v1/Position/PositionView";
import FundHistory from "./views/v1/Fund/FundHistory";
import { MarketData } from "./views/v2/Market/MarketData/index";
import { MarketManagement } from "./views/v2/Market";
import { BasketRanking } from "./views/v2/Ranking";


const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			haveToken ? (
				<Container fluid={true} style={{ padding: 0 }}>
					<MainHeader />
					<Container fluid={true}>
						<Component {...props} />
					</Container>
				</Container>
			) : (
				<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
			)
		}
	/>
);

const Routes = () => (
	<BrowserRouter basename="/">
		<Switch>
			<Route exact path="/" component={Home} />
			<Route exact path="/login" component={Login} />
			{/** Rotas privadas **/}
			<PrivateRoute exact path="/authHome" component={AuthHome} />
			<PrivateRoute exact path="/upload" component={Upload} />
			<PrivateRoute exact path="/ranking/baskets" component={BasketRanking} />
			<PrivateRoute exact path="/markets" component={MarketManagement} />
			<PrivateRoute exact path="/funds" component={FundHome} />
			<PrivateRoute exact path="/submitOrder" component={OrderView} />
			<PrivateRoute exact path="/positions" component={PositionView} />
			<PrivateRoute exact path="/marketdata" component={MarketData} />
			<PrivateRoute exact path="/funds/history" component={FundHistory} />
		</Switch>
	</BrowserRouter>
);

export default Routes;
