import API from "../../api/ApiManager";
import { Fund } from "../../models/Strategy/Fund";
import { HistoricalSerie } from "../../models/HistoricalSerie";

export default class HistoricalSerieController {
	static async Get(fund: Fund): Promise<Array<HistoricalSerie>> {
		let response: Array<HistoricalSerie> = [];

		const api = new API(`historicalserie`);
		const res = await api.postAsync<Array<HistoricalSerie>>(fund);
		if (res) {
			response = res;
		}
		return response;
	}

	static async SaveFund(series: Array<HistoricalSerie>): Promise<string> {
		let response: string = "";

		const api = new API(`historicalserie/savefund`);
		const res = await api.postAsync<string>(series);
		if (res) {
			response = res;
		}
		return response;
	}

	static async Update(serie: HistoricalSerie): Promise<string> {
		let response: string = "";

		const api = new API(`historicalserie/update`);
		const res = await api.postAsync<string>(serie);
		if (res) {
			response = res;
		}
		return response;
	}

	static async Delete(serie: HistoricalSerie): Promise<string> {
		let response: string = "";

		const api = new API(`historicalserie/delete`);
		const res = await api.postAsync<string>(serie);
		if (res) {
			response = res;
		}
		return response;
	}
}
