import React, { useState, useEffect } from "react";
import { Code } from "../../../models/Strategy/Code";
import { FaTrash } from "react-icons/fa";
import { AddCodeToSlot } from "../Code/AddCodeToSlot";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { Box, Heading, HStack, IconButton, VStack, Table, Thead, Tr, Th, Td, Tbody, ButtonGroup } from "@chakra-ui/react";
import { CodeResumePopover } from "../Code/CodeResumePopover";

interface Props {
	id: number;
	deleteSlot: (id: number) => void;
}

export function DisplaySlot({ id, deleteSlot }: Props) {
	const { SelectedCodes, UpdateSelectedCodes, RemoveSelectedCode } = useBasket();

	return (
		<Box p='0' borderStyle='solid' borderWidth='1px' borderColor='blue.900' borderRadius='5px'>
			<VStack spacing="2">
				<HStack w="full" spacing="auto" p='.4rem' bgColor={'blue.900'} color='white'>
					<Heading size='sm'>Slot {id}</Heading>
					<IconButton size="xs" icon={<FaTrash />}
						aria-label="Remover slot" title="Remover este slot"
						onClick={() => deleteSlot(id)} />
				</HStack >
				<AddCodeToSlot slotId={id} />
				<Box overflowY="auto" w='100%' height='230px' maxHeight="230px" pl='.6rem' pr='.6rem'>
					<Table size="sm">
						<Thead>
							<Tr>
								<Th colSpan={2} textAlign='center' p="0.3">{SelectedCodes.filter(x => x.SlotID === id).length} codes</Th>
							</Tr>
						</Thead>
						<Tbody>
							{SelectedCodes.filter(x => x.SlotID === id).map((code: Code) => (
								<Tr key={code.ID}>
									<Td p="0.1">{code.Name}</Td>
									<Td textAlign={"right"} p="0.1">
										<ButtonGroup spacing={1}>
											<CodeResumePopover code={code} />
											<IconButton size="xs" aria-label="Remover" icon={<FaTrash />}
												onClick={() => RemoveSelectedCode(code)} title="Remover do slot"
											/>
										</ButtonGroup>
									</Td>
								</Tr>
							))}
						</Tbody>
					</Table>
				</Box>
			</VStack>
		</Box >
	);

}
