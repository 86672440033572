import React, { useEffect } from "react";
import { Heading, useDisclosure, MenuItem, RadioGroup, Radio, Text, VStack, Box, HStack } from "@chakra-ui/react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { useBasket } from "../../../hooks/Basket/useBasket";
import { CombinationMode, DefaultMode, OptimizationMode } from "../../../models/IOptimization";
import { CustomNumberInput } from "../../NumberInput";
import { CountPossibleCombinationsTag } from "./CombinationsCalc";

export function CombinationTypeSelectorModal() {
	const { isOpen, onClose, onToggle } = useDisclosure();
	const [selectedMode, setSelectedMode] = React.useState('default')
	const [fieldsVisible, setFieldsVisibility] = React.useState(false);
	const [min, setMin] = React.useState(1);
	const [max, setMax] = React.useState(3);
	const [step, setStep] = React.useState(1);

	const { ChangeCombinationMode, Slots } = useBasket();

	useEffect(() => {
		selectedMode === 'optimization' ? setFieldsVisibility(true) : setFieldsVisibility(false);
		let mode: OptimizationMode = { Codes: [], ModeName: selectedMode };
		switch (mode.ModeName) {

			case 'optimization':
				let optMode = mode as CombinationMode;
				optMode.Max = max;
				optMode.Min = min;
				optMode.Step = step;
				ChangeCombinationMode(optMode);
				break;
			case 'default':
				let defMode = mode as DefaultMode;
				defMode.slotsCount = Slots.length;
				ChangeCombinationMode(defMode);
				break;
		}


	}, [selectedMode, max, min, step])

	var description: { [mode: string]: string } = {
		'default': 'O algoritmo gera apenas combinações limitadas a 1 code por slot configurado.',
		'optimization': 'O algoritmo gera diversas combinações sem repetição, delimitadas pelos valores abaixo.'
	}

	return (
		<>
			<MenuItem onClick={onToggle}>Combinações e geração de baskets</MenuItem>
			<Modal size="3xl" isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<Heading size="md">Modo de operação do algoritmo de combinação e geração de baskets</Heading>
						<ModalCloseButton />
					</ModalHeader>
					<ModalBody>
						<VStack spacing="8">
							<RadioGroup onChange={setSelectedMode} value={selectedMode}>
								<VStack>
									<Radio value='default'>Padrão</Radio>
									<Radio value='optimization'>Otimização por combinação</Radio>
								</VStack>
							</RadioGroup>
							<Text><Text fontWeight='semibold'>Descrição</Text>{description[selectedMode]}</Text>

							<Box>
								<Text fontWeight='bold'>Codes por basket</Text>
								<HStack>
									<CustomNumberInput isDisabled={!fieldsVisible} name="min" label="Mínimo" min={1} max={max} step={step}
										value={min} onChange={(value) => setMin(parseInt(value))} />
									<CustomNumberInput isDisabled={!fieldsVisible} name="max" label="Máximo" min={min} step={step}
										value={max} onChange={(value) => setMax(parseInt(value))} />
									<CustomNumberInput isDisabled={!fieldsVisible} name="step" label="Step" min={1} max={max} step={1}
										value={step} onChange={(value) => setStep(parseInt(value))} />
								</HStack>
							</Box>
						</VStack>
					</ModalBody>
					<ModalFooter>
						<CountPossibleCombinationsTag />
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>

	)
}
