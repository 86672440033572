import { Box, ButtonGroup, HStack, VStack } from "@chakra-ui/react";
import React from "react";
import { BasketProvider } from "../../../hooks/Basket/useBasket";
import { CodeProvider } from "../../../hooks/useCode";
import { StudyProvider } from "../../../hooks/useStudy";
import { SlotManagement } from "../../../components/v1/Slot/SlotManagement";
import { MarketProvider } from "../../../hooks/useMarket";
import { StudyManagement } from "../../../components/v1/Study/StudyManagement";
import { RankingPresetProvider } from "../../../hooks/useRankingPreset";
import { RankingPresetManagement } from "../../../components/v1/RankingPreset/RankingPresetManagement";
import { SyncButton } from "./SyncButton";
import { RankingTable } from "../../../components/basket/Ranking/Table/RankingTable";
import { BasketManagement } from "../../../components/basket/Ranking/BasketManagement";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { RankingTableError } from "../../../components/basket/Ranking/Table/RankingTableError";
import { ExportRankingModal } from "../../../components/v1/Ranking/IO/ExportRankingModal";
import { ImportRankingModal } from "../../../components/v1/Ranking/IO/ImportRankingModal";


export function BasketRanking() {

    return (
        <CodeProvider>
            <ErrorBoundary MessageOnError="Ocorreu um erro ao manipular as os critérios">
                <StudyProvider>
                    <ErrorBoundary MessageOnError="Ocorreu um erro ao manipular as baskets">
                        <BasketProvider>
                            <HStack justify="space-between">
                                <ErrorBoundary MessageOnError="Ocorreu um erro ao gerenciar os presets">
                                    <RankingPresetProvider>
                                        <RankingPresetManagement />
                                        <HStack>
                                            <SyncButton />

                                            <ImportRankingModal />
                                            <ExportRankingModal />
                                        </HStack>
                                    </RankingPresetProvider>
                                </ErrorBoundary>
                            </HStack>
                            <ErrorBoundary MessageOnError="Ocorreu um erro ao gerenciar os slots">
                                <MarketProvider>
                                    <SlotManagement />
                                </MarketProvider>
                            </ErrorBoundary>
                            <StudyManagement />
                            <VStack mt="8" spacing="2">
                                <BasketManagement />
                                <Box pb='2.5%'>
                                    <ErrorBoundary FallbackComponent={RankingTableError}>
                                        <RankingTable />
                                    </ErrorBoundary>
                                </Box>
                            </VStack>
                        </BasketProvider>
                    </ErrorBoundary>
                </StudyProvider>
            </ErrorBoundary>
        </CodeProvider >
    );
}