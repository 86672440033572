import { Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import React from "react"
import { SelectRankingPreset } from "./SelectRankingPreset";
import { SaveRankingPresetModal } from "./SaveRankingPresetModal";
import { FaChevronDown, FaCog } from "react-icons/fa";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { DeleteRankingPresetPopover } from "./DeleteRankingPresetPopover";

export function RankingPresetManagement() {
    const { SelectedPreset } = useBasket();

    return (
        <Box>
            <HStack width={"50vw"}>
                <SelectRankingPreset />
                <SaveRankingPresetModal />
                <Menu>
                    <MenuButton as={Button} size="sm" rightIcon={<FaChevronDown />} title="Mais configurações de preset">
                        <FaCog />
                    </MenuButton>
                    <MenuList>
                        <MenuItem>Atualizar preset selecionado</MenuItem>
                        <DeleteRankingPresetPopover preset={SelectedPreset} />
                    </MenuList>
                </Menu>
            </HStack>
        </Box>
    )
}