import { Fund } from "../Strategy/Fund";
import { Market } from "../Market";

export default class Order {
	public ID: number;
	public Exchange: string;
	public Market: Market;
	public Side: string;
	public Type: string;
	public Amount: number;
	public Price: number;
	public AutoCancelAt: string | null;
	public Status: string;
	public HasError: boolean;
	public ErrorMessage: string;
	public Fund: Fund;
	public MarketType: string;

	public constructor(
		id: number,
		exchange: string,
		market: Market,
		side: string,
		type: string,
		amount: number,
		price: number,
		fund: Fund,
		autoCancelAt: string | null = null,
		marketType = "",
		status: string = "nao enviada",
		error: boolean = false,
		errorMessage: string = ""
	) {
		this.ID = id;
		this.Exchange = exchange;
		this.Market = market;
		this.Side = side;
		this.Type = type;
		this.Amount = amount;
		this.Price = price;
		this.AutoCancelAt = autoCancelAt;
		this.Status = status;
		this.HasError = error;
		this.ErrorMessage = errorMessage;
		this.Fund = fund;
		this.MarketType = marketType;
	}
}
