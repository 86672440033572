// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    styles: {
        baseStyle: { fontSize: '13px' },
        fonts: {
            body: "system-ui, sans-serif",
            heading: "Georgia, serif",
            mono: "Menlo, monospace"
        },
        global: {
            html: {
                bg: 'gray.50',
                color: 'blue.800'
            }
        },
    },
    components: {
        Button: {
            baseStyle: {
                bg: 'blue.900',
                color: 'blue.50'
            }
            , defaultProps: {
                variant: 'base'
            }
        },
        Table: {
            baseStyle: {
                tr: {
                    borderColor: 'gray.300',
                    borderStyle: "solid",
                    borderWidth: '1px'
                },
                th: {
                    borderColor: 'gray.300',
                    borderStyle: "solid",
                    borderWidth: '1px',
                    backgroundColor: 'blue.900',
                    color: 'gray.100'
                },
                td: {
                    borderColor: 'gray.300',
                    borderStyle: "solid",
                    borderWidth: '1px'
                }
            }, defaultProps: {
                variant: 'base'
            }
        }
    }
})

export default theme