import { Market } from "../../../../../models/Market";
import { Tr, Td } from '@chakra-ui/react'
import { useBasket } from "../../../../../hooks/Basket/useBasket";
import { useEffect, useState } from "react";
import { Input } from "../../../../Input";

interface IProps {
    market: Market;
}

export function MarketManagementTableRow({ market }: IProps) {
    const { MoneyManagementConfig: mm, UpdateMarketOfSelectedCodes } = useBasket();
    const [expo, setExpo] = useState<number>(market.Exposition);
    const [slip, setSlip] = useState<number>(market.TransactionCost.Slippage)
    const [commission, setCommission] = useState<number>(market.TransactionCost.Commission)

    useEffect(() => {
        market.Exposition = expo;
        market.TransactionCost.Slippage = slip;
        market.TransactionCost.Commission = commission;
        UpdateMarketOfSelectedCodes(market)
    }, [expo, slip, commission])

    return (
        <Tr>
            <Td>{market.Ticker}</Td>
            <Td>
                <Input name="expo" value={(expo * 100)} min={0} defaultValue={0} type="number" onChange={(e) => setExpo(e.target.valueAsNumber / 100)} size="xs" />
            </Td>
            <Td>{(mm.InitialNetWorth * mm.Leverage * expo).toLocaleString("en-US", { style: "currency", currency: "USD" })}</Td>
            <Td>{market.BigPointValue.toLocaleString("en-US", { style: "currency", currency: "USD" })}</Td>
            <Td>{market.MinContractsHeld}</Td>
            <Td>
                <Input name="expo" value={(slip * 100)} min={0} defaultValue={0} type="number" step={0.000001} onChange={(e) => setSlip(e.target.valueAsNumber / 100)} size="xs" />
            </Td>
            <Td>
                <Input name="expo" value={(commission * 100)} min={0} defaultValue={0} type="number" step={0.000001} onChange={(e) => setCommission(e.target.valueAsNumber / 100)} size="xs" />
            </Td>
        </Tr>
    )
}