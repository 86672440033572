import { APIKey } from "../APIKey";
import { Market } from "../Market";
import { Backtest } from "./Backtest/Backtest";
import { Strategy } from "./Strategy";

export class Fund extends Strategy {
	ID: number;
	Name: string;
	Exchange: string;
	Precision: number;
	Key: APIKey;
	Markets: Array<Market>;

	constructor(name: string = "", exchange: string = "", precision: number = 0, key: APIKey = new APIKey(), markets: Array<Market> = []) {
		super(name, 0, new Backtest(), []);
		this.ID = 0;
		this.Name = name;
		this.Exchange = exchange;
		this.Precision = precision;
		this.Key = key;
		this.Markets = markets;
	}
}