import { TransactionCost } from "./TransactionCost";

class Market {
	public ID: number;
	public Prefix: string;
	public Ticker: string;
	public Exchange: string;
	public ContractType: string;
	public TransactionCost: TransactionCost;
	public Exposition: number;
	public MinContractsHeld: number;
	public BigPointValue: number;

	constructor(ticker: string);
	constructor(ticker: string, costs: TransactionCost);
	constructor(ticker: string, costs: TransactionCost, exchange: string);
	constructor(ticker: string, costs: TransactionCost, prefix: string, exchange: string, contractType: string);
	constructor(ticker: string, costs: TransactionCost = new TransactionCost(0, 0), prefix = "", exchange = "", contractType = "", exposition = 1, minContractsHeld = 1, bigPointValue = 1) {
		this.ID = 0;
		this.Prefix = prefix;
		this.Ticker = ticker;
		this.Exchange = exchange;
		this.ContractType = contractType;
		this.TransactionCost = costs;
		this.Exposition = exposition;
		this.MinContractsHeld = minContractsHeld;
		this.BigPointValue = bigPointValue;

	}
}

export { Market }