import { Center, Heading, VStack, Button } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import haveToken from "../../../Auth";
import history from "../../../history";

interface IProps { }

interface IState {
	redirect: boolean;
}

export default class Home extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			redirect: false,
		};

		this.checkIfAuthenticated();
	}

	checkIfAuthenticated = async () => {
		const hvToken = haveToken;
		if (hvToken) {
			history.push("/authHome");
		}
	};

	render() {
		return (
			<Center h='100vh'>
				<VStack>
					<Heading>FF LAB</Heading>
					<Button as={Link} to="/login">
						Login
					</Button>
				</VStack>
			</Center>
		);
	}
}
