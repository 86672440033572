import React from "react";
import { IconButton, VStack, HStack } from "@chakra-ui/react";
import { DisplayStudiesTable } from "./DisplayStudiesTable";
import { useBasket } from "../../../hooks/Basket/useBasket";
import { SelectStudy } from "./SelectStudy";
import { CreateOrUpdateStudyModal } from "./CreateOrUpdateStudyModal";
import { FaTrashAlt } from "react-icons/fa";

export function StudyManagement() {
	const { Studies, ResetStudies } = useBasket();
	return (
		<VStack spacing="1">
			<HStack spacing="1">
				<SelectStudy />
				<CreateOrUpdateStudyModal />
				<IconButton aria-label="Remover todos os critérios" icon={<FaTrashAlt />}
					size="sm" onClick={ResetStudies} />
			</HStack>
			<DisplayStudiesTable studies={Studies} />
		</VStack>
	);
}