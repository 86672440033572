interface MessageTemplate {
    Text: string;
}

enum LogEventLevel {
    Verbose = 0,
    Debug = 1,
    Information = 2,
    Warning = 3,
    Error = 4,
    Fatal = 5
}

interface LogEvent {

    Level: LogEventLevel;
    MessageTemplate: MessageTemplate;
    Timestamp: string;
}

interface ApiResponse {
    Data: any;
    Logs: Array<LogEvent>;
}

export { LogEventLevel }; export type { ApiResponse, LogEvent, MessageTemplate };

