import MarketCandle from "../../models/MarketCandleInfo";
import dayjs from "dayjs";
import API from "../../api/ApiManager";
import { AxiosRequestConfig } from "axios";
import fileDownload from "js-file-download";
import ExportCandleConfig from "../../models/ExportCandleConfig";

export default class CandleControler {
	static async GetTickerCandles(exchange: string, ticker: string): Promise<Array<MarketCandle>> {
		let candles: Array<MarketCandle> = [];
		const api = new API(`/candle/${exchange}/${ticker}`);
		const res = await api.getAsync<MarketCandle[]>();
		if (res) {
			candles = res;
		}
		return candles;
	}

	static async GetExchangeCandles(exchange: string): Promise<Array<MarketCandle>> {
		let candles: Array<MarketCandle> = [];
		const api = new API(`candle/last/${exchange}`);
		const res = await api.getAsync<MarketCandle[]>();
		if (res) {
			candles = res;
		}
		return candles;
	}

	static async GetCandlesWithFilter(prefix: string, exchange: string, contract: string): Promise<Array<MarketCandle>> {
		let candles: Array<MarketCandle> = [];
		const api = new API(`candle/last/${prefix}/${exchange}/${contract}`);
		const res = await api.getAsync<MarketCandle[]>();
		if (res) {
			candles = res;
		}
		return candles;
	}

	public static chunk<T>(arr: Array<T>, chunkSize: number): Array<Array<T>> {
		return arr.reduce(
			(prevVal: any, currVal: any, currIndx: number, array: Array<T>) =>
				!(currIndx % chunkSize) ? prevVal.concat([array.slice(currIndx, currIndx + chunkSize)]) : prevVal,
			[]
		);
	}

	static async ExportCandles(exportConfig: ExportCandleConfig) {
		const config: AxiosRequestConfig = {
			responseType: "arraybuffer",
			headers: {
				"Content-Type": "application/json",
			},
		};
		const api = new API(`candle/export`);
		var fileName = `Datas ${dayjs(new Date()).format("YYYYMMDDHm")}.zip`;
		const res = await api.postAsync<any>(exportConfig, config);

		if (res) {
			fileDownload(res, fileName, "application/zip");
		}
	}

	static async GetAvailableExchanges(): Promise<Array<string>> {
		let exchanges: Array<string> = [];
		const api = new API(`market/exchanges`);
		const res = await api.getAsync<string[]>();
		if (res) {
			exchanges = res;
		}
		return exchanges;
	}
}
