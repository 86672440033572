import { orderBy } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import API from "../api/ApiManager";
import { Market } from "../models/Market";

interface MarketProviderProps {
    children: React.ReactNode;
}

interface MarketContextData {
    prefixes: string[];
    getPrefixes: () => Promise<void>;
    contractsType: string[];
    exchanges: string[];
    markets: Market[];
    updateMarket: (market: Market) => Promise<void>;
    saveMarket: (market: Market) => Promise<void>;
    deleteMarket: (market: Market) => Promise<void>;
}

const MarketContext = createContext<MarketContextData>({} as MarketContextData);

export function MarketProvider(props: MarketProviderProps) {
    const [markets, setMarkets] = useState<Market[]>([]);
    const [prefixes, setPrefixes] = useState<string[]>([]);
    const [contractsType,] = useState<string[]>(["SPOT", "FUTURES"]);
    const [exchanges,] = useState<string[]>(["Binance", "Bitfinex", "B3", "TradeStation"]);

    async function getPrefixes() {
        const api = new API(`market/prefix`);
        api.getAsync<string[]>().then(response => response !== null
            ? setPrefixes(response)
            : []);
    }

    async function getMarkets() {
        const api = new API(`market`);
        api.getAsync<Market[]>().then(response => response !== null ? setMarkets(orderBy(response, ['Ticker'], 'asc')) : []);
    }

    async function updateMarket(market: Market): Promise<void> {
        const api = new API(`market`);
        toast.promise(api.putAsync(market), { success: "Mercado atualizado" });
        let updatedMarkets = markets;
        const id = updatedMarkets.findIndex(x => x.ID === market.ID);
        if (id !== -1) updatedMarkets[id] = market;
        setMarkets(updatedMarkets);
    }

    async function saveMarket(market: Market): Promise<void> {
        const api = new API(`market`);

        toast.promise(api.postAsync<Market>(market).then((response) => {
            console.log(response);
            setMarkets([...markets, response])
        }),
            {
                success: "Mercado salvo!",
                pending: "Salvando mercado"
            });
    }

    async function deleteMarket(market: Market): Promise<void> {
        const api = new API(`market/${market.ID}`);
        toast.promise(api.deleteAsync().then(() => setMarkets(markets.filter(x => x.ID !== market.ID))),
            {
                success: "Mercado excluído!",
                pending: "Excluindo mercado"
            });
    }

    useEffect(() => { getMarkets() }, [])

    return (
        <MarketContext.Provider value={{ prefixes, getPrefixes, contractsType, exchanges, markets, updateMarket, saveMarket, deleteMarket }}>
            {props.children}
        </MarketContext.Provider>
    );
}

export function useMarket() {
    const context = useContext(MarketContext);
    return context;
}