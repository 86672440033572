import { Study } from "../Study";

export default class RankingPreset {
    ID: number;
    Name: string;
    Slots: number;
    Studies: Study[];
    LastUpdate: string | null;

    constructor(id: number, name: string);
    constructor(id: number, name: string, slots: number);
    constructor(id: number, name: string, slots: number, studies: Study[]);

    constructor(id = 0, name = "", slots = 0, studies: Study[] = [], lastUpdate = null) {
        this.ID = id;
        this.Name = name;
        this.Slots = slots;
        this.Studies = studies;
        this.LastUpdate = lastUpdate;
    }
}
