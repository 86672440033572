import React from "react";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import MarketPricesAbstract from "../models/MarketPricesAbstract";
import API from "../api/ApiManager";
import { yesterdayUTC } from "../utils/Date";
import dayjs from "dayjs";
import ExportCandleConfig from "../models/ExportCandleConfig";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { Market } from "../models/Market";
import { filter } from "lodash";

interface MarketCandlesAbstractProviderProps {
    children: ReactNode;
}

interface ExtendedMarketCandlesAbstract extends MarketPricesAbstract {
    isSelected: boolean;
    ID: number;
    isUpdated: boolean;
}
interface MarketCandlesAbstractData {
    extendedAbstracts: ExtendedMarketCandlesAbstract[];
    editAbstract: (id: number, property: string) => Promise<void>;
    checkAllAbstracts: (status: boolean) => Promise<void>;
    getMarketData: (prefix: string, exchange: string, contractType: string) => Promise<void>;
    updateSelected: (mode: string) => Promise<void>;
    exportSelected: (exportFlatBar: boolean, minBars: number) => Promise<void>;
}


const MarketCandlesAbstractContext = createContext<MarketCandlesAbstractData>({} as MarketCandlesAbstractData);

export function MarketCandlesAbstractProvider(props: MarketCandlesAbstractProviderProps) {
    const [abstracts, setAbstracts] = useState<MarketPricesAbstract[]>([]);
    const [extendedAbstracts, setExtendedAbstracts] = useState<ExtendedMarketCandlesAbstract[]>([]);

    // Ao buscar os abstracts, gera uma lista de extended abstracts
    useEffect(() => {
        var updatedExtendedAbstracts: ExtendedMarketCandlesAbstract[] = [];

        abstracts != null && abstracts.map((x, i) => updatedExtendedAbstracts.push({ ...x, isSelected: false, ID: i, isUpdated: new Date(x.Newest) >= yesterdayUTC }));
        setExtendedAbstracts(updatedExtendedAbstracts);
    }, [abstracts]);

    // Busca os resumos
    async function getMarketData(prefix: string, exchange: string, contractType: string) {
        const marketsApi = new API("market");
        var markets = await toast.promise(marketsApi.getAsync<Market[]>(), {
            pending: "Buscando mercados cadastrados"
        })
        var filteredMarkets: Market[] = []
        if (prefix !== "***" || exchange !== "***" || contractType !== "***") {
            filteredMarkets = markets.filter(x =>
                (prefix !== "***" && x.Prefix.trim().toUpperCase() === prefix.trim().toUpperCase()) ||
                (exchange !== "***" && x.Exchange.trim().toUpperCase() === exchange.trim().toUpperCase()) ||
                (contractType !== "***" && x.ContractType.trim().toUpperCase() === contractType.trim().toUpperCase()))
        } else {
            filteredMarkets = markets;
        }

        var marketIds: number[] = []
        filteredMarkets.map(x => marketIds.push(x.ID))
        const concatMktId = marketIds.join(',')
        const abstractsApi = new API(`MarketPricesAbstract/getByMarketId/${concatMktId}`);
        const response = await toast.promise(abstractsApi.getAsync<MarketPricesAbstract[]>(), { pending: "Buscando o resumo de barras" });
        setAbstracts(response)
    }

    // Atualiza um Extended abstract
    async function editAbstract(id: number, property: string) {
        try {
            const updatedExtendedAbstracts = [...extendedAbstracts];
            const abstractExists = updatedExtendedAbstracts.find(x => x.ID === id);
            if (abstractExists) {
                if (property === "isSelected") {
                    abstractExists.isSelected = !abstractExists.isSelected;
                    setExtendedAbstracts(updatedExtendedAbstracts);
                } else {
                    throw Error();
                }
            }
        } catch {

        }

    }

    async function checkAllAbstracts(status: boolean) {
        const updatedExtendedAbstracts = [...extendedAbstracts];
        updatedExtendedAbstracts.map(x => x.isSelected = status);
        setExtendedAbstracts(updatedExtendedAbstracts);
    }

    /**
     * Desfaz a extensão da interface ExtendedMarketCandlesAbstract
     * @param extended interface a ser desestendida
     * @returns MarketCandlesAbstract
     */
    function undoExtend(extended: ExtendedMarketCandlesAbstract): MarketPricesAbstract {
        const { isSelected, isUpdated, ...rest }: ExtendedMarketCandlesAbstract & Partial<MarketPricesAbstract> = extended;
        return rest;
    }

    /**
     * Atualiza os abstracts que isSelected e !isUpdated
     */
    async function updateSelected(mode: string) {
        let selectedAbstracts: MarketPricesAbstract[] = [];
        if (mode == "overwrite") {
            extendedAbstracts.map(extended => (
                extended.isSelected ?
                    selectedAbstracts.push(undoExtend(extended))
                    : null
            ));
        } else {
            extendedAbstracts.map(extended => (
                extended.isSelected && !extended.isUpdated ?
                    selectedAbstracts.push(undoExtend(extended))
                    : null
            ));
        }

        let ids: number[] = [];
        selectedAbstracts.map(x => ids.push(x.Market.ID))
        const api = new API(`candle/update?mode=${mode}`);

        const marketsToUpdate: Market[] = []
        selectedAbstracts.map(x => marketsToUpdate.push(x.Market))

        toast.promise(api.postAsync<MarketPricesAbstract[]>(marketsToUpdate)
            .then(response => {
                if (response) {
                    const updatedAbstracts = [...abstracts];
                    response.map(newAbstract => {
                        let newAbstractIndexAtList = updatedAbstracts.findIndex(x => x.Market.ID === newAbstract.Market.ID);
                        if (newAbstractIndexAtList !== -1) {
                            updatedAbstracts[newAbstractIndexAtList] = newAbstract;
                            setAbstracts(updatedAbstracts);
                        }
                    })
                }
            }), { pending: `Atualizando barras de ${selectedAbstracts.length} mercado(s)` });
    }

    async function exportSelected(exportFlatBar: boolean, minBars: number) {
        let selectedAbstracts: MarketPricesAbstract[] = [];
        extendedAbstracts.map(extended => {
            if (extended.isSelected) {
                selectedAbstracts.push(undoExtend(extended));
            }
        })

        const marketsToUpdate: Market[] = []
        selectedAbstracts.map(x => marketsToUpdate.push(x.Market))

        const exportConfig: ExportCandleConfig = { Markets: marketsToUpdate, MinBars: minBars, ReturnFlatBar: exportFlatBar };

        const api = new API(`candle/export`);
        var fileName = `Datas ${dayjs(new Date()).format("YYYYMMDDHm")}.zip`;
        toast.promise(api.postAsync<any>(exportConfig,
            {
                responseType: "arraybuffer",
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(response => fileDownload(response, fileName, "application/zip")), {
            pending: `Preparando o download de ${selectedAbstracts.length} data(s)`
        });
    }

    return (
        <MarketCandlesAbstractContext.Provider
            value={
                {
                    extendedAbstracts, editAbstract,
                    checkAllAbstracts, getMarketData,
                    updateSelected, exportSelected
                }
            }>
            {props.children}
        </MarketCandlesAbstractContext.Provider>
    )
}

export function useMarketDataAbstract() {
    const context = useContext(MarketCandlesAbstractContext);
    return context;
};