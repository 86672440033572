import { Market } from "../../models/Market";
import API from "../../api/ApiManager";

export default class MarketController {
	static async Create(market: Market): Promise<Market> {
		let returnedMarket: Market = market;

		const api = new API(`market`);
		const res = await api.postAsync<Market>(market);
		if (res) {
			returnedMarket = res;
		}
		return returnedMarket;
	}

	static async CreateMany(markets: Array<Market>): Promise<Array<Market>> {
		let returnedMarket: Array<Market> = [];

		const api = new API(`market/savemany`);
		const res = await api.postAsync<Array<Market>>(markets);
		if (res) {
			returnedMarket = res;
		}
		return returnedMarket;
	}

	static async List(): Promise<Array<Market>> {
		let response: Array<Market> = [];

		const api = new API(`market/list`);
		const res = await api.getAsync<Array<Market>>();
		if (res) {
			response = res;
		}
		return response;
	}

	static async ListPrefix(): Promise<Array<string>> {
		let response: Array<string> = [];

		const api = new API(`market/prefix`);
		const res = await api.getAsync<Array<string>>();
		if (res) {
			response = res;
		}
		return response;
	}

	static async Update(market: Market): Promise<string> {
		let response: string = "";

		const api = new API(`market/update`);
		const res = await api.postAsync<string>(market);
		if (res) {
			response = res;
		}
		return response;
	}

	static async Delete(market: Market): Promise<string> {
		let response: string = "";

		const api = new API(`market/delete`);
		const res = await api.postAsync<string>(market);
		if (res) {
			response = res;
		}
		return response;
	}
}
