import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FormControl, FormErrorMessage, FormLabel, Input as ChakraInput, InputGroup, InputLeftAddon, InputProps as ChakraInputProps, InputRightAddon } from '@chakra-ui/react';
import { FieldError } from "react-hook-form";
import ReactInputMask from 'react-input-mask';

interface InputProps extends ChakraInputProps {
    name: string;
    prefix?: string;
    sufix?: string;
    label?: string;
    error?: FieldError;
    mask?: string;
    maskChar?: string;
    alwaysShowMask?: boolean
}


const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ name, label, prefix, sufix, error, mask, maskChar, alwaysShowMask, ...rest }, ref) => {
    return (
        <FormControl isInvalid={!!error}>
            {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <InputGroup>
                {!!prefix && <InputLeftAddon children={prefix} />}
                <ChakraInput as={ReactInputMask} mask={mask} name={name} maskChar={maskChar} alwaysShowMask={alwaysShowMask} id={name} ref={ref} {...rest} />
                {!!sufix && <InputRightAddon children={sufix} />}
                {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
            </InputGroup>
        </FormControl>
    )
}

export const Input = forwardRef(InputBase);
