import { Table, Thead, Th, Tr, useBoolean, Switch, VStack, Box, useDisclosure, Input, NumberInput } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useState } from "react";
import { useBasket } from "../../../../hooks/Basket/useBasket";
import { EquityCurveDownloader } from "../../../EquityCurve/EquityCurveDownloader";
import { RankingTableRows } from "./RankingTableRows";
import { StudyWeightInput } from "./StudyWeightInput";

export function RankingTable() {
    const { Studies, Baskets } = useBasket();
    const [showPositions, setShowPositions] = useBoolean();
    const eqDownloader = useDisclosure();
    const [eqRangeStart, setEqRangeStart] = useState<Date>(new Date())
    const [eqRangeEnd, setEqRangeEnd] = useState<Date>(new Date())

    const DownloadEquityCurve = (rangeStart: string | null, rangeEnd: string | null) => {
        rangeStart !== null ? setEqRangeStart(dayjs(rangeStart).toDate()) : setEqRangeStart(new Date(1500, 4, 22))
        rangeEnd !== null ? setEqRangeEnd(dayjs(rangeEnd).toDate()) : setEqRangeEnd(new Date())
        eqDownloader.onToggle()
    }

    return (
        <>
            <EquityCurveDownloader start={eqRangeStart} end={eqRangeEnd} isOpen={eqDownloader.isOpen}
                onClose={eqDownloader.onClose} />
            <VStack align={'right'}>
                <Box textAlign='right'>
                    <Switch size='sm' isChecked={showPositions} onChange={setShowPositions.toggle}>
                        Exibir posições nos critérios</Switch>
                </Box>
                <Box overflowY={"auto"} maxH="720px" w='98vw'>
                    <Table size="sm" p='.3rem' >
                        <Thead position="sticky" top={0}>
                            <Tr>
                                <Th colSpan={2} textAlign="center">Combinações geradas - {Baskets.length}</Th>
                                <Th colSpan={Studies.length} textAlign="center">Resultados</Th>
                            </Tr>
                            <Tr>
                                <Th colSpan={2} textAlign="right" p='.3rem'>Pesos</Th>
                                {Studies.map((x, i) => (<Th key={i} p={'.3rem'} textAlign="right">
                                    <StudyWeightInput study={x} />
                                </Th>))}
                            </Tr>
                            <Tr>
                                <Th p='.3rem'>Basket</Th>
                                <Th textAlign="right" p='.3rem'>Posição</Th>
                                {Studies.map((x, i) => (<Th key={i} p={'.3rem'} textAlign="right"
                                    onDoubleClick={() => DownloadEquityCurve(x.Start, x.End)}>{x.Surname}</Th>))}
                            </Tr>
                        </Thead>
                        <RankingTableRows showPositions={showPositions} />
                    </Table>
                </Box>
            </VStack>
        </>
    )
}