import { Badge, Button, HStack, Select, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { ColumnDef, flexRender, getCoreRowModel, getPaginationRowModel, getSortedRowModel, PaginationState, Row, SortingState, useReactTable } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { HTMLProps, useEffect } from "react";
import { Code } from "../../../models/Strategy/Code";
import { Input } from "../../Input";

interface IProps {
    codes: Code[]
    selectedCodesHandler: (codes: Code[]) => void;
}

function IndeterminateCheckbox({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!)

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate
        }
    }, [ref, indeterminate])

    return (
        <input
            type="checkbox"
            ref={ref}
            className={className + ' cursor-pointer'}
            {...rest}
        />
    )
}

function getRowRange<T>(rows: Array<Row<T>>, idA: string, idB: string) {
    const range: Array<Row<T>> = [];
    let foundStart = false;
    let foundEnd = false;
    for (let index = 0; index < rows.length; index += 1) {
        const row = rows[index];
        if (row.id === idA || row.id === idB) {
            if (foundStart) {
                foundEnd = true;
            }
            if (!foundStart) {
                foundStart = true;
            }
        }

        if (foundStart) {
            range.push(row);
        }

        if (foundEnd) {
            break;
        }
    }

    return range;
}

function createSelectColumn<T>(): ColumnDef<T> {
    let lastSelectedId = '';

    return {
        id: 'select',
        header: ({ table }) => (
            <IndeterminateCheckbox
                id="select-all"
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
            />
        ),
        cell: ({ row, table }) => (
            <IndeterminateCheckbox
                id={`select-row-${row.id}`}
                checked={row.getIsSelected()}
                indeterminate={row.getIsSomeSelected()}
                onChange={row.getToggleSelectedHandler()}
                onClick={(e) => {
                    if (e.shiftKey) {
                        const { rows, rowsById } = table.getRowModel();
                        const rowsToToggle = getRowRange(rows, row.id, lastSelectedId);
                        const isLastSelected = rowsById[lastSelectedId].getIsSelected();
                        rowsToToggle.forEach((row) => row.toggleSelected(isLastSelected));
                    }

                    lastSelectedId = row.id;
                }}
            />
        ),
        maxSize: 50,
    };
}

export function DisplayCodeTable({ codes, selectedCodesHandler }: IProps) {
    const [rowSelection, setRowSelection] = React.useState({})
    const [sorting, setSorting] = React.useState<SortingState>([])

    function CodeNameWithNewBadge(code: Code) {
        if (dayjs(code.Backtest.UploadDate) > dayjs().subtract(2, 'day')) {
            return <HStack>
                <Badge colorScheme='green' variant='subtle' size='sm'>new</Badge><Text>{code.Name}</Text>
            </HStack>
        } else {
            return <Text>{code.Name}</Text>
        }
    }

    const columns = React.useMemo<ColumnDef<Code>[]>(
        () => [
            createSelectColumn<Code>(),
            {
                header: 'Informações sobre o code',
                footer: props => props.column.id,
                columns: [
                    {
                        accessorFn: row => row.Name,
                        id: 'Nome',
                        cell: info => CodeNameWithNewBadge(info.row.original),
                        header: () => <span>Nome</span>,
                        footer: props => props.column.id,
                    }
                ],
            },
            {
                header: 'Informações do backtest',
                footer: props => props.column.id,
                columns: [
                    {
                        accessorFn: row => row.Backtest.UploadDate,
                        id: 'Backtest.UploadDate',
                        cell: info => dayjs(info.getValue()).format("YYYY-MM-DD HH:MM"),
                        header: () => <span>Upload</span>,
                        footer: props => props.column.id,
                    },
                    {
                        accessorFn: row => row.Backtest.FirstDate,
                        id: 'Backtest.FirstDate',
                        cell: info => dayjs(info.getValue()).format("YYYY-MM-DD"),
                        header: () => <span>1ª Barra</span>,
                        footer: props => props.column.id,
                    },
                    {
                        accessorFn: row => row.Backtest.LastDate,
                        id: 'Backtest.LastDate',
                        cell: info => dayjs(info.getValue()).format("YYYY-MM-DD"),
                        header: () => <span>Nª Barra</span>,
                        footer: props => props.column.id,
                    }
                ],
            }
        ],
        []
    )

    const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20
    })

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const table = useReactTable({
        data: codes,
        columns,
        pageCount: Math.ceil(codes.length / pageSize) ?? -1,
        state: {
            rowSelection,
            pagination,
            sorting
        },

        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        manualPagination: false,
        getPaginationRowModel: getPaginationRowModel(),
    })

    useEffect(() => {
        let codes: Code[] = []
        table.getSelectedRowModel().flatRows.map(x => codes.push(x.original));
        selectedCodesHandler(codes)
    }, [table.getSelectedRowModel().flatRows])

    return (
        <VStack>
            <HStack minW={'50%'}>
                <HStack spacing={1}>
                    <Button
                        title='Pular para a primeira página'
                        size='xs'
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<<'}
                    </Button>
                    <Button
                        title='Voltar uma página'
                        size='xs'
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<'}
                    </Button>
                    <Button
                        title='Avançar uma página'
                        size='xs'
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>'}
                    </Button>
                    <Button
                        title='Pular para a última página'
                        size='xs'
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        {'>>'}
                    </Button>
                </HStack>
                <Text>
                    {table.getState().pagination.pageIndex + 1}/{table.getPageCount()}
                </Text>
                <HStack>
                    <Input type="number"
                        size='xs'
                        placeholder="Ir para a página"
                        maxW='120'
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            table.setPageIndex(page);
                        }} name={"goto"} />
                </HStack>

                <Select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                    }}
                    size='xs'
                    maxW='100'
                >
                    {[20, 40, 80, 160, 320, codes.length].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Exibir {pageSize}
                        </option>
                    ))}
                </Select>
            </HStack>

            <Table size={'sm'}>
                <Thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                return (
                                    <Th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder ? null : (
                                            <div
                                                {...{
                                                    className: header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : '',
                                                    onDoubleClick: header.column.getToggleSortingHandler(),
                                                }}>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: ' 🔼',
                                                    desc: ' 🔽',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        )}
                                    </Th>
                                )
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    return (
                                        <Td key={cell.id}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </VStack >
    )
}