import { groupBy, orderBy, sum } from "lodash";
import { Basket } from "../../models/Basket";
import { Study } from "../../models/Study";
import StudyResult from "../../models/StudyResult";
import * as _ from 'lodash'

function RankBasketsByAverage(baskets: Basket[], studies: Study[]): Basket[] {
    if (baskets == undefined) return [];
    let orderedBaskets = baskets;
    baskets.map((bk, i) => (bk !== undefined ? bk.ID = i : null))
    baskets.map(bk => bk.Results.map(r => r.ID = bk.ID));
    orderedBaskets = calcBasketPositionPerStudy(orderedBaskets, studies);
    orderedBaskets = generateBasketAverages(orderedBaskets);
    orderedBaskets = orderBaskets(orderedBaskets);
    return orderedBaskets;
}

function calcBasketPositionPerStudy(baskets: Basket[], studies: Study[]): Basket[] {
    if (baskets.length === 0) return []
    let updatedBaskets = baskets;
    const allResults = baskets.flatMap(x => x.Results);
    for (let i = 0; i < studies.length; ++i) {
        const studyResults = allResults.filter(x => x.Study.ID === studies[i].ID);

        const orderedGroups = _.chain(studyResults).groupBy(x => x.Result)
            .map((groupedStudies, result) => ({ groupedStudies, result }))
            .orderBy(group => Number(group.result), ['desc']).value()

        orderedGroups.map((groups, i) => groups.groupedStudies.map(x => x.Position = i + 1));
        let ordered: StudyResult[] = []
        orderedGroups.map((groups, i) => groups.groupedStudies.map(y => ordered.push(y)));
        for (let j = 0; j < baskets.length; ++j) {
            const result = ordered.find(x => x.ID === baskets[j].ID);
            if (result) baskets[j].Results[i] = result;
        }
    }

    return updatedBaskets;
}

function generateBasketAverages(baskets: Array<Basket>): Basket[] {
    if (baskets === null) return baskets;
    const updatedBaskets = baskets;
    updatedBaskets.forEach((basket) => basket.AVGPosition = calcAveragePosition(basket.Results));
    updatedBaskets.forEach((basket) => basket.AVGResult = calcAverageResult(basket.Results));
    return updatedBaskets;
}

function calcAveragePosition(results: StudyResult[]): number {
    var divisor = sum(results.map(x => x.Study.Weight));
    if (divisor === 0) return 0;
    var dividendo = sum(results.map(x => x.Position * x.Study.Weight));
    return dividendo / divisor;
}

function calcAverageResult(results: StudyResult[]): number {
    var divisor = sum(results.map(x => x.Study.Weight));
    if (divisor === 0) return 0;
    var dividendo = sum(results.map(x => x.Result * x.Study.Weight));
    return dividendo / divisor;
}

function orderBaskets(baskets: Array<Basket>): Basket[] {
    const ordered = orderBy(baskets, 'AVGPosition', 'asc')
    ordered.map((basket, pos) => basket.AVGPosition = pos + 1);
    return ordered;
}

export { RankBasketsByAverage as RankBaskets }