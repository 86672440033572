import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../api/ApiManager';
import { Code } from "../models/Strategy/Code"

interface CodeProdiverProps {
    children: React.ReactNode;
}

interface CodeContextData {
    searchCodesByName: (searchMode: string, names: string[]) => Promise<Code[]>;
}

const CodeContext = createContext<CodeContextData>({} as CodeContextData);

export function CodeProvider(props: CodeProdiverProps) {

    async function searchCodesByName(searchMode: string, names: string[]): Promise<Code[]> {
        const api = new API(`code/search?mode=${searchMode}`);
        let codes: Code[] = await api.postAsync<Code[]>(names);
        return codes
    }

    return (
        <CodeContext.Provider value={{ searchCodesByName }}>
            {props.children}
        </CodeContext.Provider>
    )
}

export function useCode() {
    const context = useContext(CodeContext);
    return context;
}