import React from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, number } from 'yup';
import { useForm } from "react-hook-form";
import { VStack, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Center } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { Market } from "../../../models/Market";
import { useMarket } from "../../../hooks/useMarket";
import { Input } from "../../Input";

interface IProps {
	markets: Market[];
	show: boolean;
	onHide: () => void;
}

interface FormData {
	Commission: number;
	Slippage: number;
}

let schema = object().shape({
	Commission: number().required("Commission is a required field").typeError("Must be a number"),
	Slippage: number().required("Slippage is a required field").typeError("Must be a number")
}).required();


export function UpdateTransactionCostModal({ markets, show, onHide }: IProps) {
	const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<FormData>({
		resolver: yupResolver(schema)
	})

	const { updateMarket } = useMarket();
	const submit: SubmitHandler<FormData> = async (data) => {
		markets.forEach(market => {
			let updated = market;
			updated.TransactionCost.Commission = data.Commission;
			updated.TransactionCost.Slippage = data.Slippage;
			updateMarket(market);
		})
		onHide();
	}

	return (
		<>
			<Modal isOpen={show} onClose={onHide}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Alterar custos de transação</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Center as='form' onSubmit={handleSubmit(submit)}>
							<VStack>
								<p>Alterando: {markets.map(x => x.Ticker).join(", ")}</p>
								<Input label="Slip por ordem executada"
									sufix="%" error={errors.Slippage}
									{...register("Slippage")} />

								<Input label="Comissão por ordem executada"
									sufix="%" error={errors.Commission}
									{...register("Commission")} />
								<Button type="submit" isLoading={isSubmitting}>Salvar</Button>
							</VStack>
						</Center>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}
