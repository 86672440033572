import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { useMarketDataAbstract } from "../../../../hooks/useMarketDataAbstract";

interface ConfigureMarketDataExportProps {
    isConfigureMarketDataExportModalOpened: boolean;
    hideModal: () => void;
}

export function ConfigureMarketDataExportModal(props: ConfigureMarketDataExportProps) {
    const [returnFlatBar, setReturnFlatBar] = useState(false);
    const [minBars, setMinBars] = useState(150);
    const { exportSelected } = useMarketDataAbstract();

    return (
        <Modal show={props.isConfigureMarketDataExportModalOpened} onHide={props.hideModal}>
            <Row className="m-3 text-center">
                <Col>
                    <Row>
                        <Col>
                            <h4>Configure a exportação das datas</h4>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form>
                                <Form.Label>Configuração da última barra do gráfico</Form.Label>
                                <Form.Control as="select" onChange={event => setReturnFlatBar(event.target.value === "1")}>
                                    <option value="1">Com barra flat (H, L , C = OPEN)</option>
                                    <option value="0">Sem barra flat</option>
                                </Form.Control>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                        <Form.Group>
							<InputGroup>
								<InputGroup.Prepend>
									<InputGroup.Text>
										Exportar apenas mercados com mais de
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control className="text-center" as="input" type="number" value={minBars} onChange={event => setMinBars(parseInt(event.target.value))} />
								<InputGroup.Append>
									<InputGroup.Text>
										barras
									</InputGroup.Text>
								</InputGroup.Append>
							</InputGroup>
						</Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Button onClick={() => exportSelected(returnFlatBar, minBars) }>Exportar</Button>
                        </Col>
                    </Row>

                </Col>
            </Row>

        </Modal>
    );
}