import React, { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { RankBaskets } from "./RankingAlgorithm";
import { Basket } from "../../models/Basket";
import { Code } from "../../models/Strategy/Code";
import { Study } from "../../models/Study";
import RankingPreset from "../../models/Ranking/RankingPreset";
import API from "../../api/ApiManager";
import { RKGParameters, MmConfig } from "../../models/Ranking/Parameters";
import { DefaultMode, OptimizationMode, CombinationMode } from "../../models/IOptimization";
import { cloneDeep, Dictionary, groupBy, uniq, uniqBy } from "lodash";
import Combination from "../../models/Strategy/Optimization/Combination";
import { Combination as CombinationAlgo } from "js-combinatorics";
import OptimizationParameters from "../../models/Strategy/Optimization/OptimizationParameters";
import { Market } from "../../models/Market";

interface BasketProviderProps {
    children: React.ReactNode;
}

interface BasketContextData {
    Baskets: Basket[];
    AddBaskets: (basket: Basket[]) => void;
    RateBasket: () => Promise<void>;
    ResetBaskets: () => void;
    DecimalPrecision: number;
    AcumulateBaskets: boolean;
    ChangeAcumulateBaskets: () => void;
    ReturnEquityCurve: boolean;
    ChangeReturnEquityCurve: () => void;
    Combinations: Code[][];
    SelectedCodes: Code[];
    Markets: Market[];
    UpdateMarketOfSelectedCodes: (market: Market) => void;
    UpdateSelectedCodes: (codes: Code[]) => void;
    RemoveSelectedCode: (code: Code) => void;
    AddCodesToSelectedCodes: (code: Code[]) => void;
    UpdateCosts: (commission: number, slippage: number) => void;
    Slots: number[];
    UpdateSlots: (slots: number[]) => void;
    RemoveSlot: (id: number) => void;
    ResetSlots: () => void;
    AddSlots: (amount: number) => number[];
    Studies: Study[];
    UpdateStudies: (studies: Study[]) => void;
    UpdateStudyWeight: (study: Study, newWeight: number) => void;
    RemoveStudy: (study: Study) => void;
    ResetStudies: () => void;
    AddStudies: (studiesToAdd: Study[]) => void;
    UpdateSelectedPreset: (preset: RankingPreset | null) => void;
    SelectedPreset: RankingPreset | null;
    ChangeCombinationMode: (newMode: OptimizationMode) => void;
    combinationMode: OptimizationMode;
    MoneyManagementConfig: MmConfig;
    UpdateMoneyManagementConfig: (newConfig: MmConfig) => void;
    GenerateElc: boolean;
    UpdateGenerateElc: (value: boolean) => void;

}

const BasketContext = createContext<BasketContextData>({} as BasketContextData);

export function BasketProvider(props: BasketProviderProps) {
    const [Baskets, setBaskets] = useState<Basket[]>([]);
    const [Slots, setSlots] = useState<number[]>([1]);
    const [Studies, setStudies] = useState<Study[]>([]);
    const [SelectedCodes, setSelectedCodes] = useState<Code[]>([]);
    const [Markets, setMarkets] = useState<Market[]>([]);
    const [SelectedPreset, setSelectedPreset] = useState<RankingPreset | null>(null);
    const [DecimalPrecision] = useState<number>(2);
    const [MoneyManagementConfig, setMoneyManagementConfig] = useState<MmConfig>({ Leverage: 1, InitialNetWorth: 10 * 1000 * 1000 });
    const [AcumulateBaskets, setAcumulateBaskets] = useState<boolean>(false);
    const [ReturnEquityCurve, setReturnEquityCurve] = useState<boolean>(false);
    const [GenerateElc, setGenerateElc] = useState<boolean>(false);
    const [Combinations] = useState<Code[][]>([]);
    const [combinationMode, setCombinationMode] = useState<OptimizationMode>({} as unknown as DefaultMode);

    function ChangeAcumulateBaskets(): void {
        setAcumulateBaskets(!AcumulateBaskets);
    }
    function ChangeReturnEquityCurve(): void {
        setReturnEquityCurve(!ReturnEquityCurve);
    }

    function ChangeCombinationMode(newMode: OptimizationMode): void {
        setCombinationMode(newMode)
    }

    function ResetBaskets(): void {
        setBaskets([]);
    }

    async function RateBasket(): Promise<void> {
        if (SelectedCodes.length === 0 || Studies.length === 0) {
            toast.error("Insira codes e critérios para gerar o ranking");
            return;
        }

        if (combinationMode.ModeName === 'default') {
            toast.promise(OptimizeBasketDefault(), { pending: "Gerando baskets no modo padrão" });
        } else if (combinationMode.ModeName === 'optimization') {

            OptimizeBasket();
        }
        ReRankBaskets();
    }

    function gerarCombinacoes(): Code[][] {
        // Determina os slots presentes na lista de códigos
        const slotsUnicos = Slots

        // Filtra os códigos por slot e cria um objeto com arrays separados para cada slot
        const codigosPorSlot: Record<number, Code[]> = {};
        slotsUnicos.forEach(slot => {
            codigosPorSlot[slot] = SelectedCodes.filter(codigo => codigo.SlotID === slot);
        });

        // Gera as combinações
        const combinacoes: Code[][] = [];

        const gerar = (slots: number[], codigosSelecionados: Code[]) => {
            if (slots.length === 0) {
                combinacoes.push(codigosSelecionados);
            } else {
                const slotAtual = slots[0];
                const codigosDisponiveis = codigosPorSlot[slotAtual];
                for (let i = 0; i < codigosDisponiveis.length; i++) {
                    gerar(slots.slice(1), [...codigosSelecionados, codigosDisponiveis[i]]);
                }
            }
        };

        gerar(slotsUnicos, []);

        return combinacoes.filter(comb => new Set(comb.map(codigo => codigo.SlotID)).size === slotsUnicos.length);
    }



    async function OptimizeBasketDefault() {
        const combin = gerarCombinacoes();
        const combinationsLimit = 100;
        let updatedBaskets: Basket[] = [];
        if (AcumulateBaskets) updatedBaskets = Baskets;
        for (const combination of combin) {
            try {
                let response = await RateCombination(combination);
                response.map(bk => updatedBaskets.push(bk))

                const ranked = RankBaskets(updatedBaskets, Studies);

                if (Slots.length > 1 && ranked.length > combinationsLimit) {
                    setBaskets(ranked.slice(0, combinationsLimit));
                } else {
                    setBaskets(ranked)
                }
            } catch (err: any) {
                toast.error(`Erro ao rankear ${combination.map(x => x.Name).join(", ")}`)
            }

        }
    }

    function OptimizeBasket() {
        const api = new API("basket/optimize/combination");
        let params = new RKGParameters(SelectedCodes.filter(x => x.SlotID === 1), Studies, MoneyManagementConfig.InitialNetWorth, MoneyManagementConfig.Leverage, null, ReturnEquityCurve);
        const opt: Combination = combinationMode as CombinationMode;
        const optParameters = new OptimizationParameters(new Combination(opt.Min, opt.Max, opt.Step), params);
        toast.promise(api.postAsync<Basket[]>(optParameters).then(response => {
            const ranked = RankBaskets(response, params.Criteria);
            setBaskets(ranked);
        }), {
            pending: "Gerando e rankeando as otimizações"
        });
    }

    async function RateCombination(combination: Code[]): Promise<Basket[]> {
        const api = new API("basket/rate");
        let params = new RKGParameters(combination, Studies, MoneyManagementConfig.InitialNetWorth, MoneyManagementConfig.Leverage, null, ReturnEquityCurve, GenerateElc);
        return await api.postAsync<Basket[]>(params);
    }

    function UpdateSelectedPreset(preset: RankingPreset | null): void {
        setSelectedPreset(preset);
        if (preset) {
            AddSlots(preset.Slots - Slots.length);
            ResetStudies();
            AddStudies(preset.Studies);
        }
    }

    function UpdateCosts(commission: number, slippage: number): void {
        let updatedCodes = [...SelectedCodes];
        updatedCodes.forEach((code) => {
            if (code.Market && code.Market.TransactionCost) {
                code.Market.TransactionCost.Commission = commission / 100
                code.Market.TransactionCost.Slippage = slippage / 100
            } else
                toast.error(`Code ${code.Name} está sem mercado ou custo de transação`);
        });
        setSelectedCodes(updatedCodes);
    }

    function UpdateSelectedCodes(codes: Code[]) {
        if (codes.length === 0) return;
        let updatedSelectedCodes = [...SelectedCodes];
        let indexes: number[] = [];
        codes.forEach((code) => indexes.push(updatedSelectedCodes.findIndex((x) => x.ID === code.ID && x.SlotID === code.SlotID)));
        codes.forEach((code, i) => updatedSelectedCodes[indexes[i]] = code);
        setSelectedCodes(updatedSelectedCodes);
    }

    function RemoveSelectedCode(code: Code): void {
        let codeIndex = SelectedCodes.findIndex(x => x.ID === code.ID && x.SlotID === code.SlotID);
        let updatedSelected = SelectedCodes.filter((x, i) => i !== codeIndex);
        setSelectedCodes(updatedSelected);
    }

    function AddCodesToSelectedCodes(codes: Code[]): void {
        const updatedSelectedCodes = [...SelectedCodes, ...codes];
        const filledSlots = uniq(updatedSelectedCodes.map(x => x.SlotID));
        const a: Code[] = [];
        filledSlots.forEach((slot) => a.push(...uniqBy(updatedSelectedCodes.filter(x => x.SlotID === slot), 'ID')));
        setSelectedCodes(a);
    }

    useEffect(() => {
        setMarkets(uniqBy(SelectedCodes.map(x => x.Market), 'ID'))
    }, [SelectedCodes])

    function UpdateMarketOfSelectedCodes(market: Market): void {
        let updatedSelectedCodes = SelectedCodes.map(x => x.Market.ID === market.ID ? { ...x, Market: market } : x);
        setSelectedCodes(updatedSelectedCodes);
    }

    function UpdateMoneyManagementConfig(newConfig: MmConfig): void {
        setMoneyManagementConfig(newConfig);
    }

    function UpdateGenerateElc(value: boolean): void {
        setGenerateElc(value);
    }

    function UpdateSlots(slots: number[]) {
        setSlots(slots);
    }

    function RemoveSlot(id: number): void {
        if (Slots.length > 1) {
            setSlots(Slots.filter(x => x !== id))
        }
        setSelectedCodes(SelectedCodes.filter((x) => x.SlotID !== id));
    }

    function ResetSlots(): void {
        setSlots([1]);
        setSelectedCodes([]);
    }

    function AddSlots(amount: number): number[] {
        let slotsToAdd: number[] = [];
        for (let i = 0; i < amount; i++) {
            slotsToAdd.push(Math.max.apply(null, Slots) + 1 + i);
        }
        setSlots([...Slots, ...slotsToAdd]);
        return slotsToAdd;
    }

    function UpdateStudies(studiesToUpdate: Study[]) {
        let updatedSelected = cloneDeep(Studies);
        let indexes: number[] = [];
        studiesToUpdate.forEach((study) => indexes.push(Studies.findIndex((x) => x.ID === study.ID)));
        studiesToUpdate.forEach((study, i) => updatedSelected[indexes[i]] = study);
        setStudies(updatedSelected);
    }

    function RemoveStudy(study: Study): void {
        setStudies(Studies.filter((x, i) => x.ID !== study.ID));
    }

    function ResetStudies(): void {
        setStudies([]);
    }

    function AddStudies(studiesToAdd: Study[]): void {
        const updatedStudies = cloneDeep(Studies)
        studiesToAdd.forEach(study => {
            if (updatedStudies.findIndex(x => x.ID === study.ID) === -1)
                updatedStudies.push(study)
        });
        setStudies(updatedStudies);
    }

    function AddBaskets(baskets: Basket[]): void {
        setBaskets(baskets)
    }

    function ReRankBaskets(): void {
        const reordered = RankBaskets(Baskets, Studies);
        setBaskets(reordered);
    }

    function UpdateStudyWeightOnStudyList(study: Study, newWeight: number): void {
        if (Studies.length == 0) return;
        let updatedStudies = cloneDeep(Studies);
        const idx = Studies.findIndex((x) => x.ID === study.ID);
        if (idx === -1)
            return;
        updatedStudies[idx].Weight = newWeight;
        setStudies(updatedStudies);
    }

    function UpdateStudyWeightOnBaskets(study: Study, newWeight: number): void {
        if (Baskets.length == 0) return;
        let baskets = cloneDeep(Baskets);
        Baskets.forEach((basket) => {
            let results = cloneDeep(basket.Results);
            const stIdx = results.findIndex((x) => x.Study.ID === study.ID);
            if (stIdx > -1)
                results[stIdx].Study.Weight = newWeight;
            basket.Results = results;
        })
        setBaskets(baskets)
    }

    function UpdateStudyWeight(study: Study, newWeight: number): void {
        UpdateStudyWeightOnStudyList(study, newWeight);
        UpdateStudyWeightOnBaskets(study, newWeight);
        ReRankBaskets();
    }


    return (
        <BasketContext.Provider value={{
            Baskets, AddBaskets, RateBasket, ResetBaskets,
            DecimalPrecision,
            AcumulateBaskets, ChangeAcumulateBaskets,
            ReturnEquityCurve, ChangeReturnEquityCurve,
            Combinations,
            SelectedCodes, UpdateSelectedCodes, RemoveSelectedCode, AddCodesToSelectedCodes,
            Markets, UpdateMarketOfSelectedCodes,
            UpdateCosts,
            Slots, UpdateSlots, RemoveSlot, ResetSlots, AddSlots,
            Studies, UpdateStudies, RemoveStudy, AddStudies, ResetStudies, UpdateStudyWeight,
            SelectedPreset, UpdateSelectedPreset,
            ChangeCombinationMode, combinationMode,
            MoneyManagementConfig, UpdateMoneyManagementConfig,
            GenerateElc, UpdateGenerateElc
        }}>
            {props.children}
        </BasketContext.Provider>
    );
}

export function useBasket() {
    const context = useContext(BasketContext);
    return context;
}