import React from 'react';
import { createRoot } from 'react-dom/client';
//import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme';
import { createStandaloneToast } from '@chakra-ui/toast'
const { ToastContainer } = createStandaloneToast()

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <>
        <ChakraProvider theme={theme}>
            <App />
            <ToastContainer />
        </ChakraProvider>
    </>
)
