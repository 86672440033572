import React, { useState } from "react";
import { MarketProvider } from "../../../hooks/useMarket";
import { Market } from "../../../models/Market";
import { Center, Heading, VStack } from "@chakra-ui/react";
import { MarketTable } from "../../../components/v1/Market/Table";
import { MarketManagementButtons } from "../../../components/v1/Market/MarketManagementButtons";

export function MarketManagement() {
    const [selectedMarkets, setSelectedMarkets] = useState<Market[]>([]);

    return (
        <MarketProvider>
            <Center>
                <VStack>
                    <Heading size="md">Gerenciamento de mercados</Heading>
                    <MarketManagementButtons selectedMarkets={selectedMarkets} />
                    <MarketTable selectedMarkets={selectedMarkets} setSelectedMarkets={setSelectedMarkets} />
                </VStack>
            </Center>
        </MarketProvider>
    );
}