import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Row, Col, Button } from "react-bootstrap";
import { useMarket } from "../../../../hooks/useMarket";
import { useMarketDataAbstract } from "../../../../hooks/useMarketDataAbstract";

export function GetMarketDataConfig() {
    const { prefixes, getPrefixes, contractsType, exchanges } = useMarket();
    const { getMarketData } = useMarketDataAbstract();

    const [prefix, setPrefix] = useState('***');
    const [contractType, setContractType] = useState('***');
    const [exchange, setExchange] = useState('Binance');

    useEffect(() => {
        getPrefixes();
    }, [])

    async function getMarketDataAbstracts() {
        getMarketData(prefix, exchange, contractType);
    }

    return (
        <>
            <Row className="mb-2">
                <Col>
                    <Form.Control as="select" onChange={event => setPrefix(event.target.value)}>
                        <option value={prefix}>Todos os prefixos</option>
                        {
                            prefixes && prefixes.map((item, i) => (
                                item !== ""
                                    ? <option key={i} value={item}>{item}</option>
                                    : <option key={i} value="Sem prefixo">Sem prefixo</option>
                            ))
                        }
                    </Form.Control>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col>
                    <Form.Control as="select" onChange={event => setContractType(event.target.value)}>
                        <option value="***">Todos os tipos de contrato</option>
                        {
                            contractsType.map((item, i) => (<option key={i} value={item}>{item}</option>))
                        }
                    </Form.Control>
                </Col>
            </Row>

            <Row className="mb-2">
                <Col>
                    <Form.Control as="select" onChange={event => setExchange(event.target.value)}>
                        {
                            exchanges.map((item, i) => (<option key={i} value={item}>{item}</option>))
                        }
                    </Form.Control>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button style={{ marginRight: 5 }} onClick={getMarketDataAbstracts} >
                        Buscar
                    </Button>
                </Col>
            </Row>

        </>
    );
}