import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { FaPencilAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import { Fund } from "../../../models/Strategy/Fund";
import { HistoricalSerie } from "../../../models/HistoricalSerie";
import FundController from "../../../controllers/v1/FundController";
import HistoricalSerieController from "../../../controllers/v1/HistoricalSerieController";
import AddHistoricalSerie from "../../../components/v1/HistoricalSerie/AddHistoricalSerie";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

interface IProps { }

interface IState {
	isAddHistoricalSerieModalOpened: boolean;
	isEditSymbolModalOpened: boolean;
	Funds: Array<Fund>;
	SelectedFund: Fund;
	HistoricalSeries: Array<HistoricalSerie>;
	SelectedHistoricalSeries: Array<HistoricalSerie>;
}

export default class FundHistory extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isAddHistoricalSerieModalOpened: true,
			isEditSymbolModalOpened: false,
			Funds: [],
			SelectedFund: new Fund(),
			HistoricalSeries: [],
			SelectedHistoricalSeries: [],
		};
		this.getFunds();
	}

	getFunds = () => {
		toast.promise(FundController.Get().then((x: Array<Fund>) => this.setState({ Funds: x })),
			{
				pending: "Buscando fundos"
			});
	};

	toggleAddSymbolModal = () => {
		this.setState({ isAddHistoricalSerieModalOpened: !this.state.isAddHistoricalSerieModalOpened });
	};

	toggleEditSymbolModal = () => {
		this.setState({ isEditSymbolModalOpened: !this.state.isEditSymbolModalOpened });
	};

	addHistoricalSerie = (newSerie: Array<HistoricalSerie>) => {
		toast.promise(HistoricalSerieController.SaveFund(newSerie), { pending: "Salvando cota" });
		const series = this.state.HistoricalSeries;
		newSerie.map(x => series.push(x));
		this.setState({ HistoricalSeries: series });
	};

	deleteItem = () => {
		if (this.state.SelectedHistoricalSeries) {
			for (const i in this.state.SelectedHistoricalSeries) {
				if (Object.prototype.hasOwnProperty.call(this.state.SelectedHistoricalSeries, i)) {
					const serie = this.state.SelectedHistoricalSeries[i];
					HistoricalSerieController.Delete(serie);
					this.setState({ HistoricalSeries: this.state.HistoricalSeries.filter((x: HistoricalSerie) => x.ID !== serie.ID) });
				}
			}
		}
	};

	handleCheckHistoricalSerie = (historicalSerie: HistoricalSerie | number) => (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		let selecionados = this.state.SelectedHistoricalSeries;
		if (typeof historicalSerie === "number") {
			//* Se o tipo for numero, ele entende que deve selecionar todos os itens da lista
			if (e.currentTarget.checked === true) {
				this.state.HistoricalSeries.map((histSerie: HistoricalSerie) =>
					!selecionados.includes(histSerie)
						? selecionados.push(histSerie) : null
				);
			} else {
				selecionados = [];
			}
		} else {
			//* Se o tipo for específico, ele entende que deve selecionar o item especificado.
			if (e.currentTarget.checked === true) {
				let selectedItem = this.state.HistoricalSeries.filter((thing, i, arr) => arr.findIndex((t) => t.ID === historicalSerie.ID) === i)[0];
				if (selectedItem != null && !selecionados.includes(selectedItem)) {
					selecionados.push(selectedItem);
				}
			} else {
				selecionados = selecionados.filter((selected: HistoricalSerie) => selected.ID !== historicalSerie.ID);
			}
		}
		this.setState({ SelectedHistoricalSeries: selecionados });
	};

	ItemIsSelected(item: HistoricalSerie) {
		return this.state.SelectedHistoricalSeries.includes(item);
	}

	render() {
		return (
			<>
				<AddHistoricalSerie
					show={this.state.isAddHistoricalSerieModalOpened}
					onHide={this.toggleAddSymbolModal}
					onAddHistoricalSerie={this.addHistoricalSerie}></AddHistoricalSerie>
				{/* <UpdateFund show={this.state.isEditSymbolModalOpened} onHide={this.toggleEditSymbolModal} Funds={this.state.SelectedFunds}></UpdateFund> */}
				<Row className="justify-content-center" style={{ paddingTop: 5, paddingBottom: 20 }}>
					<Col sm="auto">
						<h4>Histórico de cotas de fundos</h4>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col sm="auto">
						<Button title="Adicionar mercado" onClick={this.toggleAddSymbolModal}>
							<FaPlus></FaPlus>
						</Button>
						{this.state.SelectedHistoricalSeries.length >= 1 ? (
							<>
								<Button title="Editar" onClick={this.toggleEditSymbolModal}>
									<FaPencilAlt></FaPencilAlt>
								</Button>
								<Button title="Excluir" onClick={this.deleteItem} variant="danger" style={{ marginLeft: "5rem" }}>
									<FaTrashAlt></FaTrashAlt>
								</Button>{" "}
							</>
						) : null}
					</Col>
				</Row>

				<Row className="justify-content-center" style={{ paddingTop: 3 }}>
					<Col sm="10">
						<Table className="table-condensed table-bordered" responsive hover striped size="sm">
							<thead>
								<tr>
									<th>Data</th>


									<th className="text-center">
										<Form.Check checked={this.state.SelectedHistoricalSeries.length > 0} onClick={this.handleCheckHistoricalSerie(-1)} />
									</th>
								</tr>
							</thead>
							<tbody>
								{this.state.HistoricalSeries.map((item: HistoricalSerie) => (
									<tr key={item.ID}>
										<td>{item.Date}</td>

										<td className="text-center">
											<Form.Check id={`${Math.random()}`} checked={this.ItemIsSelected(item)} onClick={this.handleCheckHistoricalSerie(item)} />
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Col>
				</Row>
			</>
		);
	}
}
