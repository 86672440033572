import React from "react";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Form, Table, Button } from "react-bootstrap";
import Order from "../../../models/Order/Order";
import { Market } from "../../../models/Market";
import OrderController from "../../../controllers/v1/OrderController";
import MarketController from "../../../controllers/v1/MarketController";
import Swal from "sweetalert2";
import { Fund } from "../../../models/Strategy/Fund";
import FundController from "../../../controllers/v1/FundController";
import { from } from "linq-to-typescript";

interface IProps {
	onHide: () => void;
	show: boolean;
}

interface IState {
	rawOrders: string;
	rawOrdersCount: number;
	ordersSep: string;
	fieldsSep: string;
	orders: Array<Order>;
	ordersCount: number;
	markets: Array<Market>;
	precision: number;
	funds: Array<Fund>;
}

export default class SubmitOrder extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			rawOrders: "",
			rawOrdersCount: 0,
			ordersSep: ":",
			fieldsSep: ";",
			orders: [],
			ordersCount: 0,
			markets: [],
			precision: 4,
			funds: [],
		};

		this.getMarkets();
		this.getFunds();
	}

	getMarkets = async () => {
		const mkt = await MarketController.List();
		this.setState({ markets: mkt });
	};

	getFunds = async () => {
		const funds = await FundController.Get();
		this.setState({ funds: funds });
	};

	handleRawOrdersChange = (e: any) => {
		this.setState({ rawOrders: e.target.value });
	};

	handleValidateRawOrders = () => {
		const rawOrders = this.state.rawOrders.split(this.state.ordersSep).filter((val) => val !== "");
		let detectedOrders = OrderController.validateOrders(
			this.state.funds,
			this.state.rawOrders,
			this.state.ordersSep,
			this.state.fieldsSep,
			this.state.markets,
			this.state.precision
		);
		this.setState({ rawOrdersCount: rawOrders.length });
		this.setState({ orders: detectedOrders, ordersCount: detectedOrders.length });
	};

	submitOrders = async () => {
		let orders = this.state.orders;
		for (let i = 0; i < orders.length; i++) {
			const order = orders[i];
			order.Status = "Enviando...";
			this.setState({ orders: orders });
			let response = await OrderController.Submit(order);
			if (response) {
				order.Status = response.Message;
			} else {
				order.Status = "Ocorreu um erro";
			}
		}
		this.setState({ orders: orders });
	};

	handleSubmitOrders = async () => {
		let orders = from(this.state.orders).groupBy((x: Order) => x.Fund.Name).toArray();
		let groupsResult = "";
		for (const orderGroup in orders) {
			if (Object.prototype.hasOwnProperty.call(orders, orderGroup)) {
				const group = orders[orderGroup];
				groupsResult = groupsResult.concat(` ${group.key}: ${group.count()} ordens. `);
			}
		}
		Swal.fire({
			title: `Confira o resumo antes de prosseguir`,
			text: `${groupsResult}`,
			icon: "warning",
			confirmButtonText: "Enviar",
			cancelButtonText: "Retornar",
			focusCancel: true,
			showCancelButton: true,
		}).then((res) => {
			if (res.value === true) {
				this.submitOrders();
			}
		});
	};

	render() {
		return (
			<Modal show={this.props.show} onHide={this.props.onHide} size="xl" centered>
				<Modal.Header closeButton>
					<Row>
						<Col xs="12">
							<h2>Enviar ordens</h2>
						</Col>
						<Col xs="12" className="text-center">
							<p>
								<span className="text-muted">
									<strong>Passo a passo: </strong>
									<strong> 1)</strong> cole as ordens no campo abaixo;
									<strong> 2)</strong> clique em "validar ordens";
									<strong> 3)</strong> clique em "Enviar".
								</span>
							</p>
							<p>
								<span>
									<strong style={{ color: "red" }}>Aviso:</strong> apenas as ordens sem erro serão enviadas para as corretoras
								</span>
							</p>
						</Col>
					</Row>
					<Row></Row>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={10}>
							<Form.Group>
								<Form.Label>
									<strong>Ordens - {this.state.rawOrdersCount} detectada(s)</strong>
								</Form.Label>
								<Form.Control
									name="ordens"
									type="text"
									as="textarea"
									rows={4}
									placeholder="Cole aqui as ordens que foram geradas pela planilha. No formato SYMBOL;AMOUNT;PRICE;EXCHANGE;SIDE;TYPE:"
									value={this.state.rawOrders}
									data-prop="ordens"
									onChange={this.handleRawOrdersChange}
								/>
							</Form.Group>
						</Col>
						<Col xs={2}>
							<Button style={{ padding: "1rem", margin: "3rem" }} onClick={this.handleValidateRawOrders} title="Validar as ordens e carregar para a planilha">
								Validar ordens
							</Button>
						</Col>
					</Row>
					<Row style={{ paddingTop: "2rem" }}>
						<Col>
							<span>{this.state.ordersCount} ordens validadas</span>
							<Table className="table-condensed table-bordered" responsive hover size="sm">
								<thead>
									<tr>
										<th>Strategy</th>
										<th>Ct. Type</th>
										<th>Symbol</th>
										<th>Side</th>
										<th>Price</th>
										<th>Amount</th>
										<th>Type</th>
										<th>Status</th>
										<th>Message</th>
									</tr>
								</thead>
								<tbody>
									{this.state.orders.map((order: Order) => (
										<tr key={order.ID} className={order.HasError ? "bgRed" : ""}>
											<td>{order.Fund ? order.Fund.Name : "Nao encontrado"}</td>
											<td>{order.MarketType}</td>
											<td>{order.Market ? order.Market.Ticker : "Nao encontrado"}</td>
											<td>{order.Side}</td>
											<td className="text-right">{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(order.Price)}</td>
											<td>{order.Amount}</td>
											<td>{order.Type}</td>
											<td>{order.Status}</td>
											<td>{order.ErrorMessage}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Row>
						<Col>
							<Button onClick={this.handleSubmitOrders} title="Enviar ordens para a bitfinex">
								Enviar
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}
