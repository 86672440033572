import API from "../../api/ApiManager";
import { Code } from "../../models/Strategy/Code";

export default class CodeController {
	static async GetAvailableCodes(): Promise<Array<Code>> {
		const api = new API("code");
		const res = await api.getAsync<Code[]>();
		return res;
	}

	static async Upload(params: FormData) {
		const api = new API("code/uploadbacktest");
		const res = await api.postAsync<any>(params);
		return res;
	}
}
