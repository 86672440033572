import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import { FaPlus } from "react-icons/fa";
import { Fund } from "../../../models/Strategy/Fund";
import {  OptionTypeBase } from "react-select/src/types";
import FundController from "../../../controllers/v1/FundController";
import AppContext from "../../../context";
import { HistoricalSerie } from "../../../models/HistoricalSerie";
import dayjs from "dayjs";
import { Col, Row } from "react-bootstrap";

interface IProps {
	show: boolean;
	onHide: () => void;
	onAddHistoricalSerie: (newSeries: Array<HistoricalSerie>) => void;
}

interface IState {
	HistoricalSeries: Array<HistoricalSerie>;
	Funds: Array<Fund>;
	MaskedFunds: Array<OptionTypeBase>;
	SelectedFund: Fund;
	HSerie: HistoricalSerie;
	MultipleSerie: string;
}

export default class AddHistoricalSerie extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			HistoricalSeries: [],
			Funds: [],
			MaskedFunds: [],
			SelectedFund: new Fund(),
			HSerie: new HistoricalSerie(
				0,
				new Fund(),
				"",
				new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()),
				0,
				0,
				0,
				0
			),
			MultipleSerie: "",
		};
	}

	static contextType = AppContext;

	generateNewFund() {
		this.setState({
			HistoricalSeries: [],
		});
	}

	componentDidMount() {
		this.loadFunds();
	}

	applyMaskToFunds() {
		let masked = this.state.MaskedFunds;
		this.state.Funds.map((fund: Fund) => 
			masked.push({ label: fund.Name, value: fund.ID })
		);
		this.setState({ MaskedFunds: masked });
	}

	loadFunds = async () => {
		let funds = await FundController.Get();
		this.setState({ Funds: funds });
		this.applyMaskToFunds();
	};

	handleSelect = (property: string, action: string, value: any) => {
		if (action === "select-option" || action === "remove-value") {
			switch (property.toLowerCase()) {
				case "fund":
					const fund = this.state.Funds.filter((x) => x.ID === value)[0];
					/** alterando o fundo do registro único */
					const serie = this.state.HSerie;
					serie.Strategy = fund;

					/** alterando o fundo do registro em massa */
					const series = this.state.HistoricalSeries;
					for (const j in series) {
						if (Object.prototype.hasOwnProperty.call(series, j)) {
							const serie = series[j];
							serie.Strategy = fund;
						}
					}
					this.setState({ SelectedFund: fund, HistoricalSeries: series, HSerie: serie });
					break;
				default:
					break;
			}
		}
	};

	handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		//TODO : Refatorar
		if (this.state.HSerie) {
			if (e.target.dataset.prop === "networth") {
				let serie = this.state.HSerie;
				//serie.NetWorth = e.target.valueAsNumber;
				this.setState({ HSerie: serie });
			}
			if (e.target.dataset.prop === "date") {
				let serie = this.state.HSerie;
				if (e.target.valueAsDate !== null) {
					serie.Date = e.target.valueAsDate;
				}
				this.setState({ HSerie: serie });
			}
		}
	};

	handleMultipleSerieChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.dataset.prop === "multipleSerie") {
			try {
				const rawSeries = e.target.value.split(":").filter((val) => val !== "");
				const series:Array<HistoricalSerie> = [];
				for (const i in rawSeries) {
					if (Object.prototype.hasOwnProperty.call(rawSeries, i)) {
						const rawSerie = rawSeries[i];
						const splittedRawSerie = rawSerie.split(";");
						const date = splittedRawSerie[0];
						const netWorth = splittedRawSerie[1].replace(",",".");
						const serie = new HistoricalSerie(0,this.state.SelectedFund, "",new Date(date),0,0,0,0);
						series.push(serie);
					}
				}
				this.setState({HistoricalSeries: series });
			} catch (error) {
				console.log(error);
			}
		}
	};

	addHistoricalSerie = () => {
		const serie = this.state.HistoricalSeries;
		// TODO: REFATORAR
		// if (this.state.HSerie.Strategy.ID !== 0 && this.state.HSerie.NetWorth !== 0) {
		// 	serie.push(this.state.HSerie);
		// }
		this.props.onAddHistoricalSerie(serie);
		this.props.onHide();
	}

	render() {
		return (
			<>
				<Modal show={this.props.show} onHide={this.props.onHide} size="lg" centered>
					<Modal.Header closeButton>
						<h5>Registrar patrimônio líquido do fundo</h5>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col>
								<Form>
									<Form.Group>
										<Select
											placeholder="Fundo"
											options={this.state.MaskedFunds}
											onChange={(x: any, y: any) => this.handleSelect("fund", y.action, x.value)}></Select>
									</Form.Group>
								</Form>
							</Col>
						</Row>

						<hr></hr>
						<Row>
							<Col xs={6}>
								<h5>Registro único</h5>
								<hr></hr>
								<Form>
									<Form.Group>
										<Form.Control
											name="net"
											type="date"
											placeholder="Data"
											onChange={this.handleChange}
											data-prop="date"
											value={dayjs(this.state.HSerie.Date).format("YYYY-MM-DD")}></Form.Control>
									</Form.Group>
									<Form.Group>
										<Form.Control name="net" type="number" placeholder="PL" onChange={this.handleChange} data-prop="networth"></Form.Control>
									</Form.Group>
								</Form>
							</Col>
							<Col xs={6}>
								<h5>Registro em massa</h5>
								<hr></hr>
								<Form.Group>
									<Form.Control
										name="net"
										type="text"
										as="textarea"
										rows={3}
										placeholder="Colar diversos PL's, no formato data;pl:"
										onChange={this.handleMultipleSerieChange}
										data-prop="multipleSerie"></Form.Control>
								</Form.Group>
								<p className="text-right">PL's identificados: {this.state.HistoricalSeries.length}</p>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button id="add" onClick={this.addHistoricalSerie}>
							<FaPlus />
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}
}
