import { HStack, Tag, TagLabel, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useBasket } from "../../../hooks/Basket/useBasket"
import { CombinationMode } from '../../../models/IOptimization';

export function CountPossibleCombinationsTag() {
    const { combinationMode, SelectedCodes } = useBasket();
    const [countCombinations, setCountCombination] = useState<number>(0);

    function rFact(num: number): number {
        return (num < 2) ? 1 : rFact(num - 1) * num;
    }

    useEffect(() => {
        if (combinationMode.ModeName === 'optimization') {
            const opt = combinationMode as CombinationMode;
            const countCodes = SelectedCodes.length;

            if (countCodes > 0) {
                let countCombs = 0;
                const facCodes = rFact(countCodes);

                for (let i = opt.Min; i <= opt.Max; i += opt.Step) {
                    let facGroups = rFact(i)
                    let diff = countCodes - i
                    let facDiff = rFact(diff)
                    countCombs += facCodes / (facGroups * facDiff)
                }
                countCombs > 1 ? setCountCombination(countCombs) : setCountCombination(0)
            }
        }

    }, [combinationMode, SelectedCodes])

    return (
        <HStack>
            <Tag size="sm" key="codes" variant='outline' colorScheme='blue'>
                <TagLabel>{SelectedCodes.length} codes</TagLabel>
            </Tag>
            <Tag size="sm" key="mode" variant='outline' colorScheme='orange'>
                <TagLabel>Modo de combinação: {combinationMode.ModeName}</TagLabel>
            </Tag>
            <Tag size="sm" key="combinations" variant='outline' colorScheme='green'>
                <TagLabel>{countCombinations} combinações possíveis</TagLabel>
            </Tag>
        </HStack>
    )
}