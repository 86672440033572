import dayjs from 'dayjs';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import API from '../api/ApiManager';
import { Study } from '../models/Study';

interface StudyProviderProps {
    children: React.ReactNode;
}

interface StudyContextData {
    studies: Study[];
    studyTypes: string[];
    rangeTypes: string[];
    saveStudy: (study: Study) => Promise<Study>;
    updateStudy: (study: Study) => Promise<void>;
    deleteStudies: (studies: Study[]) => Promise<void>;
    getStudies: () => Promise<void>;
    getRangeDescription: (rangeType: string, rollingMonths: number, startDate: string | null, endDate: string | null) => string;
    studiesThatUsePercentual: string[];
}

const StudyContext = createContext<StudyContextData>({} as StudyContextData);


export function StudyProvider(props: StudyProviderProps) {
    const [studyTypes, setStudyTypes] = useState<string[]>([]);
    const [rangeTypes] = useState<string[]>(["Backtest", "Data completa", "Realtime"]);
    const [studiesThatUsePercentual] = useState<string[]>(["CAGR", "DRAWDOWN", "DRAWDOWN MÉDIO", "VAR", "EXPECTATIVA MATEMÁTICA",
        "RETORNO MÉD. TRADES POSITIVOS", "RETORNO MÉD. TRADES NEGATIVOS", "TAXA TRADES POSITIVOS", "TAXA TRADES NEGATIVOS"]);
    const [studies, setStudies] = useState<Study[]>([]);

    useEffect(() => {
        getStudies();
        getStudyTypes();
        const updatedStudies = studies;
        updatedStudies.map(x => x.RangeDescription = getRangeDescription(x.RangeType, x.RollingMonths, x.Start, x.End));
        setStudies(updatedStudies)
    }, []);

    async function getStudyTypes() {
        const api = new API('study/types');
        toast.promise(api.getAsync<string[]>().then(response => response !== null ? setStudyTypes(response) : []), { pending: "🔎 Buscando tipos de critério..." })
    }


    async function getStudies() {
        const api = new API('study');
        toast.promise(api.getAsync<Study[]>().then(response => response !== null ? setStudies(response) : []),
            { pending: "🔎 Buscando critérios..." })
    }

    async function saveStudy(study: Study): Promise<Study> {
        const api = new API('study');
        study.ID = 0;
        let res: Study = study;
        await toast.promise(api.postAsync<Study>(study).then(response => {
            res = response;
        }), { pending: "Salvando...", success: "Critério salvo!" })

        if (res)
            setStudies([...studies, res]);
        return res;
    }

    async function updateStudy(study: Study): Promise<void> {
        const api = new API('study');
        const updatedStudies = [...studies];
        toast.promise(api.putAsync(study), {
            pending: "Salvando alterações",
            success: "Alterações salvas!",
        })
        const studyIndex = updatedStudies.findIndex(x => x.ID === study.ID);
        if (studyIndex) {
            updatedStudies[studyIndex] = study;
            setStudies(updatedStudies);
        }
    }

    async function deleteStudy(study: Study) {
        const api = new API(`study/${study.ID}`);
        await api.deleteAsync();
    }

    async function deleteStudies(studies: Study[]) {
        const ids = studies.map(x => x.ID);
        const api = new API(`study/deletebyid`);
        toast.promise(api.postAsync(ids), { pending: "Apagando critérios" });
    }

    function getRangeStart(start: string | null) {
        return start === null ? "1ª barra" : `${dayjs(start).format("YYYY-MM-DD")}`;
    }
    function getRangeEnd(end: string | null) {
        return end === null ? "data mais recente" : `${dayjs(end).format("YYYY-MM-DD")}`;
    }

    function getRangeDescription(rangeType: string, rollingMonths: number, startDate: string | null, endDate: string | null): string {
        let range: string = "";
        const start = getRangeStart(startDate);
        const end = getRangeEnd(endDate);

        switch (rangeType) {
            case "Backtest":
                range = rollingMonths === 0 ?
                    `De ${start} até a data do backtest` :
                    `De ${rollingMonths} meses do backtest até a data do backtest`;
                break;
            case "Realtime":
                range = rollingMonths === 0 ?
                    `De data do backtest até ${end}` :
                    `De ${rollingMonths} meses da última barra até a data mais recente`;
                break;
            default:
                range = rollingMonths === 0 ?
                    `De ${start} até ${end}` :
                    `De ${rollingMonths} meses da última barra até a data mais recente`;
                break;
        }

        return range;
    }


    return (
        <StudyContext.Provider value={{
            studies, studyTypes, rangeTypes, studiesThatUsePercentual,
            getRangeDescription,
            saveStudy, getStudies, deleteStudies, updateStudy
        }}>
            {props.children}
        </StudyContext.Provider>
    )
}

export function useStudy() {
    const context = useContext(StudyContext);
    return context;
}