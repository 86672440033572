import React from 'react';
import { Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton } from '@chakra-ui/react'
import { Text, IconButton, Button, MenuItem, useDisclosure, VStack } from "@chakra-ui/react";
import { FaInfo, FaTrash } from 'react-icons/fa';
import RankingPreset from '../../../models/Ranking/RankingPreset';
import { useRankingPreset } from '../../../hooks/useRankingPreset';
import { useStudy } from '../../../hooks/useStudy';

interface Props {
    preset: RankingPreset | null;
}

export function DeleteRankingPresetPopover({ preset }: Props) {
    const { isOpen, onToggle, onClose } = useDisclosure()
    const { DeleteRankingPreset } = useRankingPreset();

    async function deletePreset() {
        if (preset)
            DeleteRankingPreset(preset);
    }

    return (
        <Popover
            returnFocusOnClose={true}
            isOpen={isOpen}
            onClose={onClose}
            placement='bottom'
            closeOnBlur={false}>
            <PopoverTrigger>
                <MenuItem icon={<FaTrash />} onClick={onToggle} isDisabled={preset === null} closeOnSelect={false}>Excluir preset</MenuItem>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverHeader textAlign={"left"} fontWeight='bold'>Confirmação de exclusão</PopoverHeader>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody textAlign={"left"}>
                    <VStack align="left" spacing="5">
                        {!!preset && <Text>Confirme que você deseja excluir o preset "{preset.Name}"</Text>}
                        <Button bg="red.500" onClick={deletePreset}>Excluir</Button>
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}