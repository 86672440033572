import { Table, Tbody, Th, Thead, Tr, Text } from "@chakra-ui/react";
import { useBasket } from "../../../../../hooks/Basket/useBasket";
import { MmConfig } from "../../../../../models/Ranking/Parameters";
import { MarketManagementTableRow } from "./MarketManagementTableRow";


export function MarketManagementTable() {
    const { Markets } = useBasket();

    return (
        <>
            <Text fontWeight="bold">Configurações dos mercados</Text>
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th colSpan={4}>Money management</Th>
                        <Th colSpan={2}>Custos</Th>
                    </Tr>
                    <Tr>
                        <Th>Mercado</Th>
                        <Th>Exposição (%)</Th>
                        <Th>Exposição ($)</Th>
                        <Th>Valor do ponto</Th>
                        <Th>Lote mín.</Th>
                        <Th>Slippage (%)</Th>
                        <Th>Comissão (%)</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Markets.map(x => (
                        <MarketManagementTableRow market={x} />
                    ))}
                </Tbody>
            </Table>
        </>

    )
}