import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FormControl, FormErrorMessage, FormLabel, NumberInputField, InputGroup, NumberInputProps, NumberInput, NumberIncrementStepper, NumberDecrementStepper, NumberInputStepper } from '@chakra-ui/react';
import { FieldError } from "react-hook-form";

interface InputProps extends NumberInputProps {
    name: string;
    label?: string;
    error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ name, label, error, ...rest }, ref) => {
    return (
        <FormControl isInvalid={!!error}>
            {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
            <InputGroup>
                <NumberInput {...rest}>
                    <NumberInputField />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
                {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
            </InputGroup>
        </FormControl>
    )
}

export const CustomNumberInput = forwardRef(InputBase);
